/**
* Template Name: Anyar - v2.1.0
* Template URL: https://bootstrapmade.com/anyar-free-multipurpose-one-page-bootstrap-theme/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
	margin-top: -5%;
	font-family: 'Open Sans', sans-serif;
	color: #444444;
}

.logohome {
	/* margin-left: 30%; */
}
@media only screen and (max-width: 768px) {
	.logohome {
		margin-left: 0%;
	}
}

.homedescription {
	color: white;
	font-family: sans-serif;
	font-weight: inherit;
}
.bg-blue {
	background-color: #000000;
}

.dtr-py-8 {
	padding-top: 10rem;
}
.dtr-pr-8,
.dtr-px-8 {
	padding-right: 10rem;
}
.dtr-pb-8,
.dtr-py-8 {
	padding-bottom: 10rem;
}
.dtr-pl-8,
.dtr-px-8 {
	padding-left: 10rem;
}

a {
	color: #0880e8;
}

a:hover {
	color: #2b99f8;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Raleway', sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
	position: fixed;
	display: none;
	right: 15px;
	bottom: 15px;
	z-index: 99999;
}

.back-to-top i {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	background: #0880e8;
	color: #fff;
	transition: all 0.4s;
}

.back-to-top i:hover {
	background: #f6b024;
	color: #fff;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #fff;
}

#preloader:before {
	content: '';
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #0880e8;
	border-top-color: #bfe0fd;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	-webkit-animation: animate-preloader 1s linear infinite;
	animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
	[data-aos-delay] {
		transition-delay: 0 !important;
	}
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/

.contact-area {
	position: relative;
	z-index: 1;
}
.contact-area .contact_from .form-control {
	/* margin-bottom: 30px; */
	width: 100%;
	height: 50px;
	padding: 20px;
	border: 2px solid #ebebeb;
	font-size: 13px;
	font-style: italic;
	background-color: #f5f5ff;
}
.contact-area .contact_from .form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #3f43fd;
}
.contact-area .contact_from textarea.form-control {
	height: 150px;
}

.contact-content h6 {
	color: #b26549;
	text-transform: uppercase;
	margin-bottom: 1rem;
	font-weight: 700;
}

.contact-content h2 {
	font-size: 3rem;
	margin-bottom: 1rem;
	color: #000000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.contact-content h2 {
		font-size: 2.2rem;
	}
}
@media only screen and (max-width: 575px) {
	.contact-content h2 {
		font-size: 2rem;
	}
}

.contact-content p:last-child {
	margin-bottom: 0;
}

.apland-btn {
	position: relative;
	z-index: 1;
	border: 2px solid transparent;
	display: inline-block;
	font-size: 14px;
	padding: 0.8rem 1.6rem;
	border-radius: 6px;
	line-height: 1;
	text-transform: uppercase;
}

#topbar {
	height: 60px;
	font-size: 14px;
	transition: all 0.5s;
	overflow: hidden;
	color: rgba(255, 255, 255, 0.8);
}

#topbar.topbar-scrolled {
	top: -60px;
}

#topbar .contact-info ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

#topbar .contact-info li {
	display: inline-block;
	padding: 0;
}

#topbar .contact-info li + li {
	margin-left: 18px;
}

#topbar .contact-info a {
	color: rgba(255, 255, 255, 0.8);
	transition: 0.3s;
}

#topbar .contact-info a:hover {
	color: #fff;
}

#topbar .contact-info i {
	color: #f6b024;
	padding-right: 4px;
}

#topbar .cta {
	background: transparent;
}

#topbar .cta a {
	color: #fff;
	background: #f6b024;
	padding: 6px 24px 8px 24px;
	display: inline-block;
	transition: 0.3s;
	border-radius: 50px;
}

#topbar .cta a:hover {
	background: #f1a40a;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
	background: #000000;
	transition: all 0.5s;
	z-index: 997;
	padding: 15px 0;
}

#header a {
	text-decoration: none;
}

#header.header-scrolled {
	top: 0;
	background: #000000;
}

#header .logo {
	font-size: 32px;
	margin: 0;
	padding: 0;
	line-height: 1;
	font-weight: 400;
	letter-spacing: 2px;
	text-transform: uppercase;
}


#header .logo img {
	height: 80px;
	width: 10rem !important;
}

@media (max-width: 992px) {
	#header {
		top: 0;
		/* padding: 15px 0; */
		background: #000000;
	}
	#header .logo {
		font-size: 32px;
	}
}

.header-inner-pages {
	background: rgba(255, 255, 255, 0.9) !important;
}

.topbar-inner-pages {
	background: rgba(255, 255, 255, 0.9) !important;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
	margin: 0;
	padding: 0;
	list-style: none;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
}

.nav-menu > ul {
	display: flex;
}

.nav-menu > ul > li {
	position: relative;
	white-space: nowrap;
	padding: 10px 0 10px 24px;
}

.nav-menu a {
	display: block;
	position: relative;
	color: #dcdcdc;
	transition: 0.3s;
	font-size: 18px;
	padding: 0 3px;
	font-family: 'Montserrat', sans-serif;
}

.nav-menu > ul > li > a:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 1px;
	bottom: -5px;
	left: 0;
	background-image: linear-gradient(92.22deg, #ffc692 0.42%, #fec90f 95.34%);
	visibility: hidden;
	width: 0px;
	transition: all 0.3s ease-in-out 0s;
}

.nav-menu a:hover:before,
.nav-menu li:hover > a:before,
.nav-menu .active > a:before {
	visibility: visible;
	width: 100%;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
	background: linear-gradient(180deg, #e7936e 0%, #f6c077 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.nav-menu .drop-down ul {
	display: block;
	position: absolute;
	left: 14px;
	top: calc(100% + 30px);
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	padding: 10px 0;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
	transition: 0.3s;
	border-radius: 8px;
}

.nav-menu .drop-down:hover > ul {
	opacity: 1;
	top: 100%;
	visibility: visible;
}

.nav-menu .drop-down li {
	min-width: 180px;
	position: relative;
}

.nav-menu .drop-down ul a {
	padding: 10px 20px;
	font-size: 14px;
	font-weight: 500;
	text-transform: none;
	color: #032e54;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
	color: #dcdcdc;
}

.nav-menu .drop-down > a:after {
	content: '\ea99';
	font-family: IcoFont;
	padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
	top: 0;
	left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
	opacity: 1;
	top: 0;
	left: 100%;
}

.nav-menu .drop-down .drop-down > a {
	padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
	content: '\eaa0';
	font-family: IcoFont;
	position: absolute;
	right: 15px;
}

@media (max-width: 1366px) {
	.nav-menu .drop-down .drop-down ul {
		left: -90%;
	}
	.nav-menu .drop-down .drop-down:hover > ul {
		left: -100%;
	}
	.nav-menu .drop-down .drop-down > a:after {
		content: '\ea9d';
	}
}

/* Mobile Navigation */
.mobile-nav-toggle {
	position: fixed;
	right: 15px;
	top: 15px;
	z-index: 9998;
	border: 0;
	background: none;
	font-size: 24px;
	transition: all 0.4s;
	outline: none !important;
	line-height: 1;
	cursor: pointer;
	text-align: right;
}

.mobile-nav-toggle i {
	color: #fff;
}

.mobile-nav {
	position: fixed;
	top: 55px;
	right: 15px;
	bottom: 15px;
	left: 15px;
	z-index: 9999;
	overflow-y: auto;
	background: #000;
	transition: ease-in-out 0.2s;
	opacity: 0;
	visibility: hidden;
	border-radius: 10px;
	padding: 10px 0;
}

.mobile-nav * {
	margin: 0;
	padding: 0;
	list-style: none;
}

.mobile-nav a {
	display: block;
	position: relative;
	color: #dcdcdc;
	padding: 10px 20px;
	outline: none;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
	background: linear-gradient(180deg, #e7936e 0%, #f6c077 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-decoration: none;
}

.mobile-nav .drop-down > a:after {
	content: '\ea99';
	font-family: IcoFont;
	padding-left: 10px;
	position: absolute;
	right: 15px;
}

.mobile-nav .active.drop-down > a:after {
	content: '\eaa1';
}

.mobile-nav .drop-down > a {
	padding-right: 35px;
}

.mobile-nav .drop-down ul {
	display: none;
	overflow: hidden;
}

.mobile-nav .drop-down li {
	padding-left: 20px;
}

.mobile-nav-overly {
	width: 100%;
	height: 100%;
	z-index: 9997;
	top: 0;
	left: 0;
	position: fixed;
	background: rgba(5, 74, 133, 0.6);
	overflow: hidden;
	display: none;
	transition: ease-in-out 0.2s;
}

.mobile-nav-active {
	overflow: hidden;
}

.mobile-nav-active .mobile-nav {
	opacity: 1;
	visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
	color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
	width: 100%;
	height: 80vh;
	overflow: hidden;
	position: relative;
	background-size: cover;
	position: relative;
	margin-bottom: -90px;
}

#hero:before {
	content: '';
	background: rgba(6, 101, 183, 0.9);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

#hero .carousel-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

#hero h2 {
	color: #fff;
	margin-bottom: 30px;
	font-size: 48px;
	font-weight: 700;
}

#hero p {
	width: 80%;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
	margin: 0 auto 30px auto;
	color: #fff;
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
	width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
	background: none;
	font-size: 48px;
	line-height: 1;
	width: auto;
	height: auto;
}

#hero .btn-get-started {
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 12px 32px;
	border-radius: 50px;
	transition: 0.5s;
	line-height: 1;
	margin: 10px;
	color: #fff;
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
	border: 2px solid #f6b024;
}

#hero .btn-get-started:hover {
	background: #f6b024;
	color: #fff;
	text-decoration: none;
}

@media (min-width: 1024px) {
	#hero p {
		width: 60%;
	}
	#hero .carousel-control-prev,
	#hero .carousel-control-next {
		width: 5%;
	}
}

@media (max-width: 768px) {
	#hero {
		height: 100vh;
		margin-bottom: 60px;
	}
	#hero h2 {
		font-size: 28px;
	}
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
	overflow: hidden;
}

.section-bg {
	background-color: #f1f8ff;
}

.section-title {
	text-align: center;
	padding-bottom: 10px;
}

.section-title h2 {
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 10px;
	padding-bottom: 0;
	color: #000000;
}

.section-title h2::after {
	content: '';
	position: absolute;
	display: block;
	width: 200px;
	height: 1px;
	background: #b16448;
	left: calc(50% - 100px);
}
.section-title p {
	margin-bottom: 0;
	font-style: italic;
}

.info-s {
	margin-left: 20%;
}

.b-font {
	font-family: 'Raleway', sans-serif;
}
/*--------------------------------------------------------------
# Icon Boxes
--------------------------------------------------------------*/
.icon-boxes {
	padding-top: 0;
}

.icon-boxes .icon-box {
	padding: 40px 30px;
	position: relative;
	overflow: hidden;
	background: #fff;
	box-shadow: 5px 10px 29px 0 rgba(68, 88, 144, 0.2);
	transition: all 0.3s ease-in-out;
	border-radius: 10px;
}

.icon-boxes .icon {
	margin: 0 auto 20px auto;
	display: inline-block;
	text-align: center;
}

.icon-boxes .icon i {
	font-size: 36px;
	line-height: 1;
	color: #f6b024;
}

.icon-boxes .title {
	font-weight: 700;
	margin-bottom: 15px;
	font-size: 18px;
}

.icon-boxes .title a {
	color: #05579e;
}

.icon-boxes .description {
	font-size: 15px;
	line-height: 28px;
	margin-bottom: 0;
	color: #777777;
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about {
	padding: 40px;
	background: url('../img/aboutus-bg.png') top center no-repeat;
	position: relative;
	background-size: cover;
}

.about:before {
	content: '';
	background: rgba(255, 255, 255, 0.75);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

.about .container {
	position: relative;
}

.tech .container {
	position: relative;
}

.about .content h3 {
	font-weight: 600;
	font-size: 26px;
}

.about .content ul {
	list-style: none;
	padding: 0;
}

.about .content ul li {
	padding-left: 28px;
	position: relative;
}

.about .content ul li + li {
	margin-top: 10px;
}

.about .content ul i {
	position: absolute;
	left: 0;
	top: 2px;
	font-size: 20px;
	color: #0880e8;
	line-height: 1;
}

.about .content p:last-child {
	margin-bottom: 0;
}

.about .content .btn-learn-more {
	font-family: 'Raleway', sans-serif;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 12px 32px;
	border-radius: 5px;
	transition: 0.3s;
	line-height: 1;
	color: #0880e8;
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
	margin-top: 6px;
	border: 2px solid #0880e8;
}

.about .content .btn-learn-more:hover {
	background: #0880e8;
	color: #fff;
	text-decoration: none;
}

@media (max-width: 992px) {
	.about {
		padding: 60px 0;
	}
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients .owl-item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
}

.clients .owl-item img {
	width: 60%;
	opacity: 0.5;
	transition: 0.3s;
}

.clients .owl-item img:hover {
	opacity: 1;
}

.clients .owl-nav,
.clients .owl-dots {
	margin-top: 5px;
	text-align: center;
}

.clients .owl-dot {
	display: inline-block;
	margin: 0 5px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #ddd !important;
}

.clients .owl-dot.active {
	background-color: #0880e8 !important;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
	background: #f1f8ff;
	padding: 0;
}

.why-us .content {
	padding: 60px 100px 0 100px;
}

.why-us .content h3 {
	font-weight: 400;
	font-size: 34px;
}

.why-us .content h4 {
	font-size: 20px;
	font-weight: 700;
	margin-top: 5px;
}

.why-us .content p {
	font-size: 15px;
	color: #848484;
}

.why-us .video-box {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 400px;
	position: relative;
}

.why-us .accordion-list {
	padding: 0 100px 60px 100px;
}

.why-us .accordion-list ul {
	padding: 0;
	list-style: none;
}

.why-us .accordion-list li + li {
	margin-top: 15px;
}

.why-us .accordion-list li {
	padding: 20px;
	background: #fff;
	border-radius: 4px;
}

.why-us .accordion-list a {
	display: block;
	position: relative;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	padding-right: 30px;
	outline: none;
}

.why-us .accordion-list span {
	color: #0880e8;
	font-weight: 600;
	font-size: 18px;
	padding-right: 10px;
}

.why-us .accordion-list i {
	font-size: 24px;
	position: absolute;
	right: 0;
	top: 0;
}

.why-us .accordion-list p {
	margin-bottom: 0;
	padding: 10px 0 0 0;
}

.why-us .accordion-list .icon-show {
	display: none;
}

.why-us .accordion-list a.collapsed {
	color: #343a40;
}

.why-us .accordion-list a.collapsed:hover {
	color: #0880e8;
}

.why-us .accordion-list a.collapsed .icon-show {
	display: inline-block;
}

.why-us .accordion-list a.collapsed .icon-close {
	display: none;
}

.why-us .play-btn {
	width: 94px;
	height: 94px;
	background: radial-gradient(#0880e8 50%, rgba(8, 128, 232, 0.4) 52%);
	border-radius: 50%;
	display: block;
	position: absolute;
	left: calc(50% - 47px);
	top: calc(50% - 47px);
	overflow: hidden;
}

.why-us .play-btn::after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-40%) translateY(-50%);
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 15px solid #fff;
	z-index: 100;
	transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.why-us .play-btn::before {
	content: '';
	position: absolute;
	width: 120px;
	height: 120px;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-animation: pulsate-btn 2s;
	animation: pulsate-btn 2s;
	-webkit-animation-direction: forwards;
	animation-direction: forwards;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: steps;
	animation-timing-function: steps;
	opacity: 1;
	border-radius: 50%;
	border: 5px solid rgba(8, 128, 232, 0.7);
	top: -15%;
	left: -15%;
	background: rgba(198, 16, 0, 0);
}

.why-us .play-btn:hover::after {
	border-left: 15px solid #0880e8;
	transform: scale(20);
}

.why-us .play-btn:hover::before {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-40%) translateY(-50%);
	width: 0;
	height: 0;
	border: none;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 15px solid #fff;
	z-index: 200;
	-webkit-animation: none;
	animation: none;
	border-radius: 0;
}

@media (max-width: 1024px) {
	.why-us .content,
	.why-us .accordion-list {
		padding-left: 0;
		padding-right: 0;
	}
}

@media (max-width: 992px) {
	.why-us .content {
		padding-top: 30px;
	}
	.why-us .accordion-list {
		padding-bottom: 30px;
	}
}

@-webkit-keyframes pulsate-btn {
	0% {
		transform: scale(0.6, 0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}

@keyframes pulsate-btn {
	0% {
		transform: scale(0.6, 0.6);
		opacity: 1;
	}
	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/

.section-title.style--two h2.black {
	color: #292929;
}
.section-title h2.black {
	color: #292929;
}

.app2-bg {
	background: #f5f8ff;
}

.app2.btn-book:after,
.app2.btn-crm:after,
.app2.btn-crs:after,
.app2.btn-pay:after,
.app2.e-btn:after,
.app2.m-btn:after,
.btn.app2:after {
	left: 0;
	top: -100%;
	width: 100%;
	height: 100%;
	content: '';
	position: absolute;
	border-radius: 0 5px 5px 0;
	z-index: -1;
	background: #ff896b;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#ff896b),
		to(#ff6773)
	);
	background: -o-linear-gradient(top, #ff896b 0, #ff6773 100%);
	background: linear-gradient(to bottom, #ff896b 0, #ff6773 100%);
}

.app2.btn-book:hover,
.app2.btn-crm:hover,
.app2.btn-crs:hover,
.app2.btn-pay:hover,
.app2.e-btn:hover,
.app2.m-btn:hover,
.btn.app2:hover {
	color: #fff;
}

.app2.btn-book:hover:after,
.app2.btn-crm:hover:after,
.app2.btn-crs:hover:after,
.app2.btn-pay:hover:after,
.app2.e-btn:hover:after,
.app2.m-btn:hover:after,
.btn.app2:hover:after {
	top: 0;
}
.feature-img .mobile-bg {
	position: absolute;
	z-index: -1;
	-webkit-animation: spin 60s linear 0.3s infinite forwards;
	animation: spin 60s linear 0.3s infinite forwards;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.feature-img .mobile-bg {
		bottom: 80px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
	.feature-img .host-f2,
	.feature-img .pay-f2 {
		padding-right: 30px;
	}
}

.feature-img.style--two .f-bg {
	left: -270px;
}

@media only screen and (max-width: 1199px) {
	.feature-img.style--two .f-bg {
		left: -100px;
	}
}

.feature-img.style--two .f-img {
	right: 0;
}

@media only screen and (max-width: 1199px) {
	.feature-img.style--two .f-img {
		right: 0;
	}
}

.feature-img.style--three .f-bg {
	left: 290px;
}

@media only screen and (max-width: 1199px) {
	.feature-img.style--three .f-bg {
		left: 100px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.style--three .f-bg {
		right: 0;
	}
}

.feature-img.style--three .f-img {
	right: -90px;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.feature-img.style--three .f-img {
		right: -60px;
	}
}

@media only screen and (max-width: 1199px) {
	.feature-img.style--three .f-img {
		right: 0;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.style--three .f-img {
		right: 0;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.plug {
		margin-bottom: 60px;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.feature-img.analytic .feature-map3 {
		width: 100%;
	}
}

.feature-img.app img {
	margin-left: -150px;
	max-width: none;
}

@media only screen and (min-width: 991px) and (max-width: 1500px) {
	.feature-img.app img {
		max-width: 140%;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.app img {
		max-width: 100%;
		margin-left: 0;
		margin-bottom: 30px;
	}
}

.feature-img.app2 img {
	margin-left: -15px;
	max-width: none;
}

@media only screen and (min-width: 991px) and (max-width: 1500px) {
	.feature-img.app2 img {
		max-width: 120%;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.app2 img {
		max-width: 100%;
		margin-left: 0;
		margin-bottom: 40px;
	}
}

.feature-img.l-app {
	margin-left: 60px;
}

@media only screen and (max-width: 991px) {
	.feature-img.l-app {
		margin-left: 0;
	}
}

.feature-img.l-app.two {
	margin: 0;
	margin-right: 60px;
}

@media only screen and (max-width: 991px) {
	.feature-img.l-app.two {
		margin: 0;
	}
}

.feature-img.proto {
	padding-left: 20px;
}

@media only screen and (max-width: 991px) {
	.feature-img.proto {
		padding-left: 0;
	}
}

.feature-img.proto.left {
	padding-left: 0;
}

.feature-img.proto .proto-img {
	max-width: none;
}

@media only screen and (max-width: 991px) {
	.feature-img.proto .proto-img {
		max-width: 100%;
	}
}

.feature-img.proto .proto-img.left {
	left: -270px;
	position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.feature-img.proto .proto-img.left {
		left: -360px;
	}
}

@media only screen and (max-width: 1199px) {
	.feature-img.proto .proto-img.left {
		left: -400px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.proto .proto-img.left {
		left: 0;
	}
}

@-moz-document url-prefix() {
	.feature-img.proto .proto-img {
		max-width: none;
		min-width: 808px;
	}
	@media only screen and (max-width: 991px) {
		.feature-img.proto .proto-img {
			max-width: 100%;
			min-width: auto;
		}
	}
}

.tab-content.app2-tab div > img {
	-webkit-animation: banner3Shake 10s ease-in infinite;
	animation: banner3Shake 10s ease-in infinite;
}

.swiper-container.how-work-slider {
	height: 600px;
}

@media only screen and (max-width: 1199px) {
	.swiper-container.how-work-slider {
		height: 700px;
	}
}

@media only screen and (max-width: 991px) {
	.swiper-container.how-work-slider {
		height: 600px;
		margin-bottom: 60px;
	}
}

.swiper-container.how-work-slider .swiper-slide {
	background: 0 0;
	padding-left: 0;
	padding: 20px 30px;
}

@media only screen and (max-width: 479px) {
	.swiper-container.how-work-slider .swiper-slide {
		padding: 10px;
	}
}

.swiper-container.how-work-slider .swiper-slide .img {
	margin-right: 30px;
}

@media only screen and (max-width: 479px) {
	.swiper-container.how-work-slider .swiper-slide .img {
		margin-right: 20px;
	}
}

.swiper-container.how-work-slider .swiper-slide .content h3 {
	font-size: 30px;
	font-weight: 600;
}

@media only screen and (max-width: 575px) {
	.swiper-container.how-work-slider .swiper-slide .content h3 {
		font-size: 22px;
	}
}

.swiper-container.how-work-slider .swiper-slide .content p {
	font-size: 20px;
}

@media only screen and (max-width: 575px) {
	.swiper-container.how-work-slider .swiper-slide .content p {
		font-size: 16px;
	}
}

.swiper-container.how-work-slider .swiper-slide.active {
	background-color: #fff;
}

.services .icon-box {
	margin-bottom: 20px;
	padding: 50px 40px;
	border-radius: 6px;
	background: #fff;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.services .icon-box i {
	float: left;
	color: #f6b024;
	font-size: 40px;
}

.services .icon-box h4 {
	margin-left: 70px;
	font-weight: 700;
	margin-bottom: 15px;
	font-size: 18px;
}

.services .icon-box h4 a {
	color: #05579e;
	transition: 0.3s;
}

.services .icon-box h4 a:hover {
	color: #0880e8;
}

.services .icon-box p {
	margin-left: 70px;
	line-height: 24px;
	font-size: 14px;
	margin-bottom: 0;
}

.title-para {
	font-size: 2.25rem;
	color: #000000;
	font-family: sans-serif;

}
.title-desc {
	font-size: 1.3rem;
}

@media (max-width: 768px) {
	.title-para{
		font-size: 2rem;
	}
	.title-desc{
		font-size: 1.2rem;
	}
}
/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/

.cta h3 {
	color: #fff;
	font-size: 28px;
	font-weight: 700;
}

.cta p {
	color: #fff;
}

.cta .cta-btn {
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: 0.5px;
	display: inline-block;
	padding: 8px 26px;
	border-radius: 2px;
	transition: 0.5s;
	margin: 10px;
	border-radius: 50px;
	border: 2px solid #f6b024;
	color: #fff;
}

.cta .cta-btn:hover {
	background: #f6b024;
}

@media (max-width: 1024px) {
	.cta {
		background-attachment: scroll;
	}
}

@media (min-width: 769px) {
	.cta .cta-btn-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

/*--------------------------------------------------------------
# Portfoio
--------------------------------------------------------------*/
.portfoio #portfolio-flters {
	padding: 0;
	margin: 0 auto 25px auto;
	list-style: none;
	text-align: center;
	border-radius: 50px;
}

.portfoio #portfolio-flters li {
	cursor: pointer;
	display: inline-block;
	padding: 8px 18px 10px 18px;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	color: #444444;
	margin: 0 3px 10px 3px;
	transition: all ease-in-out 0.3s;
	border-radius: 50px;
}

.portfoio #portfolio-flters li:hover,
.portfoio #portfolio-flters li.filter-active {
	color: #fff;
	background: #0880e8;
}

.portfoio #portfolio-flters li:last-child {
	margin-right: 0;
}

.portfoio .portfolio-item {
	margin-bottom: 30px;
	overflow: hidden;
}

.portfoio .portfolio-item img {
	position: relative;
	top: 0;
	transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.portfoio .portfolio-item .portfolio-info {
	opacity: 0;
	position: absolute;
	left: 15px;
	right: 15px;
	bottom: -50px;
	z-index: 3;
	transition: all ease-in-out 0.3s;
	background: #0880e8;
	padding: 15px 20px;
}

.portfoio .portfolio-item .portfolio-info h4 {
	font-size: 18px;
	color: #fff;
	font-weight: 600;
}

.portfoio .portfolio-item .portfolio-info p {
	color: #fff;
	font-size: 14px;
	margin-bottom: 0;
}

.portfoio .portfolio-item .portfolio-info .preview-link,
.portfoio .portfolio-item .portfolio-info .details-link {
	position: absolute;
	right: 50px;
	font-size: 24px;
	top: calc(50% - 18px);
	color: white;
	transition: ease-in-out 0.3s;
}

.portfoio .portfolio-item .portfolio-info .preview-link:hover,
.portfoio .portfolio-item .portfolio-info .details-link:hover {
	color: #8ec8fb;
}

.portfoio .portfolio-item .portfolio-info .details-link {
	right: 15px;
}

.portfoio .portfolio-item:hover img {
	top: -30px;
}

.portfoio .portfolio-item:hover .portfolio-info {
	opacity: 1;
	bottom: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
	position: relative;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
	padding: 30px;
	border-radius: 10px;
	background: #fff;
}

.team .member .pic {
	overflow: hidden;
	width: 180px;
	border-radius: 50%;
}

.team .member .pic img {
	transition: ease-in-out 0.3s;
}

.team .member:hover img {
	transform: scale(1.1);
}

.team .member .member-info {
	padding-left: 30px;
}

.team .member h4 {
	font-weight: 700;
	margin-bottom: 5px;
	font-size: 20px;
	color: #05579e;
}

.team .member span {
	display: block;
	font-size: 15px;
	padding-bottom: 10px;
	position: relative;
	font-weight: 500;
}

.team .member span::after {
	content: '';
	position: absolute;
	display: block;
	width: 50px;
	height: 1px;
	background: #bfe0fd;
	bottom: 0;
	left: 0;
}

.team .member p {
	margin: 10px 0 0 0;
	font-size: 14px;
}

.team .member .social {
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.team .member .social a {
	transition: ease-in-out 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	width: 32px;
	height: 32px;
	background: #ecf6fe;
	color: #0665b7;
}

.team .member .social a i {
	font-size: 16px;
	margin: 0 2px;
}

.team .member .social a:hover {
	background: #0880e8;
	color: #fff;
}

.team .member .social a + a {
	margin-left: 8px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
	padding: 20px;
	background: #fff;
	text-align: center;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}

.pricing h3 {
	font-weight: 400;
	margin: -20px -20px 20px -20px;
	padding: 20px 15px;
	font-size: 18px;
	font-weight: 700;
	color: #05579e;
	background: #f8f8f8;
}

.pricing h4 {
	font-size: 36px;
	color: #0880e8;
	font-weight: 600;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 20px;
}

.pricing h4 sup {
	font-size: 20px;
	top: -15px;
	left: -3px;
}

.pricing h4 span {
	color: #bababa;
	font-size: 16px;
	font-weight: 300;
}

.pricing ul {
	padding: 0;
	list-style: none;
	color: #444444;
	text-align: center;
	line-height: 20px;
	font-size: 14px;
}

.pricing ul li {
	padding-bottom: 16px;
}

.pricing ul i {
	color: #0880e8;
	font-size: 18px;
	padding-right: 4px;
}

.pricing ul .na {
	color: #ccc;
}

.pricing .btn-wrap {
	margin: 20px -20px -20px -20px;
	padding: 20px 15px;
	background: #f8f8f8;
	text-align: center;
}

.pricing .btn-buy {
	background: #0880e8;
	display: inline-block;
	padding: 8px 35px 10px 35px;
	border-radius: 50px;
	color: #fff;
	transition: none;
	font-size: 14px;
	font-weight: 400;
	font-family: 'Raleway', sans-serif;
	font-weight: 600;
	transition: 0.3s;
}

.pricing .btn-buy:hover {
	background: #2b99f8;
}

.pricing .featured h3 {
	color: #fff;
	background: #0880e8;
}

.pricing .advanced {
	width: 200px;
	position: absolute;
	top: 18px;
	right: -68px;
	transform: rotate(45deg);
	z-index: 1;
	font-size: 14px;
	padding: 1px 0 3px 0;
	background: #0880e8;
	color: #fff;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
	padding: 0 100px;
}

.faq .faq-list ul {
	padding: 0;
	list-style: none;
}

.faq .faq-list li + li {
	margin-top: 15px;
}

.faq .faq-list li {
	padding: 20px;
	background: #fff;
	border-radius: 4px;
	position: relative;
}

.faq .faq-list a {
	display: block;
	position: relative;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	padding: 0 30px;
	outline: none;
}

.faq .faq-list .icon-help {
	font-size: 24px;
	position: absolute;
	right: 0;
	left: 20px;
	color: #75bdfa;
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
	font-size: 24px;
	position: absolute;
	right: 0;
	top: 0;
}

.faq .faq-list p {
	margin-bottom: 0;
	padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
	display: none;
}

.faq .faq-list a.collapsed {
	color: #343a40;
}

.faq .faq-list a.collapsed:hover {
	color: #0880e8;
}

.faq .faq-list a.collapsed .icon-show {
	display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
	display: none;
}

@media (max-width: 1200px) {
	.faq .faq-list {
		padding: 0;
	}
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
	background: url('../img/about-bg.png') top center no-repeat;
	position: relative;
}

.contact:before {
	content: '';
	background: rgba(255, 255, 255, 0.7);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

.contact .container {
	position: relative;
}

.contact .info {
	width: 100%;
}

.contact .info i {
	font-size: 20px;
	background: #0880e8;
	color: #fff;
	float: left;
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	transition: all 0.3s ease-in-out;
}

.contact .info h4 {
	padding: 0 0 0 60px;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 5px;
	color: #043c6d;
}

.contact .info p {
	padding: 0 0 0 60px;
	margin-bottom: 0;
	font-size: 14px;
	color: #444444;
}

.contact .info .email,
.contact .info .phone {
	margin-top: 40px;
}

.contact .php-email-form {
	width: 100%;
	background: #fff;
}

.contact .php-email-form .form-group {
	padding-bottom: 8px;
}

.contact .php-email-form .validate {
	display: none;
	color: red;
	margin: 0 0 15px 0;
	font-weight: 400;
	font-size: 13px;
}

.contact .php-email-form .error-message {
	display: none;
	color: #fff;
	background: #ed3c0d;
	text-align: left;
	padding: 15px;
	font-weight: 600;
}

.contact .php-email-form .error-message br + br {
	margin-top: 25px;
}

.contact .php-email-form .sent-message {
	display: none;
	color: #fff;
	background: #18d26e;
	text-align: center;
	padding: 15px;
	font-weight: 600;
}

.contact .php-email-form .loading {
	display: none;
	background: #fff;
	text-align: center;
	padding: 15px;
}

.contact .php-email-form .loading:before {
	content: '';
	display: inline-block;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin: 0 10px -6px 0;
	border: 3px solid #18d26e;
	border-top-color: #eee;
	-webkit-animation: animate-loading 1s linear infinite;
	animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
	border-radius: 4px;
	box-shadow: none;
	font-size: 14px;
}

.contact .php-email-form input {
	height: 44px;
}

.contact .php-email-form textarea {
	padding: 10px 12px;
}

.contact .php-email-form button[type='submit'] {
	border: 0;
	padding: 10px 32px;
	color: #0880e8;
	transition: 0.4s;
	border-radius: 50px;
	border: 2px solid #0880e8;
	background: #fff;
}

.contact .php-email-form button[type='submit']:hover {
	background: #0880e8;
	color: #fff;
}

@-webkit-keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
	padding: 15px 0;
	background: #ecf6fe;
	margin-top: 130px;
}

@media (max-width: 992px) {
	.breadcrumbs {
		margin-top: 50px;
	}
}

.breadcrumbs h2 {
	font-size: 26px;
	font-weight: 600;
	color: #043c6d;
}

.breadcrumbs ol {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0 0 10px 0;
	margin: 0;
	font-size: 14px;
}

.breadcrumbs ol li + li {
	padding-left: 10px;
}

.breadcrumbs ol li + li::before {
	display: inline-block;
	padding-right: 10px;
	color: #f8c255;
	content: '/';
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
	padding-top: 40px;
}

.portfolio-details .portfolio-details-container {
	position: relative;
}

.portfolio-details .portfolio-details-carousel {
	position: relative;
	z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
	margin-top: 5px;
	text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
	display: inline-block;
	margin: 0 10px 0 0;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
	background-color: #0880e8 !important;
}

.portfolio-details .portfolio-info {
	padding: 30px;
	position: absolute;
	right: 0;
	bottom: -70px;
	background: #fff;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
	z-index: 2;
}

.portfolio-details .portfolio-info h3 {
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
	list-style: none;
	padding: 0;
	font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
	margin-top: 10px;
}

.portfolio-details .portfolio-description {
	padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
	width: 50%;
	font-size: 26px;
	font-weight: 700;
	margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
	padding: 0 0 0 0;
}

@media (max-width: 768px) {
	.portfolio-details .portfolio-info {
		position: static;
		margin-top: 30px;
	}
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
	padding: 40px 0;
}

.blog .entry {
	padding: 20px;
	margin-bottom: 60px;
	overflow: hidden;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
	max-height: 400px;
	margin: -20px -20px 20px -20px;
	overflow: hidden;
}

.blog .entry .entry-title {
	font-size: 20px;
	line-height: 26px;
	font-weight: bold;
	padding: 0;
	margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
	color: #054a85;
	transition: 0.3s;
}

.blog .entry .entry-title a:hover {
	color: #0880e8;
}

.blog .entry .entry-meta {
	margin-bottom: 15px;
	color: #2b99f8;
}

.blog .entry .entry-meta ul {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 0;
}

.blog .entry .entry-meta ul li + li {
	padding-left: 20px;
}

.blog .entry .entry-meta i {
	font-size: 14px;
	padding-right: 4px;
}

.blog .entry .entry-meta a {
	color: #2b99f8;
	font-size: 14px;
	display: inline-block;
	line-height: 1;
}

.blog .entry .entry-content p {
	line-height: 24px;
	font-size: 15px;
}

.blog .entry .entry-content .read-more {
	-moz-text-align-last: right;
	text-align-last: right;
}

.blog .entry .entry-content .read-more a {
	display: inline-block;
	background: #f6b024;
	color: #fff;
	padding: 6px 20px 8px 20px;
	transition: 0.3s;
	font-size: 14px;
	border-radius: 50px;
}

.blog .entry .entry-content .read-more a:hover {
	background: #0880e8;
}

.blog .entry .entry-content h3 {
	font-size: 22px;
	margin-top: 30px;
	font-weight: bold;
}

.blog .entry .entry-content blockquote {
	overflow: hidden;
	background-color: #fafafa;
	padding: 60px;
	position: relative;
	text-align: center;
	margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
	color: #444444;
	line-height: 1.6;
	margin-bottom: 0;
	font-style: italic;
	font-weight: 500;
	font-size: 22px;
}

.blog .entry .entry-content blockquote .quote-left {
	position: absolute;
	left: 20px;
	top: 20px;
	font-size: 36px;
	color: #e7e7e7;
}

.blog .entry .entry-content blockquote .quote-right {
	position: absolute;
	right: 20px;
	bottom: 20px;
	font-size: 36px;
	color: #e7e7e7;
}

.blog .entry .entry-content blockquote::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 3px;
	background-color: #0880e8;
	margin-top: 20px;
	margin-bottom: 20px;
}

.blog .entry .entry-footer {
	padding-top: 10px;
	border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
	color: #8ec8fb;
	display: inline;
}

.blog .entry .entry-footer a {
	color: #e7f3fe;
	transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
	color: #f6b024;
}

.blog .entry .entry-footer .cats {
	list-style: none;
	display: inline;
	padding: 0 20px 0 0;
	font-size: 14px;
}

.blog .entry .entry-footer .cats li {
	display: inline-block;
}

.blog .entry .entry-footer .tags {
	list-style: none;
	display: inline;
	padding: 0;
	font-size: 14px;
}

.blog .entry .entry-footer .tags li {
	display: inline-block;
}

.blog .entry .entry-footer .tags li + li::before {
	padding-right: 6px;
	color: #6c757d;
	content: ',';
}

.blog .entry .entry-footer .share {
	font-size: 16px;
}

.blog .entry .entry-footer .share i {
	padding-left: 5px;
}

.blog .entry-single {
	margin-bottom: 30px;
}

.blog .entry-single .entry-title {
	font-size: 32px;
	line-height: 38px;
}

.blog .blog-author {
	padding: 20px;
	margin-bottom: 30px;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
	width: 120px;
}

.blog .blog-author h4 {
	margin-left: 140px;
	font-weight: 600;
	font-size: 22px;
	margin-bottom: 0px;
	padding: 0;
}

.blog .blog-author .social-links {
	margin: 0 0 5px 140px;
}

.blog .blog-author .social-links a {
	color: #bfe0fd;
}

.blog .blog-author p {
	margin-left: 140px;
	font-style: italic;
	color: #b7b7b7;
}

.blog .blog-comments {
	margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
	font-weight: bold;
}

.blog .blog-comments .comment {
	margin-top: 30px;
	position: relative;
}

.blog .blog-comments .comment .comment-img {
	width: 50px;
}

.blog .blog-comments .comment h5 {
	margin-left: 65px;
	font-size: 16px;
	margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
	font-weight: bold;
	color: #444444;
	transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
	color: #f6b024;
}

.blog .blog-comments .comment h5 .reply {
	padding-left: 10px;
	color: #0880e8;
}

.blog .blog-comments .comment time {
	margin-left: 65px;
	display: block;
	font-size: 14px;
	color: #2b99f8;
	margin-bottom: 5px;
}

.blog .blog-comments .comment p {
	margin-left: 65px;
}

.blog .blog-comments .comment.comment-reply {
	padding-left: 40px;
}

.blog .blog-comments .reply-form {
	margin-top: 30px;
	padding: 30px;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
	font-weight: bold;
	font-size: 22px;
}

.blog .blog-comments .reply-form p {
	font-size: 14px;
}

.blog .blog-comments .reply-form input {
	border-radius: 4px;
	padding: 20px 10px;
	font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
	box-shadow: none;
	border-color: #fad386;
}

.blog .blog-comments .reply-form textarea {
	border-radius: 0;
	padding: 10px 10px;
	font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
	box-shadow: none;
	border-color: #fad386;
}

.blog .blog-comments .reply-form .form-group {
	margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
	border-radius: 50px;
	padding: 10px 30px;
	border: 0;
	background-color: #0880e8;
}

.blog .blog-comments .reply-form .btn-primary:hover {
	background-color: #f6b024;
}

.blog .blog-pagination {
	color: #5db1f9;
}

.blog .blog-pagination ul {
	display: flex;
	padding-left: 0;
	list-style: none;
}

.blog .blog-pagination li {
	border: 1px solid white;
	margin: 0 5px;
	transition: 0.3s;
}

.blog .blog-pagination li.active {
	background: white;
}

.blog .blog-pagination li a {
	color: #aaaaaa;
	padding: 7px 16px;
	display: inline-block;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
	background: #0880e8;
	border: 1px solid #0880e8;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
	color: #fff;
}

.blog .blog-pagination li.disabled {
	background: #fff;
	border: 1px solid white;
}

.blog .blog-pagination li.disabled i {
	color: #f1f1f1;
	padding: 10px 16px;
	display: inline-block;
}

.blog .sidebar {
	padding: 30px;
	margin: 0 0 60px 20px;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
	font-size: 20px;
	font-weight: 700;
	padding: 0 0 0 0;
	margin: 0 0 15px 0;
	color: #054a85;
	position: relative;
}

.blog .sidebar .sidebar-item {
	margin-bottom: 30px;
}

.blog .sidebar .search-form form {
	background: #fff;
	border: 1px solid #ddd;
	padding: 3px 10px;
	position: relative;
	border-radius: 50px;
}

.blog .sidebar .search-form form input[type='text'] {
	border: 0;
	padding: 4px;
	width: calc(100% - 60px);
}

.blog .sidebar .search-form form button {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	border: 0;
	background: none;
	font-size: 16px;
	padding: 0 25px;
	margin: -1px;
	border-radius: 50px;
	background: #0880e8;
	color: #fff;
	transition: 0.3s;
}

.blog .sidebar .search-form form button:hover {
	background: #2b99f8;
}

.blog .sidebar .categories ul {
	list-style: none;
	padding: 0;
}

.blog .sidebar .categories ul li + li {
	padding-top: 10px;
}

.blog .sidebar .categories ul a {
	color: #0665b7;
	transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
	color: #f6b024;
}

.blog .sidebar .categories ul a span {
	padding-left: 5px;
	color: #8ec8fb;
	font-size: 14px;
}

.blog .sidebar .recent-posts .post-item + .post-item {
	margin-top: 15px;
}

.blog .sidebar .recent-posts img {
	width: 80px;
	float: left;
}

.blog .sidebar .recent-posts h4 {
	font-size: 15px;
	margin-left: 95px;
	font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
	color: #054a85;
	transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
	color: #f6b024;
}

.blog .sidebar .recent-posts time {
	display: block;
	margin-left: 95px;
	font-style: italic;
	font-size: 14px;
	color: #138df7;
}

.blog .sidebar .tags {
	margin-bottom: -10px;
}

.blog .sidebar .tags ul {
	list-style: none;
	padding: 0;
}

.blog .sidebar .tags ul li {
	display: inline-block;
}

.blog .sidebar .tags ul a {
	color: #0880e8;
	font-size: 14px;
	padding: 6px 14px;
	margin: 0 6px 8px 0;
	border: 1px solid #ddeefe;
	border-radius: 50px;
	display: inline-block;
	transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
	color: #fff;
	border-color: #f6b024;
	background: #f6b024;
}

.blog .sidebar .tags ul a span {
	padding-left: 5px;
	color: white;
	font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
	background: #000000;
	padding: 0 0 30px 0;
	color: #fff;
	font-size: 14px;
}

#footer .footer-top {
	padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
	margin-bottom: 15px;
	color: #fff;
	text-align: center;
	padding: 30px 20px;
	border: 4px solid #374848;
}

#footer .footer-top .footer-info h3 {
	font-size: 24px;
	margin: 0 0 20px 0;
	padding: 2px 0 2px 0;
	line-height: 1;
	font-weight: 700;
	color: white;
}

#footer .footer-top .footer-info p {
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 0;
	font-family: 'Raleway', sans-serif;
}

#footer .footer-top .social-links a {
	font-size: 18px;
	display: inline-block;
	background: #334242;
	color: #fff;
	line-height: 1;
	padding: 8px 0;
	margin-right: 4px;
	border-radius: 4px;
	text-align: center;
	width: 36px;
	height: 36px;
	transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
	background: #00c1c1;
	color: #fff;
	text-decoration: none;
}

#footer .footer-top h4 {
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	position: relative;
	padding-bottom: 12px;
}

#footer .footer-top .footer-links {
	margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

#footer .footer-top .footer-links ul i {
	padding-right: 2px;
	color: #b16448;
	font-size: 18px;
	line-height: 1;
}

#footer .footer-top .footer-links ul li {
	padding: 10px 0;
	display: flex;
	align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
	padding-top: 0;
}

#footer .footer-top .footer-links ul a {
	color: #fff;
	transition: 0.3s;
	display: inline-block;
	line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
	color: #b16448;
}

#footer .footer-top .footer-newsletter form {
	margin-top: 30px;
	background: #fff;
	padding: 6px 10px;
	position: relative;
	border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type='email'] {
	border: 0;
	padding: 4px;
	width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type='submit'] {
	position: absolute;
	top: 0;
	right: -2px;
	bottom: 0;
	border: 0;
	background: none;
	font-size: 16px;
	padding: 0 20px;
	background: #ff1100;
	color: #fff;
	transition: 0.3s;
	border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type='submit']:hover {
	background: #008e8e;
}

#footer .copyright {
	border-top: 1px solid #3e5050;
	text-align: center;
	padding-top: 30px;
}

#footer .credits {
	padding-top: 10px;
	text-align: center;
	font-size: 13px;
	color: #fff;
}

@media (max-width: 575px) {
	#footer .footer-top .footer-info {
		margin: -20px 0 30px 0;
	}
}

*,
::after,
::before {
	outline: 0 !important;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
	-webkit-transition: background-color 5000s ease-in-out 0s;
	transition: background-color 5000s ease-in-out 0s;
}

::-moz-selection {
	text-shadow: none;
	color: #fff;
}

::selection {
	text-shadow: none;
	color: #fff;
}

::-webkit-input-placeholder {
	color: #707070 !important;
	opacity: 1 !important;
}

::-moz-placeholder {
	color: #707070 !important;
	opacity: 1 !important;
}

:-ms-input-placeholder {
	color: #707070 !important;
	opacity: 1 !important;
}

::-ms-input-placeholder {
	color: #707070 !important;
	opacity: 1 !important;
}

::placeholder {
	color: #707070 !important;
	opacity: 1 !important;
}

iframe {
	max-width: 100%;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
	outline: 0 !important;
}

input[type='checkbox'] {
	margin-right: 5px;
	cursor: pointer;
}

label {
	margin: 0;
}

button,
button[type='submit'],
input[type='submit'] {
	border: none;
	background-color: transparent;
	cursor: pointer;
	-webkit-appearance: button;
	-moz-appearance: button;
}

select {
	width: 100%;
	max-width: 100%;
	color: #707070;
}

textarea {
	resize: none;
}

img {
	max-width: 100%;
	height: auto;
}

a,
a:active,
a:focus,
a:hover,
input,
input:active,
input:focus,
input:hover,
select,
textarea {
	text-decoration: none;
	outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0;
	margin: 0;
	-ms-word-wrap: break-word;
	word-wrap: break-word;
	font-weight: 700;
	line-height: 1;
	text-transform: capitalize;
}

p:last-child {
	margin: 0;
}

h1 {
	font-size: 46px;
}

h2 {
	font-size: 40px;
}

h3 {
	font-size: 24px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

table {
	width: 100%;
	max-width: 100%;
}

table td,
table th {
	padding: 15px 30px;
}

@media only screen and (max-width: 479px) {
	table td,
	table th {
		padding: 10px;
	}
}

table th {
	color: #292929;
	font-weight: 700;
	border-bottom: 1px solid #d1d1d1;
}

table td {
	border-bottom: 1px solid #f6f6f6;
}

table td a {
	color: #292929;
	font-weight: 700;
	font-size: 14px;
}

table td a:hover {
	color: #ff937d;
}

.list-check,
.list-uncheck,
.list-unstyled,
.price-feature,
.price-list,
.quick-links ul,
.tab-btn ul,
.widget ul,
.widget.job-info ul,
.widget.widget_footer_menu .footer_menu ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

p {
	margin-bottom: 20px;
	font-weight: 300;
}

@media only screen and (max-width: 575px) {
	p {
		font-size: 18px;
	}
}

.feature-p,
.footer .widget.widget_text p,
.price-box .price-head p,
.single-service .service-content P,
.testimonial-single .ts-bottom p {
	font-size: 16px;
	font-weight: 400;
}

pre {
	border: 1px solid;
	border-radius: 4px;
	font-size: 16px;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 0.8em 1.6em;
}

code {
	background-color: rgba(27, 31, 35, 0.05);
	border-radius: 3px;
	font-size: 85%;
	margin: 0;
	padding: 0.2em 0.4em;
}

body,
html {
	overflow-x: hidden !important;
}

body {
	font-size: 20px;
	line-height: 1.78;
	color: #707070;
}

.pt-160 {
	padding-top: 160px;
}

@media only screen and (max-width: 991px) {
	.pt-160 {
		padding-top: 100px;
	}
}

.pt-140 {
	padding-top: 100px;
}

@media only screen and (max-width: 991px) {
	.pt-140 {
		padding-top: 80px;
	}
}

.pt-130 {
	padding-top: 130px;
}

@media only screen and (max-width: 991px) {
	.pt-130 {
		padding-top: 70px;
	}
}

.pt-120 {
	padding-top: 120px;
}

@media only screen and (max-width: 991px) {
	.pt-120 {
		padding-top: 60px;
	}
}

.pt-110 {
	padding-top: 110px;
}

@media only screen and (max-width: 991px) {
	.pt-110 {
		padding-top: 50px;
	}
}

.pt-100 {
	padding-top: 100px;
}

@media only screen and (max-width: 991px) {
	.pt-100 {
		padding-top: 40px;
	}
}

.pt-90 {
	padding-top: 90px;
}

@media only screen and (max-width: 991px) {
	.pt-90 {
		padding-top: 30px;
	}
}

.pt-80 {
	padding-top: 80px;
}

@media only screen and (max-width: 991px) {
	.pt-80 {
		padding-top: 20px;
	}
}

.pt-70 {
	padding-top: 70px;
}

@media only screen and (max-width: 991px) {
	.pt-70 {
		padding-top: 10px;
	}
}

.pt-60 {
	padding-top: 60px;
}

@media only screen and (max-width: 991px) {
	.pt-60 {
		padding-top: 0;
	}
}

.pt-50 {
	padding-top: 50px;
}

@media only screen and (max-width: 991px) {
	.pt-50 {
		padding-top: 0;
	}
}

.pt-40 {
	padding-top: 40px;
}

@media only screen and (max-width: 991px) {
	.pt-40 {
		padding-top: 0;
	}
}

.pt-30 {
	padding-top: 30px;
}

@media only screen and (max-width: 991px) {
	.pt-30 {
		padding-top: 0;
	}
}

.pt-20 {
	padding-top: 20px;
}

@media only screen and (max-width: 991px) {
	.pt-20 {
		padding-top: 0;
	}
}

.pt-10 {
	padding-top: 10px;
}

@media only screen and (max-width: 991px) {
	.pt-10 {
		padding-top: 0;
	}
}

.pb-160 {
	padding-bottom: 160px;
}

@media only screen and (max-width: 991px) {
	.pb-160 {
		padding-bottom: 100px;
	}
}

@media only screen and (max-width: 991px) {
	.pb-140 {
		padding-bottom: 3rem;
	}
}

.pb-130 {
	padding-bottom: 130px;
}

@media only screen and (max-width: 991px) {
	.pb-130 {
		padding-bottom: 70px;
	}
}

.pb-120 {
	padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
	.pb-120 {
		padding-bottom: 60px;
	}
}

.pb-110 {
	padding-bottom: 110px;
}

@media only screen and (max-width: 991px) {
	.pb-110 {
		padding-bottom: 50px;
	}
}

.pb-100 {
	padding-bottom: 100px;
}

@media only screen and (max-width: 991px) {
	.pb-100 {
		padding-bottom: 40px;
	}
}

.pb-90 {
	padding-bottom: 90px;
}

@media only screen and (max-width: 991px) {
	.pb-90 {
		padding-bottom: 30px;
	}
}

.pb-80 {
	padding-bottom: 80px;
}

@media only screen and (max-width: 991px) {
	.pb-80 {
		padding-bottom: 20px;
	}
}

.pb-70 {
	padding-bottom: 70px;
}

@media only screen and (max-width: 991px) {
	.pb-70 {
		padding-bottom: 10px;
	}
}

.pb-60 {
	padding-bottom: 60px;
}

@media only screen and (max-width: 991px) {
	.pb-60 {
		padding-bottom: 0;
	}
}

.pb-50 {
	padding-bottom: 50px;
}

@media only screen and (max-width: 991px) {
	.pb-50 {
		padding-bottom: 0;
	}
}

.pb-40 {
	padding-bottom: 40px;
}

@media only screen and (max-width: 991px) {
	.pb-40 {
		padding-bottom: 0;
	}
}

.pb-30 {
	padding-bottom: 30px;
}

@media only screen and (max-width: 991px) {
	.pb-30 {
		padding-bottom: 0;
	}
}

.pb-20 {
	padding-bottom: 20px;
}

@media only screen and (max-width: 991px) {
	.pb-20 {
		padding-bottom: 0;
	}
}

.pb-10 {
	padding-bottom: 10px;
}

@media only screen and (max-width: 991px) {
	.pb-10 {
		padding-bottom: 0;
	}
}

.mt-140 {
	margin-top: 140px;
}

.mt-100 {
	margin-top: 100px;
}

.mt-90 {
	margin-top: 90px;
}

.mt-80 {
	margin-top: 80px;
}

.mt-70 {
	margin-top: 70px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-44 {
	margin-top: 44px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-10 {
	margin-top: 10px;
}

.mb-140 {
	margin-bottom: 140px;
}

.mb-100 {
	margin-bottom: 100px;
}

.mb-90 {
	margin-bottom: 90px;
}

.mb-80 {
	margin-bottom: 80px;
}

.mb-70 {
	margin-bottom: 70px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-44 {
	margin-bottom: 44px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-10 {
	margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
	.mb-md-60 {
		margin-bottom: 60px;
	}

	.mb-md-80 {
		margin-bottom: 80px;
	}

	.pb-md-80 {
		padding-bottom: 80px;
	}
}

.ml-10 {
	margin-left: 10px;
}

.mb-44 {
	margin-bottom: 44px !important;
}

#mobile_menu .mobile-main-menu .nav li a:hover,
#mobile_menu .mobile-main-menu .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu .nav li.current-menu-parent > a,
.animate-btn.btn-book,
.animate-btn.btn-book:hover,
.animate-btn.btn-crm,
.animate-btn.btn-crm:hover,
.animate-btn.btn-crs,
.animate-btn.btn-crs:hover,
.animate-btn.btn-pay,
.animate-btn.btn-pay:hover,
.animate-btn.e-btn,
.animate-btn.e-btn:hover,
.animate-btn.m-btn,
.animate-btn.m-btn:hover,
.btn.animate-btn,
.btn.animate-btn:hover,
.c1,
.header .header-main .main-menu #menu-button,
.header .header-main .main-menu .nav li a:hover,
.header .header-main .main-menu .nav li ul li,
.header .header-main.email .main-menu .nav li ul li,
.header-btn .link-btn:hover,
.post-details .entry-categories ul li a:hover,
.post-details .entry-tags ul li a:hover,
.theme-input-group button.submit-btn:hover,
.theme-input-group button:hover,
.ui-range-slider .total-earning strong,
.widget.widget_footer_menu .footer_menu li a:hover,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #eae9f1;
}

::-moz-selection {
	background-color: #5f52dc;
}

.btn.s-btn,
.c1-bg,
.header .header-main .main-menu .offcanvas-trigger span,
.owl-carousel button.owl-dot,
.s-btn.btn-book,
.s-btn.btn-crm,
.s-btn.btn-crs,
.s-btn.btn-pay,
.s-btn.e-btn,
.s-btn.m-btn,
.social-icon a:hover,
.theme-input-group input,
::selection {
	background-color: #5f52dc;
}

.c1-bo {
	border-color: #5f52dc !important;
}

.c2,
.footer .copyright-footer .copyright-text a:hover,
.page-title ul li,
.star-rating i,
.swiper-button-next:after,
.swiper-button-prev:after,
.testimonial-single .review strong,
.ui-range-slider .sale strong,
.widget.widget_footer_menu .footer_menu.affiliate li a:hover,
.widget.widget_service .service-list li a:hover,
.widget.widget_service .service-list li a:hover i,
.widget.widget_service .service-list li a:hover svg *,
.widget.widget_tag_cloud .tagcloud a:hover,
a:hover {
	color: #fcbf21;
}

.animate-btn.btn-book:after,
.animate-btn.btn-crm:after,
.animate-btn.btn-crs:after,
.animate-btn.btn-pay:after,
.animate-btn.e-btn:after,
.animate-btn.m-btn:after,
.ba,
.back-to-top,
.border-btn.btn-book:hover,
.border-btn.btn-crm:hover,
.border-btn.btn-crs:hover,
.border-btn.btn-pay:hover,
.border-btn.e-btn:hover,
.border-btn.m-btn:hover,
.btn,
.btn-book,
.btn-crm,
.btn-crs,
.btn-pay,
.btn.animate-btn:after,
.btn.border-btn:hover,
.c2-bg,
.e-btn,
.m-btn,
.owl-carousel button.owl-dot.active,
.theme-input-group button,
.theme-input-group button.submit-btn,
.theme-input-group button.submit-btn:hover,
.theme-input-group button:hover,
.theme-input-group.crs button:hover {
	background-color: #fcbf21;
}

.c2-bo {
	border-color: #fcbf21 !important;
}

.banner.analytic .banner-content p.info,
.border-btn.btn-book,
.border-btn.btn-crm,
.border-btn.btn-crs,
.border-btn.btn-pay,
.border-btn.e-btn,
.border-btn.m-btn,
.btn.border-btn,
.comment-content .form-group select,
.comment-content .widget select,
.cta-content.book .paperback .order-box .price .stroke-text del,
.earn-path-feature .single-service .service-content p,
.email-testimonial-slider.app2 .testimonial-item h3 span,
.entry-content .form-group select,
.entry-content .widget select,
.fake-btn,
.footer.pos .widget .footer_menu li a,
.form-group .comment-content select,
.form-group .custom-select .select-selected,
.form-group .custom-select .select-selected:after,
.form-group .entry-content select,
.form-group .page--content select,
.form-group .theme-input-style,
.lecture-container .left-content .title,
.page--content .form-group select,
.page--content .widget select,
.post-details .entry-categories ul li a,
.post-details .entry-header .entry-meta li a,
.post-details .entry-tags ul li a,
.post-navigation .nav-link .posted-on,
.single-blog-item .blog-content .blog-meta a,
.social-links a,
.text-color,
.theme-input-group.app-banner input,
.theme-input-group.app2 input,
.theme-input-group.email-banner input,
.theme-input-group.management input,
.theme-input-group.management2 input,
.theme-input-group.pay input,
.theme-input-group.proto input,
.theme-input-group.style--two input,
.widget .comment-content select,
.widget .custom-select .select-selected,
.widget .custom-select .select-selected:after,
.widget .entry-content select,
.widget .page--content select,
.widget .theme-input-style,
.widget.widget_categories ul li a,
.widget.widget_meta ul li a,
.widget.widget_nav_menu ul li a,
.widget.widget_recent_entries .post-summary .posted-on a,
.widget.widget_service .service-list li a,
.widget.widget_tag_cloud .tagcloud a {
	color: #707070;
}

#menu-button span,
#menu-button span:after,
#menu-button span:before,
.text-color-bg {
	background-color: #707070;
}

.text-color-bo {
	border-color: #707070 !important;
}

#mobile_menu .mobile-main-menu.style--two .nav li a:hover,
#mobile_menu .mobile-main-menu.style--two .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.style--two .nav li.current-menu-parent > a,
.banner.style--two .banner-content h1,
.btn-crm.border-btn,
.btn-crm.s-btn,
.crm_c1,
.cta-content.style--two h2,
.footer .copyright-footer .copyright-text.style--two a:hover,
.footer .footer-top .footer-top-left h2,
.header .header-main.style--two .main-menu #menu-button,
.header .header-main.style--two .main-menu .nav li a:hover,
.single-data strong,
.social-icon.style--two a:hover,
.testimonial-single .ts-bottom p:before,
.theme-input-group.style--two button {
	color: #57548d;
}

.crm_c1-bg,
.single-service.style--two > span,
.social-icon.style--two a,
.theme-input-group.style--two button:hover {
	background-color: #57548d;
}

.crm_c1-bo {
	border-color: #57548d !important;
}

.crm_c2,
.header-btn .link-btn.style--two:hover,
.list-check li:after,
.theme-input-group.style--two button:hover,
.widget.widget_footer_menu .footer_menu.crm li a:hover {
	color: #ebd281;
}

.back-to-top.crm,
.btn-crm.border-btn:hover,
.btn-crm.s-btn,
.crm_c2-bg,
.social-icon.style--two a:hover,
.theme-input-group.style--two button {
	background-color: #ebd281;
}

.crm_c2-bo {
	border-color: #ebd281 !important;
}

#mobile_menu .mobile-main-menu.style--three .nav li a:hover,
#mobile_menu .mobile-main-menu.style--three .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.style--three .nav li.current-menu-parent > a,
.countdown-wrapper .countdown-tag,
.crs_c1,
.header .header-main.style--three .main-menu #menu-button,
.header .header-main.style--three .main-menu .nav li a:hover,
.lecture-container .details .preview-text,
.list-check.style--three li:after,
.price-box .btn-crs.white,
.price-box .price-head span strong,
.price-box .theme-input-group.style--four button.btn-crs,
.rating.student-rating strong,
.theme-input-group.style--four .price-box button.btn-crs,
.widget.widget_footer_menu .footer_menu.crs li a:hover {
	color: #5dd9c1;
}

.border-btn.e-btn:hover,
.btn-crs.border-btn:hover,
.btn-pay.border-btn:hover,
.crs_c1-bg,
.price-box.two,
.social-icon.course a:hover,
.theme-input-group.course input,
.video-play-button:hover {
	background-color: #5dd9c1;
}

.crs_c1-bo {
	border-color: #5dd9c1 !important;
}

#mobile_menu .mobile-main-menu.ebook .nav li a:hover,
#mobile_menu .mobile-main-menu.ebook .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.ebook .nav li.current-menu-parent > a,
.banner.book .banner-content .doller,
.banner.book .banner-content .star-rating i,
.crs_c2,
.feature-box.three h2,
.feature-box.three:before,
.feature-box.two h2,
.feature-box.two:before,
.feature-box:before,
.header .header-main.ebook .main-menu .nav li a:hover,
.header-btn .link-btn.style--three,
.rating strong {
	color: #fbc006;
}

.back-to-top.book,
.back-to-top.crs,
.btn-crs.s-btn,
.cart-btn span,
.crs_c2-bg,
.header-btn .link-btn.style--three:after,
.header-btn .link-btn.style--three:before,
.price-box .btn-crs.white:hover,
.price-box .theme-input-group.style--four button.btn-crs:hover,
.theme-input-group.author-newsletter button,
.theme-input-group.author-newsletter button:hover,
.theme-input-group.course button,
.theme-input-group.course button:hover,
.theme-input-group.style--four .price-box button.btn-crs:hover,
.video-play-button {
	background-color: #fbc006;
}

.crs_c2-bo {
	border-color: #fbc006 !important;
}

.pay_c1,
.social-icon.pay a i {
	color: #5a50a5;
}

.pay_c1-bg {
	background-color: #5a50a5;
}

.pay_c1-bo {
	border-color: #5a50a5 !important;
}

#mobile_menu .mobile-main-menu.style--four .nav li a:hover,
#mobile_menu .mobile-main-menu.style--four .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.style--four .nav li.current-menu-parent > a,
.header .header-main.style--four .main-menu .nav li a:hover,
.list-check.cards li:after,
.list-check.pos li:after,
.pay_c2,
.widget.widget_footer_menu .footer_menu.pay li a:hover {
	color: #fe957b;
}

.back-to-top.pay,
.pay_c2-bg,
.social-icon.pay a:hover,
.theme-input-group.pay button,
.theme-input-group.pay button:hover {
	background-color: #fe957b;
}

.pay_c2-bo {
	border-color: #fe957b !important;
}

.book_c1,
.cta-content.book .paperback .order-box .btn-book.s-btn:hover,
.cta-content.book .paperback .order-box .price strong,
.cta-content.book .paperback .order-box.box2 .btn-book.s-btn,
.feature-box h2,
.feature-box.two.four h2,
.theme-input-group.author-newsletter button:hover {
	color: #205ce2;
}

.bg-left,
.bg-right,
.book_c1-bg,
.chaptar .chaptar-box.active,
.chaptar .chaptar-box:hover,
.cta-content.book .paperback .order-box .btn-book.s-btn,
.cta-content.book .paperback .order-box.box2 .btn-book.s-btn:hover {
	background-color: #205ce2;
}

.book_c1-bo {
	border-color: #205ce2 !important;
}

#mobile_menu .mobile-main-menu.ebook .nav li a:hover,
#mobile_menu .mobile-main-menu.ebook .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.ebook .nav li.current-menu-parent > a,
.banner.book .banner-content .doller,
.banner.book .banner-content .star-rating i,
.crs_c2,
.feature-box.three h2,
.feature-box.three:before,
.feature-box.two h2,
.feature-box.two:before,
.feature-box:before,
.header .header-main.ebook .main-menu .nav li a:hover,
.header-btn .link-btn.style--three,
.rating strong {
	color: #fbc006;
}

.back-to-top.book,
.back-to-top.crs,
.btn-crs.s-btn,
.cart-btn span,
.crs_c2-bg,
.header-btn .link-btn.style--three:after,
.header-btn .link-btn.style--three:before,
.price-box .btn-crs.white:hover,
.price-box .theme-input-group.style--four button.btn-crs:hover,
.theme-input-group.author-newsletter button,
.theme-input-group.author-newsletter button:hover,
.theme-input-group.course button,
.theme-input-group.course button:hover,
.theme-input-group.style--four .price-box button.btn-crs:hover,
.video-play-button {
	background-color: #fbc006;
}

.crs_c2-bo {
	border-color: #fbc006 !important;
}

#mobile_menu .mobile-main-menu.plugin .nav li a:hover,
#mobile_menu .mobile-main-menu.plugin .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.plugin .nav li.current-menu-parent > a,
.header .header-main.plugin .main-menu .nav li a:hover,
.plug_c1 {
	color: #5dd9c1;
}

.back-to-top.plug,
.btn-crm.border-btn.style--two:hover,
.plug_c1-bg {
	background-color: #5dd9c1;
}

.plug_c1-bo {
	border-color: #5dd9c1 !important;
}

.banner.email.plugins .banner-content h1,
.banner.style--two.plugins .banner-content h1,
.plug_c2,
.widget.widget_footer_menu .footer_menu.plug li a:hover {
	color: #fbf349;
}

.plug_c2-bg,
.social-icon.plug a:hover,
.theme-input-group.pay.plug button,
.theme-input-group.pay.plug button:hover {
	background-color: #fbf349;
}

.plug_c2-bo {
	border-color: #fbf349 !important;
}

.content-404 a,
.custom-select .select-items .same-as-selected,
.custom-select .select-items div:hover,
.email_c1,
.header .header-main.email .main-menu #menu-button,
.line-btn,
.line-btn:hover,
.list-check.job li:after,
.member .member-content h3 a,
.page-title ul li:not(:last-child) a:hover,
.signup-form .login-text a,
.single-job .job-content .job-category,
.single-job .job-content h3 a:hover,
.theme-input-group.style--four button:hover {
	color: #857dff;
}

.btn.submit-btn:hover,
.coming-soon-img,
.email_c1-bg,
.line-btn:after,
.line-btn:before,
.modal-header .modal-title .modal-title-icon,
.search-form .theme-input-group input,
.social-icon.about a,
.submit-btn.btn-book:hover,
.submit-btn.btn-crm:hover,
.submit-btn.btn-crs:hover,
.submit-btn.btn-pay:hover,
.submit-btn.e-btn:hover,
.submit-btn.m-btn:hover,
.theme-input-group.email-banner button,
.widget.widget_newsletter.style--two .theme-input-group input,
.widget.widget_search .theme-input-group input {
	background-color: #857dff;
}

.custom-select .select-selected.select-arrow-active,
.email_c1-bo {
	border-color: #857dff !important;
}

#mobile_menu .mobile-main-menu.email .nav li a:hover,
#mobile_menu .mobile-main-menu.email .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.email .nav li.current-menu-parent > a,
.email-testimonial-slider .testimonial-item h3,
.email_c2,
.footer.style--four .widget .footer_menu li:hover a,
.header .header-main.email .main-menu .nav li a:hover,
.line-btn.job,
.line-btn.job-header,
.list-check.email-check li:after,
.member .member-content h3 a:hover,
.post-details .entry-header .entry-meta li a:hover,
.quick-links ul li a:hover,
.signup-form .login-text a:hover,
.social-links a:hover,
.widget.widget_categories ul li a:hover,
.widget.widget_meta ul li a:hover,
.widget.widget_nav_menu ul li a:hover,
.widget.widget_recent_entries .post-summary .post-title a:hover {
	color: #ff937d;
}

.back-to-top.email,
.btn.submit-btn,
.email_c2-bg,
.line-btn.job-header:after,
.line-btn.job-header:before,
.line-btn.job:after,
.modal-header button.close,
.pagination li a.current,
.pagination li a:hover,
.pagination li span.current,
.pagination li span:hover,
.search-form .theme-input-group button,
.social-icon.about a:hover,
.social-icon.email a:hover,
.submit-btn.btn-book,
.submit-btn.btn-crm,
.submit-btn.btn-crs,
.submit-btn.btn-pay,
.submit-btn.e-btn,
.submit-btn.m-btn,
.theme-input-group.email-banner button:hover,
.theme-input-group.style--four button,
.theme-input-group.style--four button:hover,
.widget.share .social-icon a:hover,
.widget.widget_newsletter.style--two .theme-input-group button,
.widget.widget_search .theme-input-group button {
	background-color: #ff937d;
}

.email_c2-bo {
	border-color: #ff937d !important;
}

.banner.style--two.management .banner-content h1,
.feature-content.management h2,
.feature-one-content h2.management_c1,
.header-btn.management .link-btn:last-child,
.m-btn.border-btn.style--two,
.management_c1 {
	color: #625fd1;
}

.m-btn.border-btn.style--two:hover,
.management_c1-bg,
.single-service.style--two.management > span {
	background-color: #625fd1;
}

.m-btn.border-btn.style--two,
.management_c1-bo {
	border-color: #625fd1 !important;
}

#mobile_menu .mobile-main-menu.management .nav li a:hover,
#mobile_menu .mobile-main-menu.management .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.management .nav li.current-menu-parent > a,
.footer.management .footer-bottom a:hover,
.footer.management .widget .footer_menu li a:hover,
.header .header-main.management .main-menu .nav li a:hover,
.header-btn.management .link-btn:last-child:hover,
.header-btn.management .link-btn:not(:last-child):hover,
.management_c2 {
	color: #ff4d8c;
}

.back-to-top.man,
.m-btn.s-btn,
.management_c2-bg,
.social-icon.management a:hover,
.theme-input-group.management button,
.theme-input-group.management2 button {
	background-color: #ff4d8c;
}

.management_c2-bo {
	border-color: #ff4d8c !important;
}

.analytic_c1,
.banner.analytic .banner-content h1,
.cta-content a.analytic.e-btn:hover,
.cta-content a.btn-pay.analytic:hover,
.footer .footer-top .footer-top-left.pos h2,
.header-btn.pos .link-btn:last-child:hover,
.header-btn.pos .link-btn:not(:last-child),
.single-price .price-body .btn-pay:hover,
.single-price .price-body .e-btn:hover,
.single-price .price-head .price strong,
.swiper-button-next.style--two:after,
.swiper-button-prev.style--two:after,
.theme-input-group.style--two.analytic button:hover {
	color: #00418e;
}

.analytic_c1-bg,
.footer.style--two.analytic,
.single-price.active:after,
.social-icon.analytic a,
.social-icon.pos a {
	background-color: #00418e;
}

.analytic_c1-bo {
	border-color: #00418e !important;
}

#mobile_menu .mobile-main-menu.analytic .nav li a:hover,
#mobile_menu .mobile-main-menu.analytic .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.analytic .nav li.current-menu-parent > a,
.analytic_c2,
.footer.pos .widget .footer_menu li a:hover,
.header .header-main.analytic .main-menu .nav li a:hover,
.header-btn.pos .link-btn:last-child,
.header-btn.pos .link-btn:not(:last-child):hover,
.line-btn.analytic,
.link-btn.analytic:hover,
.list-check.pos li:after,
.single-price.active .price-head .price strong,
.widget.widget_footer_menu .footer_menu.analytic li a:hover {
	color: #ff5b83;
}

.analytic_c2-bg,
.back-to-top.analytic,
.btn-pay.pos.border-btn:hover,
.cta-content a.analytic.e-btn:hover,
.cta-content a.btn-pay.analytic:hover,
.line-btn.analytic:after,
.line-btn.analytic:before,
.pos.border-btn.e-btn:hover,
.single-price .price-body .btn-pay:hover,
.single-price .price-body .e-btn:hover,
.social-icon.analytic a:hover,
.social-icon.pos a:hover,
.theme-input-group.style--two.analytic button,
.theme-input-group.style--two.analytic button:hover,
.toggle {
	background-color: #ff5b83;
}

.analytic_c2-bo,
.btn-pay.pos.border-btn,
.pos.border-btn.e-btn {
	border-color: #ff5b83 !important;
}

.email-testimonial-slider.host .testimonial-item h3,
.host_c1,
.list-check.host li:after {
	color: #3e4491;
}

.host_c1-bg {
	background-color: #3e4491;
}

.host_c1-bo {
	border-color: #3e4491 !important;
}

#mobile_menu .mobile-main-menu.host .nav li a:hover,
#mobile_menu .mobile-main-menu.host .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.host .nav li.current-menu-parent > a,
.header .header-main.host .main-menu .nav li a:hover,
.host_c2,
.pkg .pkg-list .right div,
.widget.widget_footer_menu .footer_menu.host li a:hover {
	color: #f7a400;
}

.back-to-top.host,
.btn-crm.border-btn.style--three:hover,
.host_c2-bg,
.list-check.host li:after,
.pkg.active .pkg-list .right .btn-pay.host,
.pkg.active .pkg-list .right .btn-pay.host:hover,
.pkg.active .pkg-list .right .host.e-btn,
.pkg.active .pkg-list .right .host.e-btn:hover,
.pkg:hover .pkg-list .right .btn-pay.host,
.pkg:hover .pkg-list .right .btn-pay.host:hover,
.pkg:hover .pkg-list .right .host.e-btn,
.pkg:hover .pkg-list .right .host.e-btn:hover,
.pricing-toogle.host .switch,
.social-icon.host a:hover,
.theme-input-group.style--four.host button,
.theme-input-group.style--four.host button:hover {
	background-color: #f7a400;
}

.host_c2-bo {
	border-color: #f7a400 !important;
}

.host_c3,
.pkg.active .pkg-list .right .btn-pay.host:hover,
.pkg.active .pkg-list .right .host.e-btn:hover,
.pkg:hover .pkg-list .right .btn-pay.host:hover,
.pkg:hover .pkg-list .right .host.e-btn:hover,
.theme-input-group.style--four.host button:hover {
	color: #180d5b;
}

.host_c3-bg,
.pkg .pkg-list > span,
.pkg.active .pkg-list,
.pricing-toogle.host .toggle {
	background-color: #180d5b;
}

.host_c3-bo {
	border-color: #180d5b !important;
}

#mobile_menu .mobile-main-menu.app .nav li a:hover,
#mobile_menu .mobile-main-menu.app .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.app .nav li.current-menu-parent > a,
.app_c1,
.header .header-main.app .main-menu .nav li a:hover {
	color: #8ec9f8;
}

.app_c1-bg,
.theme-input-group.app-banner button {
	background-color: #8ec9f8;
}

.app_c1-bo {
	border-color: #8ec9f8 !important;
}

.app-btn.btn-book,
.app-btn.btn-crm,
.app-btn.btn-crs,
.app-btn.btn-pay,
.app-btn.e-btn,
.app-btn.m-btn,
.app_c2,
.btn.app-btn,
.line-btn.app {
	color: #fb7d4e;
}

.app-btn.btn-book:hover,
.app-btn.btn-crm:hover,
.app-btn.btn-crs:hover,
.app-btn.btn-pay:hover,
.app-btn.e-btn:hover,
.app-btn.m-btn:hover,
.app_c2-bg,
.back-to-top.app,
.btn.app-btn:hover,
.line-btn.app:after,
.line-btn.app:before,
.theme-input-group.app-banner button:hover {
	background-color: #fb7d4e;
}

.app_c2-bo {
	border-color: #fb7d4e !important;
}

.app2_c1 {
	color: #5cb6fe;
}

.app2_c1-bg {
	background-color: #5cb6fe;
}

.app2_c1-bo {
	border-color: #5cb6fe !important;
}

#mobile_menu .mobile-main-menu.app2 .nav li a:hover,
#mobile_menu .mobile-main-menu.app2 .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.app2 .nav li.current-menu-parent > a,
.app2_c2,
.footer.app2 .footer-bottom a:hover,
.footer.app2 .widget .footer_menu li a:hover,
.header .header-main.app2 .main-menu .nav li a:hover {
	color: #ff6773;
}

.app2_c2-bg,
.back-to-top.app2,
.btn.white-btn:hover,
.social-icon.app2 a:hover,
.white-btn.btn-book:hover,
.white-btn.btn-crm:hover,
.white-btn.btn-crs:hover,
.white-btn.btn-pay:hover,
.white-btn.e-btn:hover,
.white-btn.m-btn:hover {
	background-color: #ff6773;
}

.app2_c2-bo {
	border-color: #ff6773 !important;
}

.banner.proto .banner-content h1,
.btn.download.s-btn,
.btn.download:hover,
.download.btn-book:hover,
.download.btn-crm:hover,
.download.btn-crs:hover,
.download.btn-pay:hover,
.download.e-btn:hover,
.download.m-btn:hover,
.download.s-btn.btn-book,
.download.s-btn.btn-crm,
.download.s-btn.btn-crs,
.download.s-btn.btn-pay,
.download.s-btn.e-btn,
.download.s-btn.m-btn,
.feature-content.proto h2,
.proto_c1,
.tab-btn.proto li.active,
.trapezium .feature-content h3,
.video-play-button.proto:hover i {
	color: #5a50a5;
}

.btn.download,
.btn.download.s-btn:hover:after,
.btn.proto,
.download.btn-book,
.download.btn-crm,
.download.btn-crs,
.download.btn-pay,
.download.e-btn,
.download.m-btn,
.download.s-btn.btn-book:hover:after,
.download.s-btn.btn-crm:hover:after,
.download.s-btn.btn-crs:hover:after,
.download.s-btn.btn-pay:hover:after,
.download.s-btn.e-btn:hover:after,
.download.s-btn.m-btn:hover:after,
.footer.proto,
.proto.btn-book,
.proto.btn-crm,
.proto.btn-crs,
.proto.btn-pay,
.proto.e-btn,
.proto.m-btn,
.proto_c1-bg,
.theme-input-group.proto button:hover {
	background-color: #5a50a5;
}

.proto_c1-bo {
	border-color: #5a50a5 !important;
}

#mobile_menu .mobile-main-menu.proto .nav li a:hover,
#mobile_menu .mobile-main-menu.proto .nav li.current-menu-item > a,
#mobile_menu .mobile-main-menu.proto .nav li.current-menu-parent > a,
.footer.proto .widget .footer_menu li a:hover,
.header .header-main.proto .main-menu .nav li a:hover,
.line-btn.proto,
.proto_c2 {
	color: #ffa68b;
}

.back-to-top.proto,
.btn.proto:hover,
.cta-content.proto:after,
.line-btn.proto:after,
.line-btn.proto:before,
.proto.btn-book:hover,
.proto.btn-crm:hover,
.proto.btn-crs:hover,
.proto.btn-pay:hover,
.proto.e-btn:hover,
.proto.m-btn:hover,
.proto_c2-bg,
.social-icon.proto a:hover,
.tab-btn.proto li:after,
.theme-input-group.proto button,
.video-play-button.proto {
	background-color: #ffa68b;
}

.proto_c2-bo {
	border-color: #ffa68b !important;
}

.banner .banner-img img,
.btn.download .svg path,
.btn.white-btn,
.chaptar .chaptar-box,
.custom-select .select-selected,
.download-btn2 .content h5,
.download-btn2 .content p,
.download-btn2 .icon .svg path,
.download.btn-book .svg path,
.download.btn-crm .svg path,
.download.btn-crs .svg path,
.download.btn-pay .svg path,
.download.e-btn .svg path,
.download.m-btn .svg path,
.header,
.header .header-main .main-menu .nav li ul li ul,
.owl-carousel.app-testimonial-slider .owl-nav button .svg g,
.owl-carousel.app-testimonial-slider .owl-nav button .svg g path,
.owl-carousel.email-testimonial-slider .owl-nav button .svg g,
.owl-carousel.email-testimonial-slider .owl-nav button .svg g path,
.single-price.app .price-footer .white-btn,
.social-icon.proto a i,
.switch,
.theme-input-group button,
.theme-input-group button.submit-btn,
.theme-input-group.style--four button,
.theme-input-group.style--two button,
.trans2,
.video-play-button:hover i,
.white-btn.btn-book,
.white-btn.btn-crm,
.white-btn.btn-crs,
.white-btn.btn-pay,
.white-btn.e-btn,
.white-btn.m-btn {
	-webkit-transition: 0.2s linear;
	-o-transition: 0.2s linear;
	transition: 0.2s linear;
}

#menu-button span,
#menu-button span:after,
#menu-button span:before,
#mobile_menu,
.animate-btn.btn-book:after,
.animate-btn.btn-crm:after,
.animate-btn.btn-crs:after,
.animate-btn.btn-pay:after,
.animate-btn.e-btn:after,
.animate-btn.m-btn:after,
.app2.btn-book:after,
.app2.btn-crm:after,
.app2.btn-crs:after,
.app2.btn-pay:after,
.app2.e-btn:after,
.app2.m-btn:after,
.arrow-btn .svg,
.btn,
.btn-book,
.btn-crm,
.btn-crm.btn-view-more .svg line,
.btn-crm.btn-view-more .svg path,
.btn-crs,
.btn-crs.btn-view-more .svg line,
.btn-crs.btn-view-more .svg path,
.btn-pay,
.btn-pay.btn-view-more .svg line,
.btn-pay.btn-view-more .svg path,
.btn-view-more.e-btn .svg line,
.btn-view-more.e-btn .svg path,
.btn.animate-btn:after,
.btn.app2:after,
.btn.download:after,
.comment-content select,
.crm-testimonial.owl-carousel > .owl-nav button .svg g path,
.dot-line,
.dot-line.style--two:before,
.dot-line:after,
.download.btn-book:after,
.download.btn-crm:after,
.download.btn-crs:after,
.download.btn-pay:after,
.download.e-btn:after,
.download.m-btn:after,
.e-btn,
.e-btn:after,
.entry-content select,
.header .header-main .main-menu .offcanvas-trigger span,
.header .header-main.app2.sticky .btn.white-btn:after,
.header .header-main.app2.sticky .white-btn.btn-book:after,
.header .header-main.app2.sticky .white-btn.btn-crm:after,
.header .header-main.app2.sticky .white-btn.btn-crs:after,
.header .header-main.app2.sticky .white-btn.btn-pay:after,
.header .header-main.app2.sticky .white-btn.e-btn:after,
.header .header-main.app2.sticky .white-btn.m-btn:after,
.host-wc-right .single-service .service-icon .svg,
.host-wc-right .single-service .service-icon img,
.list-proses li,
.m-btn,
.modal-header button.close,
.offcanvas-overlay,
.owl-carousel .owl-nav button,
.owl-carousel button.owl-dot,
.owl-carousel.book-review-slider .owl-nav button .svg g path,
.owl-carousel.customer-review-slider .owl-nav button .svg g path,
.owl-carousel.logo-carousel .owl-item img,
.page--content select,
.pkg .pkg-list,
.pkg .pkg-list .right div,
.pkg .pkg-list .right div strong,
.post-navigation .nav-link .nav-title,
.single-job,
.single-service,
.single-service.style--six .service-icon .svg,
.single-service.style--two .service-content P,
.single-service.style--two .service-content h3,
.single-service.style--two .service-icon,
.single-service.style--two .service-icon .svg path,
.social-icon.email a i,
.social-icon.management a i,
.social-icon.pay a i,
.social-icon.plug a i,
.tab-btn.proto li:after,
.theme-input-group.app2 button:after,
.theme-input-group.crs button .svg g g,
.theme-input-group.crs button .svg g path,
.theme-input-group.email-banner button,
.theme-input-style,
.trans3,
.trapezium:before,
.widget.widget_service .service-list li i,
.widget.widget_service .service-list li img,
.widget.widget_service .service-list li svg,
a {
	-webkit-transition: 0.3s linear;
	-o-transition: 0.3s linear;
	transition: 0.3s linear;
}

.header .header-main .logo,
.single-service.style--three.pay .service-icon .svg g path,
.trans4 {
	-webkit-transition: 0.4s linear;
	-o-transition: 0.4s linear;
	transition: 0.4s linear;
}

.header .header-main.email .main-menu .nav li.has-sub-item:after,
.pkg .pkg-list .left h3,
.pkg .pkg-list .left p,
.single-service.style--three.pay .service-icon,
.theme-input-group.crs .input-wrap .highlight,
.trans5 {
	-webkit-transition: 0.5s linear;
	-o-transition: 0.5s linear;
	transition: 0.5s linear;
}

.accordion > .card .accordion-title .accordion-title-meta span,
.arrow-btn,
.btn,
.btn-book,
.btn-crm,
.btn-crs,
.btn-pay,
.comment-respond .comment-form label,
.course-info h3,
.cta-content.book .paperback .order-box .price .stroke-text del,
.cta-content.book .paperback .order-box .price strong,
.customer span,
.e-btn,
.email-testimonial-slider .testimonial-item P,
.fake-btn,
.feature-box:before,
.form-group .col-form-label,
.header-btn .link-btn,
.hind,
.labelText,
.lecture-container .details .preview-text,
.line-btn,
.link-btn.analytic,
.list-check.host li,
.m-btn,
.pagination li a,
.pagination li span,
.pkg .pkg-list .right div strong,
.post-details .entry-categories ul li:first-child,
.post-details .entry-tags ul li:first-child,
.price-box .price-head span del,
.price-box .price-head span strong,
.signup-form .login-text,
.single-data strong,
.single-price .price-head .price strong,
.single-price.app .price-footer span,
.single-price.app2 .price-head span,
.tab-btn.proto li,
.ui-range-slider .sale strong,
.ui-range-slider .total-earning strong,
.widget .col-form-label,
.widget.share .social-icon span,
.widget.widget_calendar table caption,
.widget.widget_calendar table th,
.widget.widget_service .service-list,
.work-slider-dots .active .dots-count,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Hind, sans-serif;
}

.app-testimonial-slider .single-slide h4 span,
.email-testimonial-slider.app2 .testimonial-item P,
.email-testimonial-slider.app2 .testimonial-item h3 span,
.ubuntu,
body {
	font-family: Ubuntu, sans-serif;
}

.price-box.two *,
.price-box.two h1,
.price-box.two h2,
.price-box.two h3,
.price-box.two h4,
.price-box.two h5,
.price-box.two h6,
.text-white *,
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6 {
	color: #fff;
}

.app2.btn-book,
.app2.btn-crm,
.app2.btn-crs,
.app2.btn-pay,
.app2.e-btn,
.app2.m-btn,
.btn-pay.pos,
.btn.app2,
.btn.white-btn,
.owl-carousel .owl-nav button,
.pos.e-btn,
.radius-5,
.theme-input-group.app2,
.theme-input-group.app2 input,
.theme-input-group.pay.plug button,
.theme-input-group.pay.plug input,
.white-btn.btn-book,
.white-btn.btn-crm,
.white-btn.btn-crs,
.white-btn.btn-pay,
.white-btn.e-btn,
.white-btn.m-btn {
	border-radius: 5px;
}

.btn-pay.host,
.btn-pay.pos.border-btn.two,
.btn.download,
.btn.proto,
.download.btn-book,
.download.btn-crm,
.download.btn-crs,
.download.btn-pay,
.download.e-btn,
.download.m-btn,
.host.e-btn,
.list-proses li,
.pos.border-btn.two.e-btn,
.proto.btn-book,
.proto.btn-crm,
.proto.btn-crs,
.proto.btn-pay,
.proto.e-btn,
.proto.m-btn,
.radius-10,
.single-service .service-icon,
.swiper-container.how-work-slider .swiper-slide.active,
.theme-input-group.proto,
.theme-input-group.proto input {
	border-radius: 10px;
}

.accordion > .card,
.chaptar .chaptar-box,
.countdown-wrapper,
.cta-content.proto:after,
.earn-path-feature .single-service .service-icon,
.feature-single,
.feature-wrap.plug,
.host-wc-right .single-service,
.pkg .pkg-list,
.pkg-info,
.price-box,
.radius-20,
.row.cta-bg:after,
.row.feature-bg:after,
.single-feature.box,
.single-service,
.single-service.proto .service-icon,
.single-service.style--two .service-icon,
.testimonial-single,
.testimonial-single.style--two.plug,
.trapezium .feature-icon,
.trapezium:before {
	border-radius: 20px;
}

.radius-30,
.ui-range-slider {
	border-radius: 30px;
}

.radius-40 {
	border-radius: 40px;
}

.animate-btn.btn-book:after,
.animate-btn.btn-crm:after,
.animate-btn.btn-crs:after,
.animate-btn.btn-pay:after,
.animate-btn.e-btn:after,
.animate-btn.m-btn:after,
.btn,
.btn-book,
.btn-crm,
.btn-crs,
.btn-pay,
.btn.animate-btn:after,
.countdown-wrapper .countdown-tag,
.e-btn,
.m-btn,
.radius-50,
.theme-input-group input {
	border-radius: 50px;
}

.earn-path-feature,
.feature-wrapper:after,
.radius-60 {
	border-radius: 60px;
}

.cart-btn span,
.dot-line.style--two:before,
.dot-line:after,
.list-check.host li:after,
.modal-header .modal-title .modal-title-icon,
.modal-header button.close,
.owl-carousel.book-review-slider .owl-nav button,
.owl-carousel.customer-review-slider .owl-nav button,
.pkg .pkg-list > span,
.radius,
.single-service.style--three.pay .service-icon,
.single-service.style--two > span,
.social-icon a,
.testimonial-single .tst-content .ts-img img,
.video-play-button {
	border-radius: 50%;
}

#mobile_menu,
.accordion > .card,
.box-shadow,
.btn.white-btn,
.contact-form,
.countdown-wrapper,
.custom-select .select-items,
.download-btn2,
.feature-img.l-app img,
.feature-single,
.feature-wrap,
.header .header-main .main-menu .nav li ul,
.header .header-main.sticky,
.member,
.modal-dialog,
.pkg .pkg-list,
.pkg-info,
.price-box,
.signin-form-wrap,
.signup-form-wrap,
.single-job:hover,
.single-price.app,
.single-service.host:hover,
.single-service.style--six,
.single-service.style--two,
.social-icon.app2 a,
.swiper-container.how-work-slider .swiper-slide.active,
.testimonial-single.style--three,
.testimonial-single.style--two.plug,
.testimonial-slider.student-review
	.owl-stage-outer
	.testimonial-single.style--two,
.theme-input-group.app-banner,
.theme-input-group.app2,
.theme-input-group.proto,
.toggle,
.white-btn.btn-book,
.white-btn.btn-crm,
.white-btn.btn-crs,
.white-btn.btn-pay,
.white-btn.e-btn,
.white-btn.m-btn {
	-webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
}

.no-shadow {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.list-check li {
	font-size: 20px;
	padding-left: 35px;
	position: relative;
	font-weight: 500;
}

.list-check li:not(:last-child) {
	margin-bottom: 20px;
}

.list-check li:after {
	position: absolute;
	font-family: FontAwesome;
	content: '';
	line-height: inherit;
	left: 0;
	top: -2px;
}

.list-check.style--two {
	margin-top: 40px;
	margin-bottom: 44px;
}

.list-check.style--two li {
	padding-left: 50px;
	font-weight: 500;
}

.list-check.style--two li h3 {
	font-size: 26px;
	color: #292929;
	font-weight: 500;
	margin-bottom: 12px;
}

.list-check.style--two li:not(:last-child) {
	margin-bottom: 30px;
}

.list-check.style--two li:after {
	left: 0;
	top: 0;
}

.list-check.style--two li:nth-child(2):after {
}

.list-check.style--two.pay {
	margin-top: 40px;
	margin-bottom: 0;
}

.list-check.style--two.pay li {
	padding-left: 50px;
	font-weight: 500;
}

.list-check.style--two.pay li h3 {
	font-size: 26px;
	color: #292929;
	font-weight: 500;
	margin-bottom: 12px;
}

.list-check.style--two.pay li:not(:last-child) {
	margin-bottom: 30px;
}

.list-check.style--two.pay li:after {
	left: 0;
	top: 0;
}

.list-check.style--two.pay li:nth-child(2):after {
}

.list-check.style--two.pay li:nth-child(3):after {
}

.list-check.style--two.pay2 li:after {
	left: 0;
	top: 0;
}

.list-check.style--two.pay2 li:nth-child(2):after {
}

.list-check.style--two.pay2 li:nth-child(3):after {
}

.list-check.style--three {
	margin-bottom: 44px;
}

.list-check.style--three li {
	font-size: 16px;
	font-weight: 400;
	color: #292929;
	padding-left: 30px;
}

.list-check.style--three li:not(:last-child) {
	margin-bottom: 5px;
}

.list-check.style--three li:after {
	top: -1px;
}

.list-check.style--three.price {
	margin-bottom: 32px;
}

.list-check.author-meta li {
	font-size: 16px;
	font-weight: 300;
}

.list-check.author-meta li:not(:last-child) {
	margin-bottom: 5px;
}

.list-check.author-meta li:after {
	top: 4px;
}

.list-check.author-meta li:nth-child(2):after {
}

.list-check.author-meta li:nth-child(3):after {
}

.list-check.cards li:not(:last-child),
.list-check.pos li:not(:last-child) {
	margin-bottom: 30px;
}

.list-check.cards li h3,
.list-check.pos li h3 {
	color: #292929;
	font-weight: 500;
	margin-bottom: 10px;
}

.list-check.cards li:after,
.list-check.pos li:after {
	top: -8px;
	font-size: 22px;
}

.list-check.email-check {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.list-check.email-check li {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	margin-bottom: 14px;
	text-transform: capitalize;
	font-size: 20px;
	font-weight: 300;
}

@media only screen and (max-width: 575px) {
	.list-check.email-check li {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
}

.list-check.host li {
	margin-bottom: 18px;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.3;
	padding-left: 30px;
}

@media only screen and (max-width: 575px) {
	.list-check.host li {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
}

.list-check.host li:after {
	content: '';
	width: 10px;
	height: 10px;
	top: 4px;
}

.list-check.pos li {
	padding-left: 40px;
}

.list-check.job li {
	font-weight: 300;
}

.list-check.job li:not(:last-child) {
	margin-bottom: 15px;
}

.list-check.job li:after {
	content: '\f10c';
	font-size: 12px;
	top: 8px;
}

.list-uncheck li:not(:last-child) {
	margin-bottom: 35px;
}

.list-uncheck li h3 {
	font-size: 22px;
	font-weight: 500;
	color: #292929;
	margin-bottom: 8px;
}

.list-uncheck.host li:not(:last-child) {
	margin-bottom: 25px;
}

.list-uncheck.host li h3 {
	font-size: 24px;
}

.list-uncheck.host li p {
	font-size: 18px;
	max-width: 500px;
}

.list-inline li {
	display: inline-block;
}

.list-proses {
	padding-left: 50px;
}

@media only screen and (max-width: 1199px) {
	.list-proses {
		padding-left: 0;
	}
}

.list-proses li {
	padding: 40px;
	padding-bottom: 35px;
}

@media only screen and (max-width: 575px) {
	.list-proses li {
		padding: 10px;
	}
}

.list-proses li:hover {
	background: #f1faff;
}

.list-proses li:not(:last-child) {
	margin-bottom: 10px;
}

.list-proses li .img {
	margin-right: 30px;
}

.list-proses li .content h3 {
	color: #292929;
	font-size: 30px;
	font-weight: 500;
	margin-bottom: 10px;
}

.list-proses li .content p {
	font-weight: 400;
}

.list-proses li:nth-child(2):hover {
	background: #e9fffa;
}

.list-proses li:nth-child(3):hover {
	background: #fff4f3;
}

.list-how-works li:not(:last-child) {
	margin-bottom: 60px;
}

.list-how-works li .img {
	margin-right: 50px;
}

@media only screen and (max-width: 1199px) {
	.list-how-works li .img {
		margin-right: 40px;
	}
}

@media only screen and (max-width: 479px) {
	.list-how-works li .img {
		margin: 0 auto 30px;
	}
}

@media only screen and (max-width: 479px) {
	.list-how-works li {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
}

.list-how-works li .content h3 {
	color: #292929;
	font-size: 30px;
	font-weight: 600;
	margin-bottom: 18px;
}

.list-how-works li .content p {
	font-weight: 400;
}

.social-icon a {
	width: 40px;
	height: 40px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #292929;
	background: #f9f9f9;
}

.social-icon a:not(:last-child) {
	margin-right: 10px;
}

.social-icon a:hover {
	color: #fff;
}

.social-icon.style--two a {
	color: #fff;
}

.social-icon.analytic a {
	color: #fff;
}

.social-icon.analytic a:hover {
	color: #fff;
}

.social-icon.pos a {
	color: #fff;
}

.social-icon.pos a:hover {
	color: #fff;
}

.social-icon.course a:hover {
	color: #fff;
}

.social-icon.pay a {
	background: #fff;
}

.social-icon.pay a:hover i {
	color: #fff;
}

.social-icon.plug a {
	background: #fff;
}

.social-icon.plug a i {
	color: #292929;
}

.social-icon.plug a:hover i {
	color: #4e2fa9;
}

.social-icon.email a {
	background: #fff;
}

.social-icon.email a i {
	color: #857dff;
}

.social-icon.email a:hover i {
	color: #fff;
}

.social-icon.management a {
	background: #fff;
}

.social-icon.management a i {
	color: #625fd1;
}

.social-icon.management a:hover i {
	color: #fff;
}

.social-icon.host a {
	background: #fff;
}

.social-icon.host a i {
	color: #180d5b;
}

.social-icon.host a:hover i {
	color: #fff;
}

.social-icon.app a {
	background: #fff;
}

.social-icon.app a i {
	color: #2154fc;
}

.social-icon.app a:hover {
	background-color: #2154fc;
}

.social-icon.app a:hover i {
	color: #fff;
	-webkit-animation: fadeInUp10 0.3s linear 0.1s 1 backwards;
	animation: fadeInUp10 0.3s linear 0.1s 1 backwards;
}

.social-icon.app a:nth-child(2) i {
	color: #3db1ff;
}

.social-icon.app a:nth-child(2):hover {
	background-color: #3db1ff;
}

.social-icon.app a:nth-child(2):hover i {
	color: #fff;
}

.social-icon.app a:nth-child(3) i {
	color: #ff65a8;
}

.social-icon.app a:nth-child(3):hover {
	background-color: #ff65a8;
}

.social-icon.app a:nth-child(3):hover i {
	color: #fff;
}

.social-icon.app a:nth-child(4) i {
	color: #238bde;
}

.social-icon.app a:nth-child(4):hover {
	background-color: #238bde;
}

.social-icon.app a:nth-child(4):hover i {
	color: #fff;
}

.social-icon.app2 a {
	background: #fff;
}

.social-icon.app2 a i {
	color: #292929;
}

.social-icon.app2 a:hover i {
	color: #fff;
}

.social-icon.proto a {
	background: #fff;
}

.social-icon.proto a i {
	color: #5a50a5;
}

.social-icon.proto a:hover i {
	color: #fff;
}

.social-icon.about span {
	display: block;
	margin-bottom: 10px;
}

.social-icon.about a {
	color: #fff;
}

.social-icon.about a:hover {
	color: #fff;
}

.social-icon.style--three a {
	background-color: #fff6f1;
	color: #292929;
}

.social-icon.style--three a:hover {
	background-color: #ffe8dc;
	color: #292929;
}

@media only screen and (max-width: 991px) {
	.footer-social-icons {
		margin-bottom: 15px;
	}
}

.footer-social-icons span {
	display: inline-block;
	margin-right: 5px;
	font-size: 18px;
}

.footer-social-icons a {
	display: inline-block;
	padding: 0 10px;
	font-size: 16px;
}

.footer-social-icons a:hover i {
	color: #fbc006;
}

.owl-carousel .owl-dots {
	position: absolute;
	right: 50px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 30px;
}

.owl-carousel button.owl-dot {
	display: inline-block;
	height: 3px;
	width: 16px;
}

.owl-carousel button.owl-dot.active {
	width: 28px;
}

.owl-carousel .owl-nav button {
	position: absolute;
	right: 0;
	top: -80px;
	width: 50px;
	height: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #c9fff5 !important;
}

@media only screen and (max-width: 991px) {
	.owl-carousel .owl-nav button {
		top: -70px;
	}
}

.owl-carousel .owl-nav button i {
	color: #5dd9c1;
	font-size: 30px;
}

.owl-carousel .owl-nav button:hover {
	background: #5dd9c1 !important;
}

.owl-carousel .owl-nav button:hover i {
	color: #fff;
}

.owl-carousel .owl-nav button.owl-prev {
	right: 66px;
}

@media only screen and (max-width: 991px) {
	.owl-carousel .owl-nav {
		display: none;
	}
}

.owl-carousel.logo-carousel .owl-nav button {
	position: absolute;
	left: auto;
	right: -50px;
	top: calc(50% - 25px);
	width: 50px;
	height: 50px;
	background: #fff !important;
}

.owl-carousel.logo-carousel .owl-nav button i {
	color: #707070;
	font-size: 30px;
}

.owl-carousel.logo-carousel .owl-nav button:hover {
	background: #fff !important;
}

.owl-carousel.logo-carousel .owl-nav button:hover i {
	color: #fe957b;
}

.owl-carousel.logo-carousel .owl-nav button.owl-prev {
	right: auto;
	left: -50px;
}

@media only screen and (max-width: 767px) {
	.owl-carousel.logo-carousel .owl-nav {
		display: none;
	}
}

.owl-carousel.logo-carousel.analytic .owl-nav button:hover i {
	color: #ff5b83;
}

.owl-carousel.book-review-slider .owl-nav button,
.owl-carousel.customer-review-slider .owl-nav button {
	position: absolute;
	left: auto;
	right: -90px;
	top: calc(50% - 25px);
	width: 50px;
	height: 50px;
	background: #fff !important;
}

@media only screen and (max-width: 991px) {
	.owl-carousel.book-review-slider .owl-nav button,
	.owl-carousel.customer-review-slider .owl-nav button {
		display: none;
	}
}

.owl-carousel.book-review-slider .owl-nav button i,
.owl-carousel.customer-review-slider .owl-nav button i {
	display: none;
}

.owl-carousel.book-review-slider .owl-nav button.owl-prev,
.owl-carousel.customer-review-slider .owl-nav button.owl-prev {
	right: auto;
	left: -90px;
}

.owl-carousel.book-review-slider .owl-nav button .svg,
.owl-carousel.customer-review-slider .owl-nav button .svg {
	width: 22px;
}

.owl-carousel.book-review-slider .owl-nav button .svg g path,
.owl-carousel.customer-review-slider .owl-nav button .svg g path {
	stroke: #292929;
}

.owl-carousel.book-review-slider .owl-nav button:hover,
.owl-carousel.customer-review-slider .owl-nav button:hover {
	background: #205ce2 !important;
}

.owl-carousel.book-review-slider .owl-nav button:hover .svg g path,
.owl-carousel.customer-review-slider .owl-nav button:hover .svg g path {
	stroke: #fff;
}

@media only screen and (max-width: 767px) {
	.owl-carousel.book-review-slider .owl-nav,
	.owl-carousel.customer-review-slider .owl-nav {
		display: none;
	}
}

.owl-carousel.customer-review-slider .owl-nav button:hover {
	background: #4e2fa9 !important;
}

.owl-carousel.student-review .owl-nav button {
	top: -110px;
}

@media only screen and (max-width: 991px) {
	.owl-carousel.student-review .owl-nav button {
		top: -86px;
	}
}

.owl-carousel.app-testimonial-slider .owl-nav button,
.owl-carousel.email-testimonial-slider .owl-nav button {
	top: auto;
	bottom: -50px;
	left: 0;
	background: 0 0 !important;
	padding: 0 !important;
	width: auto;
	height: auto;
}

.owl-carousel.app-testimonial-slider .owl-nav button.owl-next,
.owl-carousel.email-testimonial-slider .owl-nav button.owl-next {
	left: 53px;
}

.owl-carousel.app-testimonial-slider .owl-nav button .svg g,
.owl-carousel.email-testimonial-slider .owl-nav button .svg g {
	fill: #707070;
}

.owl-carousel.app-testimonial-slider .owl-nav button .svg g path,
.owl-carousel.email-testimonial-slider .owl-nav button .svg g path {
	stroke: #707070;
}

.owl-carousel.app-testimonial-slider .owl-nav button:hover .svg g,
.owl-carousel.email-testimonial-slider .owl-nav button:hover .svg g {
	fill: #ff937d;
}

.owl-carousel.app-testimonial-slider .owl-nav button:hover .svg g path,
.owl-carousel.email-testimonial-slider .owl-nav button:hover .svg g path {
	stroke: #ff937d;
}

.owl-carousel.app-testimonial-slider.host .owl-nav button:hover .svg g,
.owl-carousel.email-testimonial-slider.host .owl-nav button:hover .svg g {
	fill: #f7a400;
}

.owl-carousel.app-testimonial-slider.host .owl-nav button:hover .svg g path,
.owl-carousel.email-testimonial-slider.host .owl-nav button:hover .svg g path {
	stroke: #f7a400;
}

.owl-carousel.app-testimonial-slider.app2 .owl-nav button:hover .svg g,
.owl-carousel.email-testimonial-slider.app2 .owl-nav button:hover .svg g {
	fill: #ff6773;
}

.owl-carousel.app-testimonial-slider.app2 .owl-nav button:hover .svg g path,
.owl-carousel.email-testimonial-slider.app2 .owl-nav button:hover .svg g path {
	stroke: #ff6773;
}

.owl-carousel.pos-feature-slider .owl-nav button {
	top: -140px;
	background: 0 0 !important;
}

@media only screen and (max-width: 991px) {
	.owl-carousel.pos-feature-slider .owl-nav button {
		top: -70px;
	}
}

.owl-carousel.pos-feature-slider .owl-nav button i {
	color: #707070;
	font-size: 30px;
}

.owl-carousel.pos-feature-slider .owl-nav button:hover {
	background: 0 0 !important;
}

.owl-carousel.pos-feature-slider .owl-nav button:hover i {
	color: #00418e;
}

.owl-carousel.pos-feature-slider .owl-nav button.owl-prev {
	right: 66px;
}

@media only screen and (max-width: 767px) {
	.owl-carousel.pos-feature-slider .owl-nav {
		display: none;
	}
}

.owl-carousel.app-testimonial-slider .owl-nav button {
	top: auto;
	bottom: 190px;
	left: -30px;
}

@media only screen and (max-width: 991px) {
	.owl-carousel.app-testimonial-slider .owl-nav button {
		bottom: 190px;
	}
}

.owl-carousel.app-testimonial-slider .owl-nav button i {
	display: none;
}

.owl-carousel.app-testimonial-slider .owl-nav button .svg {
	width: 26px;
}

.owl-carousel.app-testimonial-slider .owl-nav button:hover .svg g path {
	stroke: #8ec9f8;
}

.owl-carousel.app-testimonial-slider .owl-nav button.owl-next {
	left: auto;
	right: -30px;
}

@media only screen and (max-width: 767px) {
	.owl-carousel.app-testimonial-slider .owl-nav {
		display: none;
	}
}

.comment-content select,
.entry-content select,
.page--content select,
.theme-input-style {
	border: 1px solid #eee;
	height: 52px;
	width: 100%;
	padding: 0 20px;
	background-color: #fff;
	font-size: 16px;
	margin-bottom: 20px;
}

.comment-content select:focus,
.entry-content select:focus,
.page--content select:focus,
.theme-input-style:focus {
	background-color: #f8f8ff;
}

textarea.theme-input-style {
	height: 200px;
	padding: 20px 20px;
}

.theme-input-group {
	position: relative;
	width: 100%;
}

.theme-input-group input {
	width: 100%;
	height: 60px;
	border: none;
	padding-left: 25px;
	padding-right: 150px;
	color: #fff;
	margin: 0;
}

@media only screen and (max-width: 479px) {
	.theme-input-group input {
		padding-right: 130px;
		padding-left: 10px;
	}
}

.theme-input-group button,
.theme-input-group button.submit-btn {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	padding: 15px 30px;
	line-height: 1;
	font-weight: 600;
	color: #fff;
}

@media only screen and (max-width: 479px) {
	.theme-input-group button,
	.theme-input-group button.submit-btn {
		font-size: 16px;
		padding: 20px;
	}
}

.theme-input-group.style--two {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 12px;
}

.theme-input-group.style--two input {
	width: calc(100% - 170px);
	height: 50px;
	border: none;
	border-bottom: 1px solid #57548d;
	padding-left: 0;
	padding-right: 0;
	background: 0 0;
	border-radius: 0;
	font-size: 18px;
	font-weight: 300;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.style--two input {
		width: calc(100% - 100px);
	}
}

.theme-input-group.style--two button {
	position: static;
	padding: 15px 30px;
	margin-left: 30px;
	line-height: 1;
	border-radius: 6px;
	font-weight: 600;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.style--two button {
		margin-left: 5px;
		padding: 15px;
	}
}

.theme-input-group.style--two.analytic input {
	border-bottom: 1px solid #fff;
	color: #fff;
	border-radius: 0;
}

.theme-input-group.style--two.analytic button {
	border-radius: 0;
	color: #fff;
}

.theme-input-group.email-banner {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-bottom: 12px;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.email-banner {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.theme-input-group.email-banner input {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	height: 58px;
	border: 1px solid #bfbfbf;
	background: 0 0;
	padding-right: 25px;
	border-radius: 0;
	font-size: 16px;
	font-weight: 400;
}

@media only screen and (max-width: 575px) {
	.theme-input-group.email-banner input {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media only screen and (max-width: 479px) {
	.theme-input-group.email-banner input {
		margin-bottom: 10px;
		padding: 20px 10px;
	}
}

.theme-input-group.email-banner button {
	position: static;
	padding: 20px 35px;
	margin-left: 20px;
	line-height: 1;
	border-radius: 0;
	font-weight: 600;
	color: #fff;
}

.theme-input-group.email-banner button:hover {
	color: #fff;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.email-banner button {
		margin: 0;
	}
}

.theme-input-group.app-banner {
	border-radius: 50px;
}

.theme-input-group.app-banner input {
	height: 58px;
	background: #fff;
	padding-right: 180px;
	font-size: 16px;
	font-weight: 400;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.app-banner input {
		padding-left: 10px;
		padding-right: 120px;
	}
}

.theme-input-group.app-banner button {
	padding: 20px 35px;
	color: #fff;
	border-radius: 0 50px 50px 0;
}

.theme-input-group.app-banner button:hover {
	color: #fff;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.app-banner button {
		padding: 20px 14px;
	}
}

.theme-input-group.app-banner.cta {
	max-width: 810px;
	margin: 0 auto;
}

.theme-input-group.app2 input {
	height: 58px;
	background: #fff;
	padding-right: 180px;
	font-size: 16px;
	font-weight: 400;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.app2 input {
		padding-left: 10px;
		padding-right: 120px;
	}
}

.theme-input-group.app2 button {
	padding: 20px 35px;
	color: #fff;
	border-radius: 0 5px 5px 0;
	z-index: 1;
	overflow: hidden;
	background: #ff6773;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#ff6773),
		to(#ff896b)
	);
	background: -o-linear-gradient(top, #ff6773 0, #ff896b 100%);
	background: linear-gradient(to bottom, #ff6773 0, #ff896b 100%);
}

.theme-input-group.app2 button:after {
	left: 0;
	top: -100%;
	width: 100%;
	height: 100%;
	content: '';
	position: absolute;
	border-radius: 0 5px 5px 0;
	z-index: -1;
	background: #ff896b;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#ff896b),
		to(#ff6773)
	);
	background: -o-linear-gradient(top, #ff896b 0, #ff6773 100%);
	background: linear-gradient(to bottom, #ff896b 0, #ff6773 100%);
}

.theme-input-group.app2 button:hover {
	color: #fff;
}

.theme-input-group.app2 button:hover:after {
	top: 0;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.app2 button {
		padding: 20px 14px;
	}
}

@media only screen and (max-width: 991px) {
	.theme-input-group.app2.footer input {
		padding-left: 10px;
		padding-right: 109px;
	}

	.theme-input-group.app2.footer button {
		padding: 15px;
	}
}

.theme-input-group.crs {
	max-width: 690px;
	margin: 0 auto;
	padding: 0 15px;
}

.theme-input-group.crs .input-wrap {
	position: relative;
}

.theme-input-group.crs .input-wrap .highlight {
	position: absolute;
	display: block;
	width: 0%;
	height: 1px;
	left: 0;
	bottom: 0;
	background: #fbc006;
}

.theme-input-group.crs .input-wrap input:focus + .highlight {
	width: 100%;
}

.theme-input-group.crs input {
	padding-left: 0;
	padding-right: 30px;
	background: 0 0;
	border-radius: 0;
	border-bottom: 1px solid #fff;
	font-size: 18px;
}

.theme-input-group.crs input::-webkit-input-placeholder {
	color: #fff !important;
}

.theme-input-group.crs input::-moz-placeholder {
	color: #fff !important;
}

.theme-input-group.crs input:-ms-input-placeholder {
	color: #fff !important;
}

.theme-input-group.crs input::-ms-input-placeholder {
	color: #fff !important;
}

.theme-input-group.crs input::placeholder {
	color: #fff !important;
}

.theme-input-group.crs button {
	position: absolute;
	top: 50%;
	right: 5px;
	height: auto;
	padding: 10px;
	background: 0 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.theme-input-group.crs button:hover {
	background: 0 0;
}

.theme-input-group.crs button:hover .svg g path {
	stroke: #fbc006;
}

.theme-input-group.crs button:hover .svg g g {
	fill: #fbc006;
}

.theme-input-group.course input {
	color: #fff;
}

.theme-input-group.course button:hover {
	color: #292929;
}

.theme-input-group.pay input {
	background: #fff;
	border-radius: 0;
}

.theme-input-group.pay button {
	border-radius: 0;
}

.theme-input-group.pay.plug input {
	color: #292929;
}

.theme-input-group.pay.plug button {
	color: #4e2fa9;
}

.theme-input-group.pay.plug button:hover {
	opacity: 0.9;
}

.theme-input-group.author-newsletter {
	max-width: 630px;
	margin: 0 auto;
}

.theme-input-group.author-newsletter input {
	background: #205ce2;
}

.theme-input-group.author-newsletter input::-webkit-input-placeholder {
	color: #fff !important;
}

.theme-input-group.author-newsletter input::-moz-placeholder {
	color: #fff !important;
}

.theme-input-group.author-newsletter input:-ms-input-placeholder {
	color: #fff !important;
}

.theme-input-group.author-newsletter input::-ms-input-placeholder {
	color: #fff !important;
}

.theme-input-group.author-newsletter input::placeholder {
	color: #fff !important;
}

.theme-input-group.author-newsletter button {
	right: -1px;
}

.theme-input-group.style--four {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 15px;
}

.theme-input-group.style--four input {
	width: calc(100% - 160px);
	height: 50px;
	border: 1px solid #fff;
	padding-left: 25px;
	padding-right: 25px;
	color: #fff;
	background: 0 0;
	border-radius: 0;
	font-size: 18px;
	font-weight: 300;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.style--four input {
		width: calc(100% - 100px);
		padding-left: 10px;
		padding-right: 10px;
	}
}

.theme-input-group.style--four button {
	position: static;
	padding: 16px 30px;
	height: 50px;
	margin-left: 20px;
	line-height: 1;
	border-radius: 6px;
	font-weight: 600;
	border-radius: 0;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.style--four button {
		margin-left: 5px;
		padding: 15px;
	}
}

.theme-input-group.style--four.host input {
	border-radius: 6px;
}

.theme-input-group.style--four.host button {
	border-radius: 6px;
}

.theme-input-group.management {
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;
}

.theme-input-group.management input {
	background: #fff;
	font-size: 20px;
	font-weight: 300;
	padding-right: 170px;
}

@media only screen and (max-width: 1199px) {
	.theme-input-group.management input {
		padding-right: 150px;
	}
}

.theme-input-group.management button {
	font-size: 18px;
	padding: 20px 40px;
}

@media only screen and (max-width: 1199px) {
	.theme-input-group.management button {
		padding: 20px 30px;
	}
}

.theme-input-group.management2 input {
	background: #fff;
	font-size: 20px;
	font-weight: 300;
	padding-right: 170px;
}

@media only screen and (max-width: 1199px) {
	.theme-input-group.management2 input {
		padding-right: 150px;
	}
}

@media only screen and (max-width: 479px) {
	.theme-input-group.management2 input {
		padding-right: 120px;
	}
}

.theme-input-group.management2 button {
	font-size: 18px;
	padding: 20px 30px;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.management2 button {
		padding: 20px;
	}
}

.theme-input-group.pos input {
	border-color: #707070;
}

.theme-input-group.pos button {
	background: #ff5b83;
	color: #fff;
}

.theme-input-group.pos button:hover {
	background: #00418e;
	color: #fff;
}

.theme-input-group.proto input {
	height: 60px;
	background: #fff;
	padding-right: 160px;
	font-size: 16px;
	font-weight: 400;
}

@media only screen and (max-width: 767px) {
	.theme-input-group.proto input {
		height: 50px;
	}
}

@media only screen and (max-width: 479px) {
	.theme-input-group.proto input {
		padding-left: 10px;
		padding-right: 120px;
	}
}

.theme-input-group.proto button {
	padding: 20px 35px;
	color: #fff;
	border-radius: 0 10px 10px 0;
	z-index: 1;
}

.theme-input-group.proto button:hover {
	color: #fff;
}

@media only screen and (max-width: 479px) {
	.theme-input-group.proto button {
		padding: 20px 14px;
	}
}

.comment-content .theme-input-group.affiliate select,
.entry-content .theme-input-group.affiliate select,
.page--content .theme-input-group.affiliate select,
.theme-input-group.affiliate .comment-content select,
.theme-input-group.affiliate .entry-content select,
.theme-input-group.affiliate .page--content select,
.theme-input-group.affiliate .theme-input-style {
	color: #fff;
}

.comment-content .theme-input-group.affiliate select:focus,
.entry-content .theme-input-group.affiliate select:focus,
.page--content .theme-input-group.affiliate select:focus,
.theme-input-group.affiliate .comment-content select:focus,
.theme-input-group.affiliate .entry-content select:focus,
.theme-input-group.affiliate .page--content select:focus,
.theme-input-group.affiliate .theme-input-style:focus {
	background-color: #5f52dc;
}

.ov-hidden {
	overflow: hidden;
}

.ovx-hidden {
	overflow-x: hidden;
}

.ba {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.pagination > ul {
	margin-bottom: 0;
}

.pagination li {
	margin: 0 5px;
}

.pagination li a,
.pagination li span {
	width: 40px;
	height: 40px;
	line-height: 1;
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	border: 1px solid #eee;
	color: #5e5e5e;
	font-size: 22px;
	font-weight: 600;
}

.pagination li a.current,
.pagination li a:hover,
.pagination li span.current,
.pagination li span:hover {
	color: #fff;
}

.pagination li.nav-btn a.disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

blockquote {
	padding: 0 20px 30px 20px;
	position: relative;
	font-size: 18px;
	font-style: italic;
	line-height: 1.33;
	font-weight: 400;
	max-width: 540px;
	margin: 0 auto;
}

blockquote cite {
	display: block;
	margin-top: 15px;
	font-size: 16px;
	line-height: 1.44;
	font-style: normal;
	font-weight: 400;
}

@media (min-width: 1500px) {
	.container {
		max-width: 1410px;
	}
}

.form-response {
	background-color: rgba(95, 82, 220, 0.2);
}

.form-response span {
	display: block;
	margin-top: 20px;
	margin-bottom: 30px;
	padding: 10px 15px;
}

.light-bg {
	background-color: #fffcf6;
}

.bg-img {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.search-form .theme-input-group input,
.widget.widget_newsletter.style--two .theme-input-group input,
.widget.widget_search .theme-input-group input {
	color: #fff;
	border-radius: 0;
	color: #fff;
	padding-right: 100px;
}

.search-form .theme-input-group input::-webkit-input-placeholder,
.widget.widget_newsletter.style--two
	.theme-input-group
	input::-webkit-input-placeholder,
.widget.widget_search .theme-input-group input::-webkit-input-placeholder {
	color: #fff !important;
}

.search-form .theme-input-group input::-moz-placeholder,
.widget.widget_newsletter.style--two .theme-input-group input::-moz-placeholder,
.widget.widget_search .theme-input-group input::-moz-placeholder {
	color: #fff !important;
}

.search-form .theme-input-group input:-ms-input-placeholder,
.widget.widget_newsletter.style--two
	.theme-input-group
	input:-ms-input-placeholder,
.widget.widget_search .theme-input-group input:-ms-input-placeholder {
	color: #fff !important;
}

.search-form .theme-input-group input::-ms-input-placeholder,
.widget.widget_newsletter.style--two
	.theme-input-group
	input::-ms-input-placeholder,
.widget.widget_search .theme-input-group input::-ms-input-placeholder {
	color: #fff !important;
}

.search-form .theme-input-group input::placeholder,
.widget.widget_newsletter.style--two .theme-input-group input::placeholder,
.widget.widget_search .theme-input-group input::placeholder {
	color: #fff !important;
}

.search-form .theme-input-group button:hover,
.widget.widget_newsletter.style--two .theme-input-group button:hover,
.widget.widget_search .theme-input-group button:hover {
	color: #fff;
}

.faq.widget.widget_newsletter.style--two h2,
.faq.widget.widget_search h2,
.search-form.faq h2 {
	color: #292929;
	font-weight: 600;
	margin-bottom: 30px;
	text-align: center;
}

.faq.widget.widget_newsletter.style--two .theme-input-group input,
.faq.widget.widget_search .theme-input-group input,
.search-form.faq .theme-input-group input {
	background-color: #fff;
	color: #707070;
	border: 1px solid #292929;
	width: calc(100% - 150px);
}

.faq.widget.widget_newsletter.style--two
	.theme-input-group
	input::-webkit-input-placeholder,
.faq.widget.widget_search .theme-input-group input::-webkit-input-placeholder,
.search-form.faq .theme-input-group input::-webkit-input-placeholder {
	color: #707070 !important;
}

.faq.widget.widget_newsletter.style--two
	.theme-input-group
	input::-moz-placeholder,
.faq.widget.widget_search .theme-input-group input::-moz-placeholder,
.search-form.faq .theme-input-group input::-moz-placeholder {
	color: #707070 !important;
}

.faq.widget.widget_newsletter.style--two
	.theme-input-group
	input:-ms-input-placeholder,
.faq.widget.widget_search .theme-input-group input:-ms-input-placeholder,
.search-form.faq .theme-input-group input:-ms-input-placeholder {
	color: #707070 !important;
}

.faq.widget.widget_newsletter.style--two
	.theme-input-group
	input::-ms-input-placeholder,
.faq.widget.widget_search .theme-input-group input::-ms-input-placeholder,
.search-form.faq .theme-input-group input::-ms-input-placeholder {
	color: #707070 !important;
}

.faq.widget.widget_newsletter.style--two .theme-input-group input::placeholder,
.faq.widget.widget_search .theme-input-group input::placeholder,
.search-form.faq .theme-input-group input::placeholder {
	color: #707070 !important;
}

@media only screen and (max-width: 479px) {
	.faq.widget.widget_newsletter.style--two .theme-input-group input,
	.faq.widget.widget_search .theme-input-group input,
	.search-form.faq .theme-input-group input {
		width: calc(100% - 100px);
		padding: 0 10px;
	}
}

.faq.widget.widget_newsletter.style--two .theme-input-group button,
.faq.widget.widget_search .theme-input-group button,
.search-form.faq .theme-input-group button {
	padding: 15px 38px;
	font-weight: 500;
}

@media only screen and (max-width: 479px) {
	.faq.widget.widget_newsletter.style--two .theme-input-group button,
	.faq.widget.widget_search .theme-input-group button,
	.search-form.faq .theme-input-group button {
		padding: 15px 25px;
	}
}

.comment-content form.newsletter-form .theme-input-group select,
.comment-content form.search-form .theme-input-group select,
.comment-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select,
.comment-content form.widget.widget_search .theme-input-group select,
.entry-content form.newsletter-form .theme-input-group select,
.entry-content form.search-form .theme-input-group select,
.entry-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select,
.entry-content form.widget.widget_search .theme-input-group select,
.page--content form.newsletter-form .theme-input-group select,
.page--content form.search-form .theme-input-group select,
.page--content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select,
.page--content form.widget.widget_search .theme-input-group select,
form.newsletter-form .theme-input-group .comment-content select,
form.newsletter-form .theme-input-group .entry-content select,
form.newsletter-form .theme-input-group .page--content select,
form.newsletter-form .theme-input-group .theme-input-style,
form.search-form .theme-input-group .comment-content select,
form.search-form .theme-input-group .entry-content select,
form.search-form .theme-input-group .page--content select,
form.search-form .theme-input-group .theme-input-style,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.comment-content
	select,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.entry-content
	select,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.page--content
	select,
form.widget.widget_newsletter.style--two .theme-input-group .theme-input-style,
form.widget.widget_search .theme-input-group .comment-content select,
form.widget.widget_search .theme-input-group .entry-content select,
form.widget.widget_search .theme-input-group .page--content select,
form.widget.widget_search .theme-input-group .theme-input-style {
	border-bottom: 1px solid #999 !important;
	padding: 0 10px;
	background-color: transparent;
	height: 40px;
	color: #707070;
	border-radius: 0;
}

.comment-content
	form.newsletter-form
	.theme-input-group
	select::-webkit-input-placeholder,
.comment-content
	form.search-form
	.theme-input-group
	select::-webkit-input-placeholder,
.comment-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::-webkit-input-placeholder,
.comment-content
	form.widget.widget_search
	.theme-input-group
	select::-webkit-input-placeholder,
.entry-content
	form.newsletter-form
	.theme-input-group
	select::-webkit-input-placeholder,
.entry-content
	form.search-form
	.theme-input-group
	select::-webkit-input-placeholder,
.entry-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::-webkit-input-placeholder,
.entry-content
	form.widget.widget_search
	.theme-input-group
	select::-webkit-input-placeholder,
.page--content
	form.newsletter-form
	.theme-input-group
	select::-webkit-input-placeholder,
.page--content
	form.search-form
	.theme-input-group
	select::-webkit-input-placeholder,
.page--content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::-webkit-input-placeholder,
.page--content
	form.widget.widget_search
	.theme-input-group
	select::-webkit-input-placeholder,
form.newsletter-form
	.theme-input-group
	.comment-content
	select::-webkit-input-placeholder,
form.newsletter-form
	.theme-input-group
	.entry-content
	select::-webkit-input-placeholder,
form.newsletter-form
	.theme-input-group
	.page--content
	select::-webkit-input-placeholder,
form.newsletter-form
	.theme-input-group
	.theme-input-style::-webkit-input-placeholder,
form.search-form
	.theme-input-group
	.comment-content
	select::-webkit-input-placeholder,
form.search-form
	.theme-input-group
	.entry-content
	select::-webkit-input-placeholder,
form.search-form
	.theme-input-group
	.page--content
	select::-webkit-input-placeholder,
form.search-form
	.theme-input-group
	.theme-input-style::-webkit-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.comment-content
	select::-webkit-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.entry-content
	select::-webkit-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.page--content
	select::-webkit-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.theme-input-style::-webkit-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.comment-content
	select::-webkit-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.entry-content
	select::-webkit-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.page--content
	select::-webkit-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.theme-input-style::-webkit-input-placeholder {
	color: #d1d1d1 !important;
}

.comment-content
	form.newsletter-form
	.theme-input-group
	select::-moz-placeholder,
.comment-content form.search-form .theme-input-group select::-moz-placeholder,
.comment-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::-moz-placeholder,
.comment-content
	form.widget.widget_search
	.theme-input-group
	select::-moz-placeholder,
.entry-content form.newsletter-form .theme-input-group select::-moz-placeholder,
.entry-content form.search-form .theme-input-group select::-moz-placeholder,
.entry-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::-moz-placeholder,
.entry-content
	form.widget.widget_search
	.theme-input-group
	select::-moz-placeholder,
.page--content form.newsletter-form .theme-input-group select::-moz-placeholder,
.page--content form.search-form .theme-input-group select::-moz-placeholder,
.page--content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::-moz-placeholder,
.page--content
	form.widget.widget_search
	.theme-input-group
	select::-moz-placeholder,
form.newsletter-form
	.theme-input-group
	.comment-content
	select::-moz-placeholder,
form.newsletter-form .theme-input-group .entry-content select::-moz-placeholder,
form.newsletter-form .theme-input-group .page--content select::-moz-placeholder,
form.newsletter-form .theme-input-group .theme-input-style::-moz-placeholder,
form.search-form .theme-input-group .comment-content select::-moz-placeholder,
form.search-form .theme-input-group .entry-content select::-moz-placeholder,
form.search-form .theme-input-group .page--content select::-moz-placeholder,
form.search-form .theme-input-group .theme-input-style::-moz-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.comment-content
	select::-moz-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.entry-content
	select::-moz-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.page--content
	select::-moz-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.theme-input-style::-moz-placeholder,
form.widget.widget_search
	.theme-input-group
	.comment-content
	select::-moz-placeholder,
form.widget.widget_search
	.theme-input-group
	.entry-content
	select::-moz-placeholder,
form.widget.widget_search
	.theme-input-group
	.page--content
	select::-moz-placeholder,
form.widget.widget_search
	.theme-input-group
	.theme-input-style::-moz-placeholder {
	color: #d1d1d1 !important;
}

.comment-content
	form.newsletter-form
	.theme-input-group
	select:-ms-input-placeholder,
.comment-content
	form.search-form
	.theme-input-group
	select:-ms-input-placeholder,
.comment-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select:-ms-input-placeholder,
.comment-content
	form.widget.widget_search
	.theme-input-group
	select:-ms-input-placeholder,
.entry-content
	form.newsletter-form
	.theme-input-group
	select:-ms-input-placeholder,
.entry-content form.search-form .theme-input-group select:-ms-input-placeholder,
.entry-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select:-ms-input-placeholder,
.entry-content
	form.widget.widget_search
	.theme-input-group
	select:-ms-input-placeholder,
.page--content
	form.newsletter-form
	.theme-input-group
	select:-ms-input-placeholder,
.page--content form.search-form .theme-input-group select:-ms-input-placeholder,
.page--content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select:-ms-input-placeholder,
.page--content
	form.widget.widget_search
	.theme-input-group
	select:-ms-input-placeholder,
form.newsletter-form
	.theme-input-group
	.comment-content
	select:-ms-input-placeholder,
form.newsletter-form
	.theme-input-group
	.entry-content
	select:-ms-input-placeholder,
form.newsletter-form
	.theme-input-group
	.page--content
	select:-ms-input-placeholder,
form.newsletter-form
	.theme-input-group
	.theme-input-style:-ms-input-placeholder,
form.search-form
	.theme-input-group
	.comment-content
	select:-ms-input-placeholder,
form.search-form .theme-input-group .entry-content select:-ms-input-placeholder,
form.search-form .theme-input-group .page--content select:-ms-input-placeholder,
form.search-form .theme-input-group .theme-input-style:-ms-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.comment-content
	select:-ms-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.entry-content
	select:-ms-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.page--content
	select:-ms-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.theme-input-style:-ms-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.comment-content
	select:-ms-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.entry-content
	select:-ms-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.page--content
	select:-ms-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.theme-input-style:-ms-input-placeholder {
	color: #d1d1d1 !important;
}

.comment-content
	form.newsletter-form
	.theme-input-group
	select::-ms-input-placeholder,
.comment-content
	form.search-form
	.theme-input-group
	select::-ms-input-placeholder,
.comment-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::-ms-input-placeholder,
.comment-content
	form.widget.widget_search
	.theme-input-group
	select::-ms-input-placeholder,
.entry-content
	form.newsletter-form
	.theme-input-group
	select::-ms-input-placeholder,
.entry-content
	form.search-form
	.theme-input-group
	select::-ms-input-placeholder,
.entry-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::-ms-input-placeholder,
.entry-content
	form.widget.widget_search
	.theme-input-group
	select::-ms-input-placeholder,
.page--content
	form.newsletter-form
	.theme-input-group
	select::-ms-input-placeholder,
.page--content
	form.search-form
	.theme-input-group
	select::-ms-input-placeholder,
.page--content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::-ms-input-placeholder,
.page--content
	form.widget.widget_search
	.theme-input-group
	select::-ms-input-placeholder,
form.newsletter-form
	.theme-input-group
	.comment-content
	select::-ms-input-placeholder,
form.newsletter-form
	.theme-input-group
	.entry-content
	select::-ms-input-placeholder,
form.newsletter-form
	.theme-input-group
	.page--content
	select::-ms-input-placeholder,
form.newsletter-form
	.theme-input-group
	.theme-input-style::-ms-input-placeholder,
form.search-form
	.theme-input-group
	.comment-content
	select::-ms-input-placeholder,
form.search-form
	.theme-input-group
	.entry-content
	select::-ms-input-placeholder,
form.search-form
	.theme-input-group
	.page--content
	select::-ms-input-placeholder,
form.search-form .theme-input-group .theme-input-style::-ms-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.comment-content
	select::-ms-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.entry-content
	select::-ms-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.page--content
	select::-ms-input-placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.theme-input-style::-ms-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.comment-content
	select::-ms-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.entry-content
	select::-ms-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.page--content
	select::-ms-input-placeholder,
form.widget.widget_search
	.theme-input-group
	.theme-input-style::-ms-input-placeholder {
	color: #d1d1d1 !important;
}

.comment-content form.newsletter-form .theme-input-group select::placeholder,
.comment-content form.search-form .theme-input-group select::placeholder,
.comment-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::placeholder,
.comment-content
	form.widget.widget_search
	.theme-input-group
	select::placeholder,
.entry-content form.newsletter-form .theme-input-group select::placeholder,
.entry-content form.search-form .theme-input-group select::placeholder,
.entry-content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::placeholder,
.entry-content form.widget.widget_search .theme-input-group select::placeholder,
.page--content form.newsletter-form .theme-input-group select::placeholder,
.page--content form.search-form .theme-input-group select::placeholder,
.page--content
	form.widget.widget_newsletter.style--two
	.theme-input-group
	select::placeholder,
.page--content form.widget.widget_search .theme-input-group select::placeholder,
form.newsletter-form .theme-input-group .comment-content select::placeholder,
form.newsletter-form .theme-input-group .entry-content select::placeholder,
form.newsletter-form .theme-input-group .page--content select::placeholder,
form.newsletter-form .theme-input-group .theme-input-style::placeholder,
form.search-form .theme-input-group .comment-content select::placeholder,
form.search-form .theme-input-group .entry-content select::placeholder,
form.search-form .theme-input-group .page--content select::placeholder,
form.search-form .theme-input-group .theme-input-style::placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.comment-content
	select::placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.entry-content
	select::placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.page--content
	select::placeholder,
form.widget.widget_newsletter.style--two
	.theme-input-group
	.theme-input-style::placeholder,
form.widget.widget_search
	.theme-input-group
	.comment-content
	select::placeholder,
form.widget.widget_search .theme-input-group .entry-content select::placeholder,
form.widget.widget_search .theme-input-group .page--content select::placeholder,
form.widget.widget_search .theme-input-group .theme-input-style::placeholder {
	color: #d1d1d1 !important;
}

form.newsletter-form .theme-input-group .submit-btn,
form.search-form .theme-input-group .submit-btn,
form.widget.widget_newsletter.style--two .theme-input-group .submit-btn,
form.widget.widget_search .theme-input-group .submit-btn {
	border: none;
	padding: 10px;
	background-color: transparent;
}

.countdown-wrapper {
	padding: 50px;
	padding-bottom: 44px;
	background: #fff;
}

@media only screen and (max-width: 991px) {
	.countdown-wrapper {
		margin-top: 30px;
	}
}

@media only screen and (max-width: 575px) {
	.countdown-wrapper {
		padding: 30px 20px 26px;
	}
}

.countdown-wrapper .countdown-tag {
	display: inline-block;
	background-color: #c9fff5;
	padding: 4px 22px;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 26px;
}

#countdown .single-countdown {
	text-align: center;
}

#countdown .single-countdown h2 {
	font-size: 60px;
	font-weight: 600;
	color: #292929;
}

@media only screen and (max-width: 575px) {
	#countdown .single-countdown h2 {
		font-size: 40px;
	}
}

@media only screen and (max-width: 575px) {
	#countdown .single-countdown p {
		font-size: 14px;
	}
}

.video-play-button {
	width: 68px;
	height: 68px;
	color: #fff;
	position: absolute;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 2;
}

.video-play-button:hover {
	color: #fff;
}

.trapezium-video {
	position: relative;
	z-index: 1;
}

.trapezium-video:after {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: #fff;
	opacity: 0.3;
	content: '';
	position: absolute;
}

#course {
	background: #f6f8f5;
}

.bg-right {
	position: absolute;
	left: auto;
	right: -300%;
	top: 0;
	width: calc(400% - 15px);
	height: 100%;
	border-radius: 150px 0 0 0;
	z-index: -1;
}

@media only screen and (max-width: 767px) {
	.bg-right {
		width: 600%;
	}
}

.custom-checkbox input[type='checkbox'] {
	position: relative;
	top: 1px;
}

.custom-checkbox.pos label {
	display: block;
	position: relative;
	padding-left: 30px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.custom-checkbox.pos label input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.custom-checkbox.pos .checkmark {
	position: absolute;
	top: 6px;
	left: 0;
	height: 16px;
	width: 16px;
	background-color: transparent;
	border: 1px solid #00418e;
}

.custom-checkbox.pos label input:checked ~ .checkmark {
	background-color: #00418e;
}

.custom-checkbox.pos .checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

.custom-checkbox.pos label input:checked ~ .checkmark:after {
	display: block;
}

.custom-checkbox.pos label .checkmark:after {
	left: 5px;
	top: 1px;
	width: 5px;
	height: 10px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.app2-bg {
	background: #f5f8ff;
}

@media only screen and (max-width: 991px) {
	.about-content {
		margin-top: 60px;
	}
}

.about-content h2 {
	font-weight: 600;
	color: #292929;
	margin-bottom: 20px;
	line-height: 1.2;
}

.body-bg {
	background-color: #f6f8f5;
}

.service-cta-bg {
	background-color: #857dff;
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes grow {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes grow {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}

	50% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.grow {
	-webkit-animation-name: grow;
	animation-name: grow;
}

@-webkit-keyframes grow2 {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes grow2 {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	50% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.grow2 {
	-webkit-animation-name: grow2;
	animation-name: grow2;
}

@-webkit-keyframes growDown {
	0% {
		opacity: 0;
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes growDown {
	0% {
		opacity: 0;
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.growDown {
	-webkit-animation-name: growDown;
	animation-name: growDown;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDown2 {
	0% {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 0.2;
	}
}

@keyframes fadeInDown2 {
	0% {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 0.2;
	}
}

.fadeInDown2 {
	-webkit-animation-name: fadeInDown2;
	animation-name: fadeInDown2;
}

@-webkit-keyframes fadeInDownBig {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes fadeInDownBig {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100px);
		transform: translateY(-100px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.fadeInDownBig {
	-webkit-animation-name: fadeInDownBig;
	animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-40px);
		transform: translateX(-40px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-40px);
		transform: translateX(-40px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeft2 {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-10px);
		transform: translateX(-10px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes fadeInLeft2 {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-10px);
		transform: translateX(-10px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.fadeInLeft2 {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-200px);
		transform: translateX(-200px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes fadeInLeftBig {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-200px);
		transform: translateX(-200px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.fadeInLeftBig {
	-webkit-animation-name: fadeInLeftBig;
	animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(40px);
		transform: translateX(40px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(40px);
		transform: translateX(40px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRight2 {
	0% {
		opacity: 0;
		-webkit-transform: translateX(10px);
		transform: translateX(10px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes fadeInRight2 {
	0% {
		opacity: 0;
		-webkit-transform: translateX(10px);
		transform: translateX(10px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.fadeInRight2 {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
	0% {
		opacity: 0;
		-webkit-transform: translateX(200px);
		transform: translateX(200px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes fadeInRightBig {
	0% {
		opacity: 0;
		-webkit-transform: translateX(200px);
		transform: translateX(200px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.fadeInRightBig {
	-webkit-animation-name: fadeInRightBig;
	animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(40px);
		transform: translateY(40px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUp2 {
	0% {
		opacity: 0;
		-webkit-transform: translateY(200px);
		transform: translateY(200px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes fadeInUp2 {
	0% {
		opacity: 0;
		-webkit-transform: translateY(200px);
		transform: translateY(200px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.fadeInUp2 {
	-webkit-animation-name: fadeInUp2;
	animation-name: fadeInUp2;
}

@-webkit-keyframes fadeInUp10 {
	0% {
		opacity: 0;
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes fadeInUp10 {
	0% {
		opacity: 0;
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.fadeInUp10 {
	-webkit-animation-name: fadeInUp10;
	animation-name: fadeInUp10;
}

@-webkit-keyframes fadeInUpBig {
	0% {
		opacity: 0;
		-webkit-transform: translateY(100px);
		transform: translateY(100px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes fadeInUpBig {
	0% {
		opacity: 0;
		-webkit-transform: translateY(100px);
		transform: translateY(100px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.fadeInUpBig {
	-webkit-animation-name: fadeInUpBig;
	animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes slideInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
		transform: translateX(-2000px);
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes slideInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-2000px);
		-ms-transform: translateX(-2000px);
		transform: translateX(-2000px);
	}

	100% {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
}

.slideInLeft {
	-webkit-animation-name: slideInLeft;
	animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
	0% {
		-webkit-transform: translateX(2000px);
		transform: translateX(2000px);
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes slideInRight {
	0% {
		-webkit-transform: translateX(2000px);
		-ms-transform: translateX(2000px);
		transform: translateX(2000px);
	}

	100% {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
}

.slideInRight {
	-webkit-animation-name: slideInRight;
	animation-name: slideInRight;
}

@-webkit-keyframes bg-scale {
	0% {
		-webkit-transform: translate(100%);
		transform: translate(100%);
	}

	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}

@keyframes bg-scale {
	0% {
		-webkit-transform: translate(100%);
		transform: translate(100%);
	}

	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}

.bg-scale {
	-webkit-animation-name: bg-scale;
	animation-name: bg-scale;
}

@-webkit-keyframes cbat {
	0% {
		-webkit-transform: translateX(30%) translateY(30%);
		transform: translateX(30%) translateY(30%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0) translateY(0);
		transform: translateX(0) translateY(0);
		opacity: 1;
	}
}

@keyframes cbat {
	0% {
		-webkit-transform: translateX(30%) translateY(30%);
		transform: translateX(30%) translateY(30%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0) translateY(0);
		transform: translateX(0) translateY(0);
		opacity: 1;
	}
}

.cbat {
	-webkit-animation-name: cbat;
	animation-name: cbat;
}

@-webkit-keyframes cbab {
	0% {
		-webkit-transform: translateX(-30%) translateY(-30%);
		transform: translateX(-30%) translateY(-30%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0) translateY(0);
		transform: translateX(0) translateY(0);
		opacity: 1;
	}
}

@keyframes cbab {
	0% {
		-webkit-transform: translateX(-30%) translateY(-30%);
		transform: translateX(-30%) translateY(-30%);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0) translateY(0);
		transform: translateX(0) translateY(0);
		opacity: 1;
	}
}

.cbab {
	-webkit-animation-name: cbab;
	animation-name: cbab;
}

@-webkit-keyframes zoomIn {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes zoomIn {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.zoomIn {
	-webkit-animation-name: zoomIn;
	animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
	0% {
		-webkit-transform: scale(1.01);
		transform: scale(1.01);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes zoomOut {
	0% {
		-webkit-transform: scale(1.01);
		transform: scale(1.01);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.zoomOut {
	-webkit-animation-name: zoomOut;
	animation-name: zoomOut;
}

@-webkit-keyframes arrowRight {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateX(10px);
		transform: translateX(10px);
	}
}

@keyframes arrowRight {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateX(10px);
		transform: translateX(10px);
	}
}

.arrowRight {
	-webkit-animation-name: arrowRight;
	animation-name: arrowRight;
}

@-webkit-keyframes arrowLeft {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateX(-10px);
		transform: translateX(-10px);
	}
}

@keyframes arrowLeft {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateX(-10px);
		transform: translateX(-10px);
	}
}

.arrowLeft {
	-webkit-animation-name: arrowLeft;
	animation-name: arrowLeft;
}

@-webkit-keyframes zoomDown {
	0% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes zoomDown {
	0% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.zoomDown {
	-webkit-animation-name: zoomDown;
	animation-name: zoomDown;
}

@-webkit-keyframes heightWidth {
	0% {
		height: 0;
		width: 0;
	}

	100% {
		height: 100%;
		width: 100%;
	}
}

@keyframes heightWidth {
	0% {
		height: 0;
		width: 0;
	}

	100% {
		height: 100%;
		width: 100%;
	}
}

.heightWidth {
	-webkit-animation-name: height;
	animation-name: height;
}

@-webkit-keyframes levitate {
	from {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	50% {
		-webkit-transform: translate(0, 10px);
		transform: translate(0, 10px);
	}

	to {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes levitate {
	from {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}

	50% {
		-webkit-transform: translate(0, 10px);
		transform: translate(0, 10px);
	}

	to {
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

.levitate {
	-webkit-animation-name: levitate;
	animation-name: levitate;
	-webkit-animation-duration: 3s;
	animation-duration: 3s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
}

@-webkit-keyframes wide {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
	}

	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

@keyframes wide {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
	}

	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

.wide {
	-webkit-animation-name: wide;
	animation-name: wide;
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes rotate {
	from {
		-webkit-transform: rotate(5deg);
		transform: rotate(5deg);
	}

	to {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
}

@keyframes rotate {
	from {
		-webkit-transform: rotate(5deg);
		transform: rotate(5deg);
	}

	to {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
}

@-webkit-keyframes banner3Shake {
	0% {
		-webkit-transform: rotate3d(0, 1, 0, 0deg);
		transform: rotate3d(0, 1, 0, 0deg);
	}

	30% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	60% {
		-webkit-transform: rotate3d(1, 0, 0, 0deg);
		transform: rotate3d(1, 0, 0, 0deg);
	}

	80% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	100% {
		-webkit-transform: rotate3d(0, 1, 0, 0deg);
		transform: rotate3d(0, 1, 0, 0deg);
	}
}

@keyframes banner3Shake {
	0% {
		-webkit-transform: rotate3d(0, 1, 0, 0deg);
		transform: rotate3d(0, 1, 0, 0deg);
	}

	30% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	60% {
		-webkit-transform: rotate3d(1, 0, 0, 0deg);
		transform: rotate3d(1, 0, 0, 0deg);
	}

	80% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	100% {
		-webkit-transform: rotate3d(0, 1, 0, 0deg);
		transform: rotate3d(0, 1, 0, 0deg);
	}
}

@-webkit-keyframes leftToRight {
	from {
		left: 0;
	}

	to {
		right: 0;
	}
}

@keyframes leftToRight {
	from {
		left: -130px;
	}

	to {
		left: 100%;
	}
}

.preloader {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#00b377),
		to(#00d68f)
	);
	background: -o-linear-gradient(left, #00b377, #00d68f);
	background: linear-gradient(90deg, #00b377, #00d68f);
	background: #000;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: hidden;
	z-index: 9999999;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.loader-circle {
	position: absolute;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.loader-circle .content {
	font-size: 40px;
	font-weight: 700;
	color: #fff;
	left: 50%;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.loader-line-mask {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 60px;
	height: 120px;
	margin-left: -60px;
	margin-top: -60px;
	overflow: hidden;
	-webkit-transform-origin: 60px 60px;
	-ms-transform-origin: 60px 60px;
	transform-origin: 60px 60px;
	-webkit-mask-image: -webkit-linear-gradient(top, #000, rgba(0, 0, 0, 0));
	mask-image: -webkit-linear-gradient(top, #000, rgba(0, 0, 0, 0));
	-webkit-animation: spin 1.2s infinite linear;
	animation: spin 1.2s infinite linear;
}

.loader-line-mask .loader-line {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.btn,
.btn-book,
.btn-crm,
.btn-crs,
.btn-pay,
.e-btn,
.m-btn {
	display: inline-block;
	padding: 20px 33px;
	font-weight: 500;
	line-height: 1;
	font-size: 18px;
	position: relative;
	cursor: pointer;
	color: #fff;
	border: 1px solid transparent;
	text-transform: capitalize;
}

.btn-book:hover,
.btn-crm:hover,
.btn-crs:hover,
.btn-pay:hover,
.btn:hover,
.e-btn:hover,
.m-btn:hover {
	color: #fff;
	background: #5f52dc;
}

.btn.two:hover,
.two.btn-book:hover,
.two.btn-crm:hover,
.two.btn-crs:hover,
.two.btn-pay:hover,
.two.e-btn:hover,
.two.m-btn:hover {
	background: #fff;
	color: #5f52dc;
}

.btn-book:focus,
.btn-crm:focus,
.btn-crs:focus,
.btn-pay:focus,
.btn.focus,
.btn:focus,
.e-btn:focus,
.focus.btn-book,
.focus.btn-crm,
.focus.btn-crs,
.focus.btn-pay,
.focus.e-btn,
.focus.m-btn,
.m-btn:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn.s-btn:hover,
.s-btn.btn-book:hover,
.s-btn.btn-crm:hover,
.s-btn.btn-crs:hover,
.s-btn.btn-pay:hover,
.s-btn.e-btn:hover,
.s-btn.m-btn:hover {
	color: #fff;
	background: #fcbf21;
}

.animate-btn.btn-book,
.animate-btn.btn-crm,
.animate-btn.btn-crs,
.animate-btn.btn-pay,
.animate-btn.e-btn,
.animate-btn.m-btn,
.btn.animate-btn {
	background: #fff;
	position: relative;
	padding: 12px 20px;
	-webkit-transform: translateX(-20px);
	-ms-transform: translateX(-20px);
	transform: translateX(-20px);
}

.animate-btn.btn-book:after,
.animate-btn.btn-crm:after,
.animate-btn.btn-crs:after,
.animate-btn.btn-pay:after,
.animate-btn.e-btn:after,
.animate-btn.m-btn:after,
.btn.animate-btn:after {
	width: 48px;
	height: 100%;
	opacity: 0.3;
	position: absolute;
	content: '';
	right: 0;
	top: 0;
}

.animate-btn.btn-book:hover,
.animate-btn.btn-crm:hover,
.animate-btn.btn-crs:hover,
.animate-btn.btn-pay:hover,
.animate-btn.e-btn:hover,
.animate-btn.m-btn:hover,
.btn.animate-btn:hover {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

.animate-btn.btn-book:hover:after,
.animate-btn.btn-crm:hover:after,
.animate-btn.btn-crs:hover:after,
.animate-btn.btn-pay:hover:after,
.animate-btn.e-btn:hover:after,
.animate-btn.m-btn:hover:after,
.btn.animate-btn:hover:after {
	width: 100%;
	height: 100%;
}

.animate-btn.btn-book i,
.animate-btn.btn-crm i,
.animate-btn.btn-crs i,
.animate-btn.btn-pay i,
.animate-btn.e-btn i,
.animate-btn.m-btn i,
.btn.animate-btn i {
	font-size: 20px;
	padding-left: 10px;
	position: relative;
	top: 2px;
}

.border-btn.btn-book,
.border-btn.btn-crm,
.border-btn.btn-crs,
.border-btn.btn-pay,
.border-btn.e-btn,
.border-btn.m-btn,
.btn.border-btn {
	border: 1px solid #707070;
	background: 0 0;
}

.border-btn.btn-book:hover,
.border-btn.btn-crm:hover,
.border-btn.btn-crs:hover,
.border-btn.btn-pay:hover,
.border-btn.e-btn:hover,
.border-btn.m-btn:hover,
.btn.border-btn:hover {
	border: 1px solid transparent;
	color: #fff;
}

.btn.pricing-btn,
.pricing-btn.btn-book,
.pricing-btn.btn-crm,
.pricing-btn.btn-crs,
.pricing-btn.btn-pay,
.pricing-btn.e-btn,
.pricing-btn.m-btn {
	border: 1px solid transparent;
}

.btn.pricing-btn:hover,
.pricing-btn.btn-book:hover,
.pricing-btn.btn-crm:hover,
.pricing-btn.btn-crs:hover,
.pricing-btn.btn-pay:hover,
.pricing-btn.e-btn:hover,
.pricing-btn.m-btn:hover {
	border: 1px solid transparent;
}

.app-btn.btn-book,
.app-btn.btn-crm,
.app-btn.btn-crs,
.app-btn.btn-pay,
.app-btn.e-btn,
.app-btn.m-btn,
.btn.app-btn {
	padding: 15px 33px;
	background-color: #ffe5db;
}

.app-btn.btn-book:hover,
.app-btn.btn-crm:hover,
.app-btn.btn-crs:hover,
.app-btn.btn-pay:hover,
.app-btn.e-btn:hover,
.app-btn.m-btn:hover,
.btn.app-btn:hover {
	color: #fff;
}

.app-btn.price1.btn-book,
.app-btn.price1.btn-crm,
.app-btn.price1.btn-crs,
.app-btn.price1.btn-pay,
.app-btn.price1.e-btn,
.app-btn.price1.m-btn,
.btn.app-btn.price1 {
	background-color: #e6f4ff;
	color: #4ea8f1;
}

.app-btn.price1.btn-book:hover,
.app-btn.price1.btn-crm:hover,
.app-btn.price1.btn-crs:hover,
.app-btn.price1.btn-pay:hover,
.app-btn.price1.e-btn:hover,
.app-btn.price1.m-btn:hover,
.btn.app-btn.price1:hover {
	background-color: #4ea8f1;
	color: #fff;
}

.app-btn.price2.btn-book,
.app-btn.price2.btn-crm,
.app-btn.price2.btn-crs,
.app-btn.price2.btn-pay,
.app-btn.price2.e-btn,
.app-btn.price2.m-btn,
.btn.app-btn.price2 {
	background-color: #dcfef6;
	color: #3bebc5;
}

.app-btn.price2.btn-book:hover,
.app-btn.price2.btn-crm:hover,
.app-btn.price2.btn-crs:hover,
.app-btn.price2.btn-pay:hover,
.app-btn.price2.e-btn:hover,
.app-btn.price2.m-btn:hover,
.btn.app-btn.price2:hover {
	background-color: #3bebc5;
	color: #fff;
}

.btn.white-btn,
.white-btn.btn-book,
.white-btn.btn-crm,
.white-btn.btn-crs,
.white-btn.btn-pay,
.white-btn.e-btn,
.white-btn.m-btn {
	background-color: #fff;
	color: #292929;
	padding-top: 15px;
	padding-bottom: 15px;
}

.btn.white-btn:hover,
.white-btn.btn-book:hover,
.white-btn.btn-crm:hover,
.white-btn.btn-crs:hover,
.white-btn.btn-pay:hover,
.white-btn.e-btn:hover,
.white-btn.m-btn:hover {
	color: #fff;
}

.app2.btn-book,
.app2.btn-crm,
.app2.btn-crs,
.app2.btn-pay,
.app2.e-btn,
.app2.m-btn,
.btn.app2 {
	padding: 12px 26px;
	z-index: 1;
	overflow: hidden;
	background: #ff6773;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#ff6773),
		to(#ff896b)
	);
	background: -o-linear-gradient(top, #ff6773 0, #ff896b 100%);
	background: linear-gradient(to bottom, #ff6773 0, #ff896b 100%);
}

.app2.btn-book:after,
.app2.btn-crm:after,
.app2.btn-crs:after,
.app2.btn-pay:after,
.app2.e-btn:after,
.app2.m-btn:after,
.btn.app2:after {
	left: 0;
	top: -100%;
	width: 100%;
	height: 100%;
	content: '';
	position: absolute;
	border-radius: 0 5px 5px 0;
	z-index: -1;
	background: #ff896b;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#ff896b),
		to(#ff6773)
	);
	background: -o-linear-gradient(top, #ff896b 0, #ff6773 100%);
	background: linear-gradient(to bottom, #ff896b 0, #ff6773 100%);
}

.app2.btn-book:hover,
.app2.btn-crm:hover,
.app2.btn-crs:hover,
.app2.btn-pay:hover,
.app2.e-btn:hover,
.app2.m-btn:hover,
.btn.app2:hover {
	color: #fff;
}

.app2.btn-book:hover:after,
.app2.btn-crm:hover:after,
.app2.btn-crs:hover:after,
.app2.btn-pay:hover:after,
.app2.e-btn:hover:after,
.app2.m-btn:hover:after,
.btn.app2:hover:after {
	top: 0;
}

.btn.proto,
.proto.btn-book,
.proto.btn-crm,
.proto.btn-crs,
.proto.btn-pay,
.proto.e-btn,
.proto.m-btn {
	padding: 15px 30px;
}

.btn.proto .banner-content,
.proto.btn-book .banner-content,
.proto.btn-crm .banner-content,
.proto.btn-crs .banner-content,
.proto.btn-pay .banner-content,
.proto.e-btn .banner-content,
.proto.m-btn .banner-content {
	padding-right: 0;
}

.btn.download,
.download.btn-book,
.download.btn-crm,
.download.btn-crs,
.download.btn-pay,
.download.e-btn,
.download.m-btn {
	padding: 14px 28px;
	position: relative;
	overflow: hidden;
	z-index: 1;
	border: none;
}

.btn.download .svg,
.download.btn-book .svg,
.download.btn-crm .svg,
.download.btn-crs .svg,
.download.btn-pay .svg,
.download.e-btn .svg,
.download.m-btn .svg {
	margin-right: 10px;
}

.btn.download:after,
.download.btn-book:after,
.download.btn-crm:after,
.download.btn-crs:after,
.download.btn-pay:after,
.download.e-btn:after,
.download.m-btn:after {
	position: absolute;
	left: 0;
	top: -100%;
	width: 100%;
	height: 100%;
	background-color: #ffe4dc;
	content: '';
	z-index: -1;
}

.btn.download:hover:after,
.download.btn-book:hover:after,
.download.btn-crm:hover:after,
.download.btn-crs:hover:after,
.download.btn-pay:hover:after,
.download.e-btn:hover:after,
.download.m-btn:hover:after {
	top: 0;
}

.btn.download:hover .svg g path,
.download.btn-book:hover .svg g path,
.download.btn-crm:hover .svg g path,
.download.btn-crs:hover .svg g path,
.download.btn-pay:hover .svg g path,
.download.e-btn:hover .svg g path,
.download.m-btn:hover .svg g path {
	fill: #5a50a5;
}

.btn.download.s-btn,
.download.s-btn.btn-book,
.download.s-btn.btn-crm,
.download.s-btn.btn-crs,
.download.s-btn.btn-pay,
.download.s-btn.e-btn,
.download.s-btn.m-btn {
	background-color: #ffe4dc;
}

.btn.download.s-btn:hover,
.download.s-btn.btn-book:hover,
.download.s-btn.btn-crm:hover,
.download.s-btn.btn-crs:hover,
.download.s-btn.btn-pay:hover,
.download.s-btn.e-btn:hover,
.download.s-btn.m-btn:hover {
	color: #fff;
}

.btn.download.s-btn:hover .svg path,
.download.s-btn.btn-book:hover .svg path,
.download.s-btn.btn-crm:hover .svg path,
.download.s-btn.btn-crs:hover .svg path,
.download.s-btn.btn-pay:hover .svg path,
.download.s-btn.e-btn:hover .svg path,
.download.s-btn.m-btn:hover .svg path {
	fill: #fff;
}

.btn.submit-btn,
.submit-btn.btn-book,
.submit-btn.btn-crm,
.submit-btn.btn-crs,
.submit-btn.btn-pay,
.submit-btn.e-btn,
.submit-btn.m-btn {
	padding: 20px 35px;
	border-radius: 0;
	font-weight: 600;
	color: #fff;
}

.btn.submit-btn:hover,
.submit-btn.btn-book:hover,
.submit-btn.btn-crm:hover,
.submit-btn.btn-crs:hover,
.submit-btn.btn-pay:hover,
.submit-btn.e-btn:hover,
.submit-btn.m-btn:hover {
	color: #fff;
}

.link-btn.analytic {
	color: #292929;
	text-transform: capitalize;
	font-size: 18px;
	font-weight: 500;
}

.cart-btn {
	position: relative;
}

.cart-btn span {
	width: 16px;
	height: 16px;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
	position: absolute;
	right: -7px;
	top: 0;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.arrow-btn {
	color: #ff5b83;
	font-size: 18px;
	font-weight: 500;
	text-transform: capitalize;
}

.arrow-btn .svg {
	margin-left: 10px;
	width: 24px;
}

.arrow-btn:hover {
	color: #ff5b83;
}

.arrow-btn:hover .svg {
	margin-left: 15px;
}

.btn-crm {
	border-radius: 6px;
	background: #57548d;
}

.btn-crm:hover {
	background: #ebd281;
}

.btn-crm.s-btn:hover {
	color: #ebd281 !important;
	background: #57548d;
}

.btn-crm.s-btn.two:hover {
	color: #57548d !important;
	background: #fff;
}

.btn-crm.border-btn {
	border: 1px solid #57548d;
}

.btn-crm.border-btn:hover {
	border: 1px solid transparent;
	color: #57548d;
}

.btn-crm.border-btn.style--two {
	color: #fff;
	border: 1px solid #5dd9c1;
}

.btn-crm.border-btn.style--two .svg {
	margin-right: 5px;
}

.btn-crm.border-btn.style--two:hover {
	border: 1px solid transparent;
	color: #fff;
}

.btn-crm.border-btn.style--two:hover .svg {
	margin-right: 5px;
}

.btn-crm.border-btn.style--two:hover .svg path {
	fill: #fbf349;
}

.btn-crm.border-btn.style--three {
	color: #fff;
	border: 1px solid #fff;
}

.btn-crm.border-btn.style--three .svg {
	margin-right: 5px;
}

.btn-crm.border-btn.style--three .svg path {
	fill: #fff;
}

.btn-crm.border-btn.style--three:hover {
	border: 1px solid transparent;
	color: #fff;
}

.btn-crm.btn-view-more {
	background: 0 0;
	color: #57548d;
}

.btn-crm.btn-view-more .svg {
	margin-left: 5px;
}

.btn-crm.btn-view-more:hover {
	color: #ebd281;
}

.btn-crm.btn-view-more:hover .svg {
	-webkit-animation: arrowRight 0.8s linear 0.2s infinite;
	animation: arrowRight 0.8s linear 0.2s infinite;
}

.btn-crm.btn-view-more:hover .svg line,
.btn-crm.btn-view-more:hover .svg path {
	stroke: #ebd281;
}

.btn-crs {
	border-radius: 6px;
	background: #5dd9c1;
	padding: 15px 33px;
}

.btn-crs.plug {
	background: #fbf349;
	color: #4e2fa9;
}

.btn-crs.plug:hover {
	background: #5dd9c1;
	color: #fff;
}

.btn-crs.host {
	background: #f7a400;
	color: #fff;
	overflow: hidden;
	position: relative;
}

.btn-crs.host:hover {
	background: #bdc2fc;
	color: #3e4491;
}

.btn-crs.host3 {
	background: rgba(247, 164, 0, 0.3);
	color: #f7a400;
}

.btn-crs.host3:hover {
	background: #f7a400;
	color: #fff;
}

.btn-crs:hover {
	background: #fbc006;
}

.btn-crs.s-btn {
	color: #fff;
}

.btn-crs.s-btn:hover {
	color: #fff !important;
	background: #5dd9c1;
}

.btn-crs.s-btn.plug {
	background: #5dd9c1;
	color: #fff;
}

.btn-crs.s-btn.plug:hover {
	background: #fff;
	color: #4e2fa9 !important;
}

.btn-crs.s-btn.host {
	background: #bdc2fc;
	color: #3e4491;
}

.btn-crs.s-btn.host:hover {
	background: #f7a400;
	color: #fff;
}

.btn-crs.border-btn {
	color: #292929;
	border: 1px solid #5dd9c1;
}

.btn-crs.border-btn:hover {
	border: 1px solid transparent;
	color: #fff;
}

.btn-crs.line-btn {
	color: #292929;
	background: 0 0;
	padding: 0;
}

.btn-crs.line-btn:before {
	position: absolute;
	content: '';
	background: #292929;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.btn-crs.line-btn:after {
	position: absolute;
	content: '';
	background: #292929;
	left: 0;
	bottom: 0;
	width: 0;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.btn-crs.line-btn:hover {
	background: 0 0;
}

.btn-crs.line-btn:hover:before {
	left: auto;
	right: 0;
	width: 0;
}

.btn-crs.line-btn:hover:after {
	width: 100%;
}

.btn-crs.btn-view-more {
	background: 0 0;
	color: #5dd9c1;
}

.btn-crs.btn-view-more .svg {
	margin-left: 5px;
}

.btn-crs.btn-view-more:hover {
	color: #fbc006;
}

.btn-crs.btn-view-more:hover .svg line,
.btn-crs.btn-view-more:hover .svg path {
	stroke: #fbc006;
}

.btn-crs.syllabus-btn {
	position: absolute;
	right: 15px;
	top: -10px;
}

.btn-pay,
.e-btn {
	border-radius: 0;
	background: #fe957b;
	padding: 15px 33px;
}

.btn-pay:hover,
.e-btn:hover {
	background: #baf;
	color: #fff;
}

.btn-pay.s-btn,
.s-btn.e-btn {
	background: #baf;
	color: #fff;
}

.btn-pay.s-btn:hover,
.s-btn.e-btn:hover {
	color: #fff !important;
	background: #fe957b;
}

.border-btn.e-btn,
.btn-pay.border-btn {
	color: #292929;
	border: 1px solid #5dd9c1;
}

.border-btn.e-btn:hover,
.btn-pay.border-btn:hover {
	border: 1px solid transparent;
	color: #fff;
}

.btn-pay.line-btn,
.line-btn.e-btn {
	color: #292929;
	background: 0 0;
	padding: 0;
}

.btn-pay.line-btn:before,
.line-btn.e-btn:before {
	position: absolute;
	content: '';
	background: #292929;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.btn-pay.line-btn:after,
.line-btn.e-btn:after {
	position: absolute;
	content: '';
	background: #292929;
	left: 0;
	bottom: 0;
	width: 0;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.btn-pay.line-btn:hover,
.line-btn.e-btn:hover {
	background: 0 0;
}

.btn-pay.line-btn:hover:before,
.line-btn.e-btn:hover:before {
	left: auto;
	right: 0;
	width: 0;
}

.btn-pay.line-btn:hover:after,
.line-btn.e-btn:hover:after {
	width: 100%;
}

.btn-pay.btn-view-more,
.btn-view-more.e-btn {
	background: 0 0;
	color: #5dd9c1;
}

.btn-pay.btn-view-more .svg,
.btn-view-more.e-btn .svg {
	margin-left: 5px;
}

.btn-pay.btn-view-more:hover,
.btn-view-more.e-btn:hover {
	color: #fbc006;
}

.btn-pay.btn-view-more:hover .svg line,
.btn-pay.btn-view-more:hover .svg path,
.btn-view-more.e-btn:hover .svg line,
.btn-view-more.e-btn:hover .svg path {
	stroke: #fbc006;
}

.btn-pay.syllabus-btn,
.syllabus-btn.e-btn {
	position: absolute;
	right: 15px;
	top: -10px;
}

.analytic.e-btn,
.btn-pay.analytic {
	background: #ff5b83;
}

.analytic.e-btn:hover,
.btn-pay.analytic:hover {
	background: #00418e;
}

.analytic.s-btn.e-btn,
.btn-pay.analytic.s-btn {
	background: #00418e;
}

.analytic.s-btn.e-btn:hover,
.btn-pay.analytic.s-btn:hover {
	background: #ff5b83;
}

.btn-pay.pos,
.pos.e-btn {
	background: #ff5b83;
	padding: 17px 33px;
}

.btn-pay.pos:hover,
.pos.e-btn:hover {
	background: #00418e;
}

.btn-pay.pos.s-btn,
.pos.s-btn.e-btn {
	background: #00418e;
	padding: 17px 33px;
}

.btn-pay.pos.s-btn:hover,
.pos.s-btn.e-btn:hover {
	background: #ff5b83;
}

.btn-pay.pos.border-btn,
.pos.border-btn.e-btn {
	color: #292929;
	background-color: transparent;
}

.btn-pay.pos.border-btn:hover,
.pos.border-btn.e-btn:hover {
	color: #fff;
}

.btn-pay.host,
.host.e-btn {
	background: #180d5b;
}

.btn-pay.host:hover,
.host.e-btn:hover {
	background: #f7a400;
}

.btn-pay.host.s-btn,
.host.s-btn.e-btn {
	background: #f7a400;
	padding: 20px 35px;
}

.btn-pay.host.s-btn:hover,
.host.s-btn.e-btn:hover {
	color: #180d5b !important;
}

.btn-book {
	padding: 15px 33px;
	background: #fff;
	color: #fbc006;
}

.btn-book:hover {
	background: #fbc006;
	color: #fff;
}

.btn-book.s-btn {
	background: #fbc006;
	color: #fff;
}

.btn-book.s-btn:hover {
	background: #fff;
	color: #fbc006;
}

.btn-book.line-btn {
	color: #fff;
	background: 0 0;
	padding: 0;
}

.btn-book.line-btn:before {
	position: absolute;
	content: '';
	background: #fff;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.btn-book.line-btn:after {
	position: absolute;
	content: '';
	background: #fff;
	left: 0;
	bottom: 0;
	width: 0;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.btn-book.line-btn:hover {
	background: 0 0;
}

.btn-book.line-btn:hover:before {
	left: auto;
	right: 0;
	width: 0;
}

.btn-book.line-btn:hover:after {
	width: 100%;
}

.btn-book.style--two {
	color: #fbc006;
}

.btn-book.style--two:before {
	background: #fbc006;
}

.btn-book.style--two:after {
	background: #fbc006;
}

.btn-book.style--three {
	color: #205ce2;
}

.btn-book.style--three:before {
	background: #205ce2;
}

.btn-book.style--three:after {
	background: #205ce2;
}

.btn-book.style--four {
	color: #5dd9c1;
}

.btn-book.style--four:before {
	background: #5dd9c1;
}

.btn-book.style--four:after {
	background: #5dd9c1;
}

.m-btn.s-btn:hover {
	background: #625fd1;
}

.m-btn.border-btn {
	border-color: #ff4d8c;
	color: #292929;
}

.m-btn.border-btn:hover {
	background: #ff4d8c;
	color: #fff;
}

.m-btn.border-btn.style--two:hover {
	color: #fff;
}

.header-btn a:not(:last-child) {
	margin-right: 36px;
}

.header-btn.book a {
	display: inline-block;
}

.header-btn.book a:not(:last-child) {
	margin-right: 50px;
}

.header-btn .btn-crm.style--three,
.header-btn .btn-crm.style--two {
	padding: 15px 33px;
}

.header-btn .btn-crs.style--three {
	padding: 15px 33px;
}

.header-btn .link-btn {
	color: #292929;
	font-size: 18px;
	font-weight: 500;
	position: relative;
	text-transform: capitalize;
}

.header-btn .link-btn.style--two {
	color: #292929;
}

.header-btn .link-btn.style--three:before {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.header-btn .link-btn.style--three:after {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	width: 0;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.header-btn .link-btn.style--three:hover:before {
	left: auto;
	right: 0;
	width: 0;
}

.header-btn .link-btn.style--three:hover:after {
	width: 100%;
}

.header-btn.management .link-btn {
	position: relative;
	line-height: 1;
}

.header-btn.management .link-btn:not(:last-child) {
	padding-right: 20px;
	margin-right: 16px;
}

.header-btn.management .link-btn:not(:last-child):after {
	position: absolute;
	content: '';
	top: 0;
	right: -1px;
	width: 1.5px;
	height: 100%;
	background: #292929;
}

.header-btn.pos .link-btn {
	position: relative;
	line-height: 1;
}

.header-btn.pos .link-btn:not(:last-child) {
	padding-right: 20px;
	margin-right: 16px;
}

.header-btn.pos .link-btn:not(:last-child):after {
	position: absolute;
	content: '';
	top: 0;
	right: -1px;
	width: 1.5px;
	height: 100%;
	background-color: #8a8a8a;
}

.banner-btn-group a {
	display: inline-block;
}

.banner-btn-group a:not(:last-child) {
	margin-right: 26px;
}

@media only screen and (max-width: 1199px) {
	.banner-btn-group a:not(:last-child) {
		margin-right: 20px;
	}
}

@media only screen and (max-width: 575px) {
	.banner-btn-group a:not(:last-child) {
		margin-right: 10px;
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 575px) {
	.cta-btn-group {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.cta-btn-group a:not(:last-child) {
	margin-right: 20px;
}

@media only screen and (max-width: 575px) {
	.cta-btn-group a:not(:last-child) {
		margin-right: 0;
		margin-bottom: 10px;
	}
}

.cta-btn-group .btn-crs.plug {
	padding: 20px 35px;
}

.e-btn {
	top: 6px;
	left: -6px;
}

.e-btn:after {
	position: absolute;
	left: 6px;
	top: -6px;
	width: 100%;
	height: 100%;
	background: 0 0;
	border: 1px solid #fff;
	content: '';
	z-index: -1;
}

.e-btn:hover {
	background: #ff937d;
	top: 0;
	left: 0;
}

.e-btn:hover:after {
	left: 0;
	top: 0;
}

.e-btn.s-btn {
	background: #857dff;
	color: #fff;
}

.e-btn.s-btn:after {
	border-color: #292929;
}

.e-btn.s-btn:hover {
	background: #857dff;
}

.e-btn.inner:after {
	border: 1px solid #292929;
}

.line-btn {
	position: relative;
	padding: 0;
	text-transform: capitalize;
	font-size: 18px;
	font-weight: 600;
}

.line-btn:before {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	width: 100%;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.line-btn:after {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	width: 0;
	height: 1px;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.line-btn:hover:before {
	left: auto;
	right: 0;
	width: 0;
}

.line-btn:hover:after {
	width: 100%;
}

.line-btn.job:before {
	display: none;
}

.line-btn.job:after {
	width: 60%;
}

.line-btn.job:hover:after {
	width: 100%;
}

.line-btn.reading-btn {
	color: #292929;
}

.line-btn.reading-btn:before {
	background-color: #292929;
}

.line-btn.reading-btn:after {
	background-color: #292929;
}

.download-btn {
	margin-right: 30px;
	display: inline-block;
	padding: 16px 30px !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.download-btn {
		width: 195px;
	}
}

@media only screen and (max-width: 575px) {
	.download-btn {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

.download-btn .icon {
	margin-right: 10px;
}

.download-btn .content p {
	line-height: 1;
	color: #fff;
	margin-bottom: 5px !important;
	font-size: 12px;
	font-weight: 400;
}

.download-btn .content h5 {
	color: #fff;
	font-weight: 600;
}

.download-btn2 {
	display: inline-block;
	padding: 16px 30px !important;
	background: #fff !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.download-btn2 {
		width: 180px;
	}
}

.download-btn2 .icon {
	margin-right: 10px;
}

.download-btn2 .content p {
	line-height: 1;
	color: #292929;
	margin-bottom: 5px !important;
	font-size: 12px;
	font-weight: 400;
}

.download-btn2 .content h5 {
	color: #292929;
	font-weight: 600;
}

.download-btn2:hover .icon {
	margin-right: 10px;
}

.download-btn2:hover .icon .svg path {
	fill: #fff;
}

.download-btn2:hover .content p {
	color: #fff;
}

.download-btn2:hover .content h5 {
	color: #fff;
}

@media only screen and (max-width: 767px) {
	.cta-btn-group.proto a:not(:last-child) {
		margin-right: 0;
		margin-bottom: 14px;
	}
}

.section-title {
	margin-bottom: 60px;
}

@media only screen and (max-width: 991px) {
	.section-title {
		margin-bottom: 40px;
	}
}

.section-title h2 {
	font-size: 40px;
	line-height: 1.35;
	margin-top: -10px;
	margin-bottom: 10px;
}

.section-title h2.black {
	color: #292929;
}

@media only screen and (max-width: 575px) {
	.section-title h2 {
		font-size: 36px;
	}
}

.section-title p {
	max-width: 660px;
	margin: 0 auto;
}

@media only screen and (max-width: 575px) {
	.section-title p {
		font-size: 16px;
	}
}

.section-title.style-two {
	margin-bottom: 40px;
}

.section-title.crs {
	margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
	.section-title.crs {
		margin-bottom: 10px;
	}
}

.section-title.book h2 {
	font-weight: 600;
}

.section-title.style--two {
	margin-bottom: 24px;
}

.section-title.style--two h2 {
	font-size: 40px;
	margin-bottom: 10px;
}

.section-title.style--two h2.black {
	color: #292929;
}

@media only screen and (max-width: 575px) {
	.section-title.style--two h2 {
		font-size: 36px;
	}
}

.section-title.style--two p {
	font-size: 20px;
	line-height: 1.67;
	margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
	.section-title.style--two p {
		font-size: 16px;
	}
}

.section-title.style--two p b {
	font-weight: 600;
	color: #131313;
}

.section-title.style--three {
	margin-bottom: 42px;
}

.section-title.style--three h2 {
	margin-bottom: 10px;
	font-weight: 600;
}

.section-title.style--three p {
	max-width: 910px;
}

.section-title.style--three p.mxw-640 {
	max-width: 640px;
	margin: 0;
}

.section-title.analytic.m-less {
	margin-bottom: 20px;
}

.section-title.analytic h2 {
	font-size: 46px;
	font-weight: 600;
	margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
	.section-title.analytic h2 {
		font-size: 36px;
	}
}

.section-title.analytic p {
	max-width: 840px;
	font-size: 22px;
}

.section-title.host {
	margin-bottom: 50px;
}

.section-title.host.m-less {
	margin-bottom: 20px;
}

.section-title.host.mb-40 {
	margin-bottom: 40px;
}

.section-title.host h2 {
	font-size: 46px;
	font-weight: 600;
	margin-bottom: 13px;
	line-height: 1.25;
}

@media only screen and (max-width: 575px) {
	.section-title.host h2 {
		font-size: 36px;
	}
}

@media only screen and (max-width: 991px) {
	.section-title br {
		display: none;
	}
}

.section-title.author-newsletter {
	margin-bottom: 35px;
}

.section-title.app2 {
	margin-bottom: 40px;
}

.section-title.app2 h2 {
	font-size: 50px;
	line-height: 1;
}

@media only screen and (max-width: 575px) {
	.section-title.app2 h2 {
		font-size: 36px;
	}
}

.section-title.proto {
	margin-bottom: 40px;
}

.section-title.proto h2 {
	margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
	.section-title.proto h2 {
		font-size: 36px;
	}
}

.section-title.about h2 {
	font-weight: 600;
	color: #292929;
}

.section-title.semi-bold h2 {
	font-weight: 600;
}

.page-title-wrap {
	background-color: #ecf7ff;
	margin-top: 100px;
	padding: 100px 0;
	z-index: 1;
}

.page-title h2 {
	margin-top: -13px;
	margin-bottom: 8px;
	font-size: 50px;
	font-weight: 600;
	line-height: 1.4;
	color: #292929;
}

.page-title ul {
	margin-bottom: -8px;
}

.page-title ul li {
	font-size: 20px;
	font-weight: 400;
	color: #292929;
}

.page-title ul li:not(:last-child) {
	position: relative;
	padding-right: 15px;
	margin-right: 10px;
	color: #292929;
}

.page-title ul li:not(:last-child) a {
	color: #292929;
}

.page-title ul li:not(:last-child):after {
	right: 0;
	position: absolute;
	content: '/';
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.breadcrumb-icon {
	position: absolute;
	left: 5%;
	top: 10%;
	z-index: -1;
}

.bread1 {
	-webkit-animation: banner3Shake 5s linear 0s infinite forwards;
	animation: banner3Shake 5s linear 0s infinite forwards;
}

.bread2 {
	left: 0;
	top: auto;
	bottom: 0;
	-webkit-animation: leftToRight 300s linear 0.3s infinite forwards;
	animation: leftToRight 300s linear 0.3s infinite forwards;
}

.bread3 {
	left: 85%;
	top: 30%;
	animation: spin 80s linear 0.2s infinite reverse forwards;
}

.bread4 {
	left: 30%;
	top: auto;
	bottom: 0;
	-webkit-animation: banner3Shake 15s ease-in 0s infinite forwards;
	animation: banner3Shake 15s ease-in 0s infinite forwards;
}

.widget {
	font-size: 16px;
}

.widget:not(:last-child) {
	margin-bottom: 50px;
}

.widget ul ul {
	padding-left: 10px;
	margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
	.widget ul ul {
		padding-left: 0;
	}
}

.widget .widget-title {
	margin-bottom: 30px;
}

.widget .widget-title h4 {
	font-weight: 500;
}

.widget .widget_title {
	font-size: 20px;
	color: #292929;
	margin-bottom: 20px;
}

.widget .custom-select {
	margin-bottom: 0 !important;
}

.widget .custom-select .select-selected {
	background-color: #fff6f1;
	border: 1px solid transparent;
	font-weight: 400 !important;
}

.widget .custom-select .select-selected.select-arrow-active {
	border-color: transparent !important;
}

.widget .custom-select .select-items {
	background-color: #fffbf9;
}

.widget.widget_categories ul li,
.widget.widget_meta ul li,
.widget.widget_nav_menu ul li {
	position: relative;
	padding-left: 15px;
}

.widget.widget_categories ul li a,
.widget.widget_meta ul li a,
.widget.widget_nav_menu ul li a {
	display: inline-block;
	margin-right: 10px;
}

.widget.widget_categories ul li:after,
.widget.widget_meta ul li:after,
.widget.widget_nav_menu ul li:after {
	position: absolute;
	left: 0;
	top: 12px;
	content: '';
	height: 1px;
	width: 5px;
	background-color: #292929;
}

.widget.widget_categories ul li:not(:last-child),
.widget.widget_meta ul li:not(:last-child),
.widget.widget_nav_menu ul li:not(:last-child) {
	margin-bottom: 18px;
}

.widget.widget_contact .contact-content p {
	margin-bottom: 20px;
}

.widget.widget_contact .contact-content ul li {
	padding-left: 30px;
	position: relative;
	font-size: 14px;
	line-height: 2;
}

.widget.widget_contact .contact-content ul li:not(:last-child) {
	margin-bottom: 5px;
}

.widget.widget_contact .contact-content ul li i {
	position: absolute;
	left: 0;
	line-height: inherit;
}

.widget.widget_service .service-list li {
	line-height: 2;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.widget.widget_service .service-list li a:hover i,
.widget.widget_service .service-list li a:hover svg * {
	fill: #fcbf21;
}

.widget.widget_service .service-list li i {
	font-size: 18px;
}

.widget.widget_service .service-list li i,
.widget.widget_service .service-list li img,
.widget.widget_service .service-list li svg {
	margin-right: 10px;
}

.widget.widget_service .service-list li:not(:last-child) {
	margin-bottom: 24px;
}

.widget.widget_recent_entries ul li {
	padding-bottom: 15px;
	border-bottom: 1px solid #eee;
}

.widget.widget_recent_entries ul li:not(:last-child) {
	margin-bottom: 10px;
}

.widget.widget_recent_entries .post-summary .post-title a {
	color: #292929;
	font-size: 16px;
	line-height: 1.2;
}

.widget.widget_recent_entries .post-summary .posted-on a {
	font-size: 12px;
}

.widget.widget_recent_entries .post-summary .post-excerpt {
	margin-top: 7px;
	font-size: 14px;
}

.widget.widget_recent_entries.has_thumb li .post_thumb {
	min-width: 80px;
	margin-right: 20px;
}

.widget.widget_recent_comments li:not(:last-child) {
	margin-bottom: 20px;
}

.widget.widget_recent_comments li a {
	font-weight: 700;
	color: #292929;
	background-image: -o-linear-gradient(transparent 96%, currentColor 1px);
	background-image: linear-gradient(transparent 96%, currentColor 1px);
	background-repeat: no-repeat;
	background-size: 0 100%;
}

.widget.widget_recent_comments li a:hover {
	background-size: 100% 100%;
}

.widget.widget_newsletter > p {
	margin-bottom: 20px;
	font-weight: 400;
}

.widget.widget_newsletter .newsletter-content p {
	margin-bottom: 25px;
}

.widget.widget_newsletter.style--two .newsletter-content p {
	font-size: 14px;
}

.widget.widget_newsletter.style--two
	.newsletter-content
	.theme-input-group
	button {
	position: static;
	width: 100%;
	margin-top: 16px;
	height: 52px;
}

.widget.widget_newsletter.style--two
	.newsletter-content
	.theme-input-group
	input {
	background-color: #fff;
}

.widget.widget_newsletter.style--two,
.widget.widget_search {
	padding: 0;
	background-color: transparent;
}

.widget.widget_tag_cloud .tagcloud {
	margin: 0 -6px -6px 0;
}

.widget.widget_tag_cloud .tagcloud a {
	display: inline-block;
	padding: 5px 7px;
	background-color: #fff;
	margin: 0 6px 6px 0;
}

.widget.widget_footer_menu .footer_menu li {
	display: block;
}

.widget.widget_footer_menu .footer_menu li:after {
	display: none;
}

.widget.widget_footer_menu .footer_menu li a {
	color: #fff;
	font-weight: 300;
	text-transform: capitalize;
	padding-bottom: 10px;
	display: inline-block;
	position: relative;
}

.widget.widget_footer_menu .footer_menu li a:hover {
	padding-left: 5px;
}

.widget.widget_footer_menu .footer_menu.affiliate li a {
	color: #707070;
}

.widget.widget_footer_menu .footer_menu.analytic li a {
	color: #707070;
}

.widget.widget_footer_menu .footer_menu.crm li a {
	color: #707070;
}

.widget.widget_footer_menu .footer_menu.crs li a {
	color: #707070;
}

.widget.widget_footer_menu .footer_menu.pay li a {
	color: #fff;
}

.widget.widget_footer_menu .footer_menu.plug li a {
	color: #fff;
}

.widget.widget_footer_menu .footer_menu.analytic li a {
	font-weight: 400;
}

.widget.job-info {
	border: 1px solid #eee;
	background-color: #fff;
	padding: 60px;
}

@media only screen and (max-width: 1199px) {
	.widget.job-info {
		padding: 60px 40px;
	}
}

@media only screen and (max-width: 479px) {
	.widget.job-info {
		padding: 40px 30px;
	}
}

.widget.job-info li:not(:last-child) {
	margin-bottom: 30px;
}

.widget.job-info li h3 {
	color: #292929;
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 5px;
}

.widget.job-info li p {
	font-size: 20px;
}

.widget.share {
	border: 1px solid #eee;
	background-color: #fff;
	padding: 24px;
}

.widget.share .social-icon span {
	font-size: 26px;
	font-weight: 500;
	color: #292929;
	margin-right: 10px;
	display: inline-block;
}

@media only screen and (max-width: 479px) {
	.widget.share .social-icon span {
		font-size: 22px;
	}
}

.widget.share .social-icon a {
	color: #707070;
	margin-right: 0;
	background-color: #fff;
}

.widget.share .social-icon a:hover {
	color: #fff;
}

.widget.widget_calendar table {
	width: 100%;
	background-color: #fffbf9;
}

.widget.widget_calendar table caption {
	padding: 5px 10px;
	caption-side: top;
	background-color: #fffbf9;
	font-weight: 700;
	color: #292929;
}

.widget.widget_calendar table th {
	background-color: #fff6f1;
	border: none;
}

.widget.widget_calendar table td {
	background-color: #fff;
	border: 1px solid #fffbf9;
}

.widget.widget_calendar table td,
.widget.widget_calendar table th {
	text-align: center;
	padding: 5px 0;
}

.back-to-top {
	position: fixed;
	opacity: 0;
	visibility: hidden;
	cursor: pointer;
	width: 35px;
	height: 35px;
	-webkit-box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
	box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
	font-size: 16px;
	color: #fff !important;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	line-height: 35px;
	bottom: 50%;
	right: 4%;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition: 0.3s ease-in;
	-o-transition: 0.3s ease-in;
	transition: 0.3s ease-in;
}

.back-to-top.show {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	visibility: visible;
	bottom: 10%;
	z-index: 999;
}

.back-to-top:hover {
	opacity: 0.6;
}

.header {
	min-height: 100px;
}

.header.scrollDown {
	-webkit-animation: fadeInDown 0.5s linear;
	animation: fadeInDown 0.5s linear;
}

.header.scrollUp {
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.header.payment-header .header-main {
	margin-top: 15px;
	-webkit-animation: fadeInUp 0.5s linear 0.9s backwards;
	animation: fadeInUp 0.5s linear 0.9s backwards;
}

.header.payment-header .header-main.sticky {
	margin-top: 0;
}

.header.payment-header .header-main.sticky .header-btn .btn-pay.s-btn,
.header.payment-header .header-main.sticky .header-btn .s-btn.e-btn {
	color: #fff !important;
	background: #fe957b;
}

.header.payment-header .header-main.sticky .header-btn .btn-pay.s-btn:hover,
.header.payment-header .header-main.sticky .header-btn .s-btn.e-btn:hover {
	background: #baf;
	color: #fff;
}

.header.inner-page {
	background: #fff;
}

.header .header-main {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: 100px;
}

.header .header-main.sticky {
	background-color: #fff;
}

.header .header-main.sticky .logo .default-logo {
	display: none;
}

.header .header-main.sticky .logo .sticky-logo {
	display: inline-block;
}

.header .header-main.sticky .main-menu .nav > li {
	position: relative;
}

.header .header-main.sticky .main-menu .nav > li.current-menu-item > a,
.header .header-main.sticky .main-menu .nav > li.current-menu-parent > a,
.header .header-main.sticky .main-menu .nav > li:hover > a {
	color: #5f52dc !important;
}

.header .header-main.sticky .main-menu .nav > li > a {
	color: #292929 !important;
}

.header .header-main.sticky .main-menu .nav > li > a:hover {
	color: #5f52dc !important;
}

.header .header-main .logo {
	line-height: 1;
}

.header .header-main .logo a {
	display: inline-block;
}

.header .header-main .logo .sticky-logo {
	display: none;
}

.header .header-main.style--two.sticky .main-menu .nav > li {
	position: relative;
}

.header
	.header-main.style--two.sticky
	.main-menu
	.nav
	> li.current-menu-item
	> a,
.header
	.header-main.style--two.sticky
	.main-menu
	.nav
	> li.current-menu-parent
	> a,
.header .header-main.style--two.sticky .main-menu .nav > li:hover > a {
	color: #57548d !important;
}

.header .header-main.style--two.sticky .main-menu .nav > li > a {
	color: #292929 !important;
}

.header .header-main.style--two.sticky .main-menu .nav > li > a:hover {
	color: #57548d !important;
}

.header .header-main.style--two .main-menu {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header .header-main.style--two .main-menu .nav {
	margin: 0 auto;
}

.header .header-main.style--two .main-menu .nav > li > a {
	color: #292929;
}

.header .header-main.style--two .main-menu .nav li.current-menu-item > a,
.header .header-main.style--two .main-menu .nav li.current-menu-parent > a,
.header .header-main.style--two .main-menu .nav li:hover > a {
	color: #57548d;
}

.header .header-main.email.sticky .main-menu .nav > li {
	position: relative;
}

.header .header-main.email.sticky .main-menu .nav > li.current-menu-item > a,
.header .header-main.email.sticky .main-menu .nav > li.current-menu-parent > a,
.header .header-main.email.sticky .main-menu .nav > li:hover > a {
	color: #857dff !important;
}

.header .header-main.email.sticky .main-menu .nav > li > a {
	color: #292929 !important;
}

.header .header-main.email.sticky .main-menu .nav > li > a:hover {
	color: #857dff !important;
}

.header .header-main.email.sticky .header-btn .e-btn {
	background: #857dff;
}

.header .header-main.email.sticky .header-btn .e-btn:after {
	border: 1px solid #292929;
}

.header .header-main.email.sticky .header-btn .e-btn:hover {
	background: #857dff;
}

.header .header-main.email.sticky .header-btn .btn-book.line-btn {
	color: #ff937d;
}

.header .header-main.email.sticky .header-btn .btn-book.line-btn:after,
.header .header-main.email.sticky .header-btn .btn-book.line-btn:before {
	background: #ff937d;
}

.header .header-main.email .main-menu {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header .header-main.email .main-menu .nav {
	margin: 0 auto;
}

.header .header-main.email .main-menu .nav > li > a {
	color: #292929;
}

.header .header-main.email .main-menu .nav li.current-menu-parent > a,
.header .header-main.email .main-menu .nav li:hover > a {
	color: #857dff;
}

.header .header-main.email .main-menu .nav li.current-menu-item > a {
	color: #ff937d;
}

.header .header-main.email .main-menu .nav li.has-sub-item:after {
	content: '+';
	position: absolute;
	right: 30px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 1;
	font-size: 30px;
	color: #292929;
}

.header .header-main.email .main-menu .nav li.has-sub-item:hover:after {
	content: '-';
	color: #ff937d;
}

.header .header-main.email .main-menu .nav li ul li:hover > a {
	color: #ff937d;
}

.header .header-main.style--three.sticky .main-menu .nav > li {
	position: relative;
}

.header
	.header-main.style--three.sticky
	.main-menu
	.nav
	> li.current-menu-item
	> a,
.header
	.header-main.style--three.sticky
	.main-menu
	.nav
	> li.current-menu-parent
	> a,
.header .header-main.style--three.sticky .main-menu .nav > li:hover > a {
	color: #5dd9c1 !important;
}

.header .header-main.style--three.sticky .main-menu .nav > li > a:hover {
	color: #5dd9c1 !important;
}

.header .header-main.style--three .main-menu {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header .header-main.style--three .main-menu .nav {
	margin-left: auto;
	margin-right: 26px;
}

.header .header-main.style--three .main-menu .nav > li > a {
	color: #5c6b56;
}

.header .header-main.style--three .main-menu .nav li.current-menu-item > a,
.header .header-main.style--three .main-menu .nav li.current-menu-parent > a,
.header .header-main.style--three .main-menu .nav li:hover > a {
	color: #5dd9c1;
}

.header .header-main.style--four #menu-button span,
.header .header-main.style--four #menu-button span:after,
.header .header-main.style--four #menu-button span:before {
	background: #fff;
}

.header .header-main.style--four.sticky #menu-button span,
.header .header-main.style--four.sticky #menu-button span:after,
.header .header-main.style--four.sticky #menu-button span:before {
	background: #fe957b;
}

.header .header-main.style--four.sticky .main-menu .nav > li {
	position: relative;
}

.header
	.header-main.style--four.sticky
	.main-menu
	.nav
	> li.current-menu-item
	> a,
.header
	.header-main.style--four.sticky
	.main-menu
	.nav
	> li.current-menu-parent
	> a,
.header .header-main.style--four.sticky .main-menu .nav > li:hover > a {
	color: #fe957b !important;
}

.header .header-main.style--four.sticky .main-menu .nav > li > a:hover {
	color: #fe957b !important;
}

.header .header-main.style--four .main-menu {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header .header-main.style--four .main-menu .nav > li > a {
	color: #fff;
}

.header .header-main.style--four .main-menu .nav li.current-menu-item > a,
.header .header-main.style--four .main-menu .nav li.current-menu-parent > a,
.header .header-main.style--four .main-menu .nav li:hover > a {
	color: #fe957b;
}

.header .header-main.ebook #menu-button span,
.header .header-main.ebook #menu-button span:after,
.header .header-main.ebook #menu-button span:before {
	background: #fbc006;
}

.header .header-main.ebook.sticky .main-menu .nav > li.current-menu-item > a,
.header .header-main.ebook.sticky .main-menu .nav > li.current-menu-parent > a,
.header .header-main.ebook.sticky .main-menu .nav > li:hover > a {
	color: #fbc006 !important;
}

.header .header-main.ebook.sticky .main-menu .nav > li > a:hover {
	color: #fbc006 !important;
}

.header .header-main.ebook.sticky .header-btn.book .btn-book {
	background: #fbc006;
	color: #fff;
}

.header .header-main.ebook.sticky .header-btn.book .btn-book:hover {
	background: #205ce2;
	color: #fbc006;
}

.header .header-main.ebook.sticky .header-btn.book .cart-btn .svg g path {
	fill: #205ce2;
}

.header .header-main.ebook.sticky #menu-button span,
.header .header-main.ebook.sticky #menu-button span:after,
.header .header-main.ebook.sticky #menu-button span:before {
	background: #205ce2;
}

.header .header-main.ebook .main-menu {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header .header-main.ebook .main-menu .nav > li > a {
	color: #fff;
}

.header .header-main.ebook .main-menu {
	margin-right: 26px;
}

.header .header-main.ebook .main-menu .nav li.current-menu-item > a,
.header .header-main.ebook .main-menu .nav li.current-menu-parent > a,
.header .header-main.ebook .main-menu .nav li:hover > a {
	color: #fbc006;
}

.header .header-main.plugin #menu-button span,
.header .header-main.plugin #menu-button span:after,
.header .header-main.plugin #menu-button span:before {
	background: #5dd9c1;
}

.header .header-main.plugin.sticky .main-menu .nav > li.current-menu-item > a,
.header .header-main.plugin.sticky .main-menu .nav > li.current-menu-parent > a,
.header .header-main.plugin.sticky .main-menu .nav > li:hover > a {
	color: #5dd9c1 !important;
}

.header .header-main.plugin.sticky .main-menu .nav > li > a:hover {
	color: #5dd9c1 !important;
}

.header .header-main.plugin .main-menu {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header .header-main.plugin .main-menu .nav > li > a {
	color: #fff;
}

.header .header-main.plugin .main-menu {
	margin-right: 26px;
}

.header .header-main.plugin .main-menu .nav li.current-menu-item > a,
.header .header-main.plugin .main-menu .nav li.current-menu-parent > a,
.header .header-main.plugin .main-menu .nav li:hover > a {
	color: #5dd9c1;
}

.header .header-main.management #menu-button span,
.header .header-main.management #menu-button span:after,
.header .header-main.management #menu-button span:before {
	background: #ff4d8c;
}

.header
	.header-main.management.sticky
	.main-menu
	.nav
	> li.current-menu-item
	> a,
.header
	.header-main.management.sticky
	.main-menu
	.nav
	> li.current-menu-parent
	> a,
.header .header-main.management.sticky .main-menu .nav > li:hover > a {
	color: #ff4d8c !important;
}

.header .header-main.management.sticky .main-menu .nav > li > a:hover {
	color: #ff4d8c !important;
}

.header .header-main.management .main-menu {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header .header-main.management .main-menu .nav > li > a {
	color: #292929;
}

.header .header-main.management .main-menu .nav li.current-menu-item > a,
.header .header-main.management .main-menu .nav li.current-menu-parent > a,
.header .header-main.management .main-menu .nav li:hover > a {
	color: #ff4d8c;
}

.header .header-main.analytic.sticky .main-menu .nav > li.current-menu-item > a,
.header
	.header-main.analytic.sticky
	.main-menu
	.nav
	> li.current-menu-parent
	> a,
.header .header-main.analytic.sticky .main-menu .nav > li:hover > a {
	color: #ff5b83 !important;
}

.header .header-main.analytic.sticky .main-menu .nav > li > a:hover {
	color: #ff5b83 !important;
}

.header .header-main.analytic .main-menu {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header .header-main.analytic .main-menu .nav > li > a {
	color: #292929;
}

.header .header-main.analytic .main-menu {
	margin-right: 26px;
}

.header .header-main.analytic .main-menu .nav li.current-menu-item > a,
.header .header-main.analytic .main-menu .nav li.current-menu-parent > a,
.header .header-main.analytic .main-menu .nav li:hover > a {
	color: #ff5b83;
}

.header .header-main.host #menu-button span,
.header .header-main.host #menu-button span:after,
.header .header-main.host #menu-button span:before {
	background: #f7a400;
}

.header .header-main.host.sticky .main-menu .nav > li.current-menu-item > a,
.header .header-main.host.sticky .main-menu .nav > li.current-menu-parent > a,
.header .header-main.host.sticky .main-menu .nav > li:hover > a {
	color: #f7a400 !important;
}

.header .header-main.host.sticky .main-menu .nav > li > a:hover {
	color: #f7a400 !important;
}

.header .header-main.host .main-menu {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header .header-main.host .main-menu .nav > li > a {
	color: #fff;
}

.header .header-main.host .main-menu .nav li.current-menu-item > a,
.header .header-main.host .main-menu .nav li.current-menu-parent > a,
.header .header-main.host .main-menu .nav li:hover > a {
	color: #f7a400;
}

.header .header-main.app {
	margin-top: 20px;
}

@media only screen and (max-width: 991px) {
	.header .header-main.app {
		margin-top: 0;
	}
}

.header .header-main.app #menu-button span,
.header .header-main.app #menu-button span:after,
.header .header-main.app #menu-button span:before {
	background: #8ec9f8;
}

.header .header-main.app.sticky {
	margin-top: 0;
}

.header .header-main.app.sticky .main-menu .nav > li.current-menu-item > a,
.header .header-main.app.sticky .main-menu .nav > li.current-menu-parent > a,
.header .header-main.app.sticky .main-menu .nav > li:hover > a {
	color: #8ec9f8 !important;
}

.header .header-main.app.sticky .main-menu .nav > li > a:hover {
	color: #8ec9f8 !important;
}

.header .header-main.app .main-menu {
	margin-right: 25px;
}

.header .header-main.app .main-menu .nav > li > a {
	color: #292929;
}

.header .header-main.app .main-menu .nav li.current-menu-item > a,
.header .header-main.app .main-menu .nav li.current-menu-parent > a,
.header .header-main.app .main-menu .nav li:hover > a {
	color: #8ec9f8;
}

.header .header-main.app2 {
	margin-top: 20px;
}

@media only screen and (max-width: 991px) {
	.header .header-main.app2 {
		margin-top: 0;
	}
}

.header .header-main.app2 #menu-button span,
.header .header-main.app2 #menu-button span:after,
.header .header-main.app2 #menu-button span:before {
	background: #ff6773;
}

.header .header-main.app2.sticky {
	margin-top: 0;
}

.header .header-main.app2.sticky .main-menu .nav > li.current-menu-item > a,
.header .header-main.app2.sticky .main-menu .nav > li.current-menu-parent > a,
.header .header-main.app2.sticky .main-menu .nav > li:hover > a {
	color: #ff6773 !important;
}

.header .header-main.app2.sticky .main-menu .nav > li > a:hover {
	color: #ff6773 !important;
}

.header .header-main.app2.sticky .btn.white-btn,
.header .header-main.app2.sticky .white-btn.btn-book,
.header .header-main.app2.sticky .white-btn.btn-crm,
.header .header-main.app2.sticky .white-btn.btn-crs,
.header .header-main.app2.sticky .white-btn.btn-pay,
.header .header-main.app2.sticky .white-btn.e-btn,
.header .header-main.app2.sticky .white-btn.m-btn {
	color: #fff;
	position: relative;
	z-index: 1;
	overflow: hidden;
	background: #ff6773;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#ff6773),
		to(#ff896b)
	);
	background: -o-linear-gradient(top, #ff6773 0, #ff896b 100%);
	background: linear-gradient(to bottom, #ff6773 0, #ff896b 100%);
}

.header .header-main.app2.sticky .btn.white-btn:after,
.header .header-main.app2.sticky .white-btn.btn-book:after,
.header .header-main.app2.sticky .white-btn.btn-crm:after,
.header .header-main.app2.sticky .white-btn.btn-crs:after,
.header .header-main.app2.sticky .white-btn.btn-pay:after,
.header .header-main.app2.sticky .white-btn.e-btn:after,
.header .header-main.app2.sticky .white-btn.m-btn:after {
	left: 0;
	top: -100%;
	width: 100%;
	height: 100%;
	content: '';
	position: absolute;
	border-radius: 0 5px 5px 0;
	z-index: -1;
	background: #ff896b;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#ff896b),
		to(#ff6773)
	);
	background: -o-linear-gradient(top, #ff896b 0, #ff6773 100%);
	background: linear-gradient(to bottom, #ff896b 0, #ff6773 100%);
}

.header .header-main.app2.sticky .btn.white-btn:hover,
.header .header-main.app2.sticky .white-btn.btn-book:hover,
.header .header-main.app2.sticky .white-btn.btn-crm:hover,
.header .header-main.app2.sticky .white-btn.btn-crs:hover,
.header .header-main.app2.sticky .white-btn.btn-pay:hover,
.header .header-main.app2.sticky .white-btn.e-btn:hover,
.header .header-main.app2.sticky .white-btn.m-btn:hover {
	color: #fff;
}

.header .header-main.app2.sticky .btn.white-btn:hover:after,
.header .header-main.app2.sticky .white-btn.btn-book:hover:after,
.header .header-main.app2.sticky .white-btn.btn-crm:hover:after,
.header .header-main.app2.sticky .white-btn.btn-crs:hover:after,
.header .header-main.app2.sticky .white-btn.btn-pay:hover:after,
.header .header-main.app2.sticky .white-btn.e-btn:hover:after,
.header .header-main.app2.sticky .white-btn.m-btn:hover:after {
	top: 0;
}

.header .header-main.app2 .main-menu {
	margin-right: 25px;
}

.header .header-main.app2 .main-menu .nav > li > a {
	color: #292929;
}

.header .header-main.app2 .main-menu .nav li.current-menu-item > a,
.header .header-main.app2 .main-menu .nav li.current-menu-parent > a,
.header .header-main.app2 .main-menu .nav li:hover > a {
	color: #ff6773;
}

.header .header-main.proto {
	margin-top: 20px;
}

@media only screen and (max-width: 991px) {
	.header .header-main.proto {
		margin-top: 0;
	}
}

.header .header-main.proto #menu-button span,
.header .header-main.proto #menu-button span:after,
.header .header-main.proto #menu-button span:before {
	background: #ffa68b;
}

.header .header-main.proto.sticky {
	margin-top: 0;
}

.header .header-main.proto.sticky .main-menu .nav > li.current-menu-item > a,
.header .header-main.proto.sticky .main-menu .nav > li.current-menu-parent > a,
.header .header-main.proto.sticky .main-menu .nav > li:hover > a {
	color: #ffa68b !important;
}

.header .header-main.proto.sticky .main-menu .nav > li > a:hover {
	color: #ffa68b !important;
}

.header .header-main.proto .main-menu {
	margin-right: 25px;
}

.header .header-main.proto .main-menu .nav > li > a {
	color: #292929;
}

.header .header-main.proto .main-menu .nav li.current-menu-item > a,
.header .header-main.proto .main-menu .nav li.current-menu-parent > a,
.header .header-main.proto .main-menu .nav li:hover > a {
	color: #ffa68b;
}

.header .header-main .main-menu #menu-button {
	display: none;
	font-size: 28px;
	cursor: pointer;
}

.header .header-main .main-menu .nav li {
	position: relative;
}

.header .header-main .main-menu .nav li.current-menu-item > a,
.header .header-main .main-menu .nav li.current-menu-parent > a,
.header .header-main .main-menu .nav li:hover > a {
	color: #5f52dc;
}

.header .header-main .main-menu .nav li a {
	display: block;
	line-height: 1;
	position: relative;
	z-index: 1;
	text-transform: capitalize;
	font-weight: 400;
}

.header .header-main .main-menu .nav li ul {
	position: absolute;
	left: 0;
	top: -2000px;
	opacity: 0;
	visibility: hidden;
	background-color: #fff;
	width: 270px;
	padding: 24px 0;
	-webkit-transform: translate3d(0, -5px, 0);
	transform: translate3d(0, -5px, 0);
	-webkit-transition: -webkit-transform 0.2s ease-out;
	transition: -webkit-transform 0.2s ease-out;
	-o-transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
	z-index: 9;
}

.header .header-main .main-menu .nav li ul li {
	display: block;
	width: 100%;
	line-height: 1;
	font-size: 18px;
}

.header .header-main .main-menu .nav li ul li:hover > a {
	margin-left: 5px;
	opacity: 0.8;
}

.header .header-main .main-menu .nav li ul li a {
	padding: 13px 40px;
	color: #292929;
}

.header .header-main .main-menu .nav li ul li ul {
	top: 0;
	left: calc(100% + 20px);
}

.header .header-main .main-menu .nav li ul li:hover > ul {
	opacity: 1;
	visibility: visible;
	top: 0;
	left: 100%;
}

.header .header-main .main-menu .nav > li {
	display: inline-block;
}

.header .header-main .main-menu .nav > li > a {
	line-height: 100px;
	position: relative;
	color: #81724d;
	font-size: 20px;
	padding: 0 24px;
}

@media only screen and (max-width: 1199px) {
	.header .header-main .main-menu .nav > li > a {
		padding: 0 10px;
	}
}

.header .header-main .main-menu .nav > li:hover > ul {
	opacity: 1;
	visibility: visible;
	top: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.header .header-main .main-menu .offcanvas-trigger {
	display: inline-block;
	width: 30px;
	cursor: pointer;
}

.header .header-main .main-menu .offcanvas-trigger span {
	display: block;
	height: 3px;
}

.header .header-main .main-menu .offcanvas-trigger span:nth-child(2) {
	margin: 5px 0;
	margin-left: 5px;
}

.header .header-main .main-menu .offcanvas-trigger span:nth-child(3) {
	margin-left: 10px;
}

.header .header-main .main-menu .offcanvas-trigger:hover span {
	margin-left: 0 !important;
}

.header .header-main.price-box.two:not(.sticky) .main-menu #menu-button span,
.header
	.header-main.price-box.two:not(.sticky)
	.main-menu
	#menu-button
	span:after,
.header
	.header-main.price-box.two:not(.sticky)
	.main-menu
	#menu-button
	span:before,
.header .header-main:not(.sticky).text-white .main-menu #menu-button span,
.header .header-main:not(.sticky).text-white .main-menu #menu-button span:after,
.header
	.header-main:not(.sticky).text-white
	.main-menu
	#menu-button
	span:before {
	background-color: #fff;
}

.header
	.header-main.price-box.two:not(.sticky)
	.main-menu
	.nav
	> li.current-menu-item
	> a,
.header
	.header-main.price-box.two:not(.sticky)
	.main-menu
	.nav
	> li.current-menu-parent
	> a,
.header .header-main.price-box.two:not(.sticky) .main-menu .nav > li:hover > a,
.header
	.header-main:not(.sticky).text-white
	.main-menu
	.nav
	> li.current-menu-item
	> a,
.header
	.header-main:not(.sticky).text-white
	.main-menu
	.nav
	> li.current-menu-parent
	> a,
.header .header-main:not(.sticky).text-white .main-menu .nav > li:hover > a {
	opacity: 0.8;
	color: #fff !important;
}

.header
	.header-main.price-box.two:not(.sticky)
	.main-menu
	.offcanvas-trigger
	span,
.header
	.header-main:not(.sticky).text-white
	.main-menu
	.offcanvas-trigger
	span {
	background-color: #fff;
}

.offcanvas-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: #000;
	opacity: 0;
	visibility: hidden;
	z-index: 9998;
}

.offcanvas-overlay.opened {
	opacity: 0.3;
	visibility: visible;
}

#mobile_menu {
	position: fixed;
	width: 400px;
	height: 100vh;
	left: auto;
	right: 0;
	top: 0;
	z-index: 9999;
	background-color: #fff;
	padding: 50px;
	overflow: hidden;
	overflow-y: auto;
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
}

@media only screen and (max-width: 575px) {
	#mobile_menu {
		width: 100%;
	}
}

#mobile_menu.opened {
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}

#mobile_menu .offcanvas-header {
	margin-bottom: 50px;
}

#mobile_menu .offcanvas-header .offcanvas-close {
	cursor: pointer;
}

#mobile_menu .mobile-main-menu .nav li {
	display: block;
}

#mobile_menu .mobile-main-menu .nav li.current-menu-item span.sub-menu a,
#mobile_menu .mobile-main-menu .nav li.current-menu-parent span.sub-menu a {
	color: #292929;
}

#mobile_menu .mobile-main-menu .nav li a {
	display: block;
	color: #81724d;
	font-size: 20px;
	padding: 8px 0;
	text-transform: capitalize;
}

#mobile_menu .mobile-main-menu .nav li .sub-menu {
	padding-left: 20px;
	margin-top: 10px;
}

#mobile_menu .mobile-main-menu .nav li .sub-menu a {
	font-size: 18px;
}

#mobile_menu .mobile-main-menu .nav span.submenu-button {
	width: 48px;
	height: 48px;
	position: absolute;
	right: 30px;
	cursor: pointer;
}

#mobile_menu .mobile-main-menu .nav span.submenu-button:after {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-family: FontAwesome;
	content: '';
}

#mobile_menu .mobile-main-menu .nav span.submenu-button.sub-menu-oppened:after {
	font-family: FontAwesome;
	content: '';
}

#mobile_menu .mobile-main-menu .nav .sub-menu {
	display: none;
}

#mobile_menu .mobile-main-menu .nav .sub-menu.sub-menu-oppened {
	display: block;
}

#mobile_menu .mobile-main-menu.style--two .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.email .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.style--three .nav li a {
	color: #5c6b56;
}

#mobile_menu .mobile-main-menu.style--four .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.ebook .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.plugin .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.management .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.host .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.analytic .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.app .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.app2 .nav li a {
	color: #292929;
}

#mobile_menu .mobile-main-menu.proto .nav li a {
	color: #292929;
}

#menu-button {
	height: 25px;
	float: right;
	z-index: 99999;
	position: relative;
	top: 12px;
	cursor: pointer;
}

#menu-button span {
	position: relative;
}

#menu-button span:after,
#menu-button span:before {
	position: absolute;
	content: '';
}

#menu-button span,
#menu-button span:after,
#menu-button span:before {
	display: block;
	width: 25px;
	height: 2px;
}

#menu-button span::before {
	top: -7px;
}

#menu-button span::after {
	top: 7px;
}

.banner {
	padding-top: 240px;
	padding-bottom: 140px;
	position: relative;
	background-size: cover;
	z-index: 1;
}

@media only screen and (max-width: 1199px) {
	.banner {
		padding-top: 180px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 767px) {
	.banner {
		padding-top: 150px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 479px) {
	.banner {
		padding-top: 120px;
		padding-bottom: 50px;
	}
}

@media only screen and (max-width: 991px) {
	.banner .banner-content {
		margin-bottom: 60px;
	}
}

.banner .banner-content h1 {
	font-size: 60px;
	font-weight: 600;
	line-height: 1.17;
	color: #fff;
	margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
	.banner .banner-content h1 {
		font-size: 50px;
	}

	.banner .banner-content h1 br {
		display: none;
	}
}

.banner .banner-content p {
	margin-bottom: 44px;
}

.banner.cource-banner {
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(white),
		color-stop(20%, rgba(228, 255, 239, 0.8)),
		to(rgba(228, 255, 239, 0))
	);
	background: -o-linear-gradient(
		top,
		#fff 0,
		rgba(228, 255, 239, 0.8) 20%,
		rgba(228, 255, 239, 0) 100%
	);
	background: linear-gradient(
		to bottom,
		#fff 0,
		rgba(228, 255, 239, 0.8) 20%,
		rgba(228, 255, 239, 0) 100%
	);
	padding-bottom: 100px;
}

.banner.cource-banner .banner-content .content {
	margin-bottom: 34px;
}

@media only screen and (max-width: 1199px) {
	.banner.cource-banner .banner-content {
		margin-bottom: 60px;
	}
}

.banner.cource-banner .banner-content h1 {
	color: #292929;
}

.banner.cource-banner .banner-content p {
	color: #5c6b56;
	margin-bottom: 34px;
}

.banner.cource-banner .banner-right {
	position: relative;
	z-index: 1;
}

.banner.cource-banner .banner-right .cbat {
	position: absolute;
	left: -75px;
	top: -60px;
	z-index: -1;
}

@media only screen and (max-width: 991px) {
	.banner.cource-banner .banner-right .cbat {
		display: none;
	}
}

.banner.cource-banner .banner-right .cbab {
	position: absolute;
	right: 0;
	bottom: -70px;
	z-index: -1;
}

@media only screen and (max-width: 991px) {
	.banner.cource-banner .banner-right .cbab {
		display: none;
	}
}

@media only screen and (max-width: 991px) {
	.banner.cource-banner .banner-right .course-b {
		margin-bottom: 50px;
	}
}

.banner.style--one {
	padding: 295px 0;
	background: #fff5db;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.banner.style--one {
		padding: 250px 0;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.style--one {
		padding-top: 200px;
		padding-bottom: 100px;
	}
}

@media only screen and (max-width: 991px) {
	.banner.style--one {
		padding-top: 150px;
		padding-bottom: 40px;
	}
}

@media only screen and (max-width: 575px) {
	.banner.style--one {
		padding-bottom: 20px;
	}
}

.banner.style--one .banner-content h1 {
	font-size: 70px;
	color: #292929;
	margin-bottom: 22px;
	line-height: 1.15;
	font-weight: 700;
}

@media only screen and (max-width: 1199px) {
	.banner.style--one .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 575px) {
	.banner.style--one .banner-content h1 {
		font-size: 50px;
	}
}

.banner.style--one .banner-img .b-thumb {
	right: 0;
	top: 80px;
	position: absolute;
	max-width: 56%;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.banner.style--one .banner-img .b-thumb {
		top: 50%;
		margin-top: -349px;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.style--one .banner-img .b-thumb {
		top: 50%;
		margin-top: -220px;
	}
}

@media only screen and (max-width: 991px) {
	.banner.style--one .banner-img {
		display: none;
	}
}

.banner.style--two {
	padding-top: 240px;
	padding-bottom: 140px;
}

@media only screen and (max-width: 1199px) {
	.banner.style--two {
		padding-top: 180px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 767px) {
	.banner.style--two {
		padding-top: 150px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.style--two {
		padding-top: 120px;
		padding-bottom: 50px;
	}
}

.banner.style--two .banner-content {
	-webkit-animation: fadeInLeft 0.6s linear 1.5s backwards;
	animation: fadeInLeft 0.6s linear 1.5s backwards;
}

.banner.style--two .banner-content.crm h1 {
	margin-bottom: 25px;
}

.banner.style--two .banner-content h1 {
	font-size: 60px;
	margin-bottom: 14px;
	line-height: 1.3;
	font-weight: 700;
}

@media only screen and (max-width: 1199px) {
	.banner.style--two .banner-content h1 {
		font-size: 44px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.style--two .banner-content h1 {
		font-size: 42px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	.banner.style--two .banner-content h1 br {
		display: none;
	}
}

.banner.style--two .crm-bg {
	position: absolute;
	right: 0;
	top: 0;
	-webkit-animation: bg-scale 0.6s linear 0.3s backwards;
	animation: bg-scale 0.6s linear 0.3s backwards;
	width: 58%;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.banner.style--two .crm-bg {
		width: 62%;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.style--two .crm-bg {
		width: 64%;
	}
}

@media only screen and (max-width: 991px) {
	.banner.style--two .crm-bg {
		width: auto;
	}
}

.banner.style--two .banner-img .b-thumb {
	-webkit-animation: fadeInRight 0.6s linear 0.9s backwards;
	animation: fadeInRight 0.6s linear 0.9s backwards;
}

@media only screen and (max-width: 1199px) {
	.banner.style--two .banner-img .b-thumb {
		margin-bottom: 60px;
	}
}

.banner.style--two.plugins {
	z-index: 2;
	padding-top: 200px;
	padding-bottom: 140px;
}

@media only screen and (max-width: 991px) {
	.banner.style--two.plugins {
		padding-top: 140px;
	}
}

.banner.style--two.plugins .plug-banner-shape {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 160px;
	z-index: 0;
}

.banner.style--two.plugins .banner-content {
	position: relative;
}

.banner.style--two.plugins .banner-content h1 {
	font-size: 70px;
	line-height: 1;
	margin-bottom: 14px;
}

@media only screen and (max-width: 575px) {
	.banner.style--two.plugins .banner-content h1 {
		font-size: 50px;
	}
}

.banner.style--two.plugins .banner-img {
	position: relative;
	margin-bottom: -140px;
}

@media only screen and (max-width: 991px) {
	.banner.style--two.plugins .banner-img {
		margin-bottom: 0;
	}
}

.banner.style--two.management {
	padding-top: 150px;
	padding-bottom: 150px;
}

@media only screen and (max-width: 1199px) {
	.banner.style--two.management {
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.style--two.management {
		padding-top: 120px;
		padding-bottom: 0;
	}
}

.banner.style--two.management .banner-content {
	-webkit-animation: fadeInLeft2 0.6s linear 1.5s backwards;
	animation: fadeInLeft2 0.6s linear 1.5s backwards;
}

.banner.style--two.management .banner-content .content {
	margin-bottom: 34px;
}

.banner.style--two.management .banner-content h1 {
	font-size: 48px;
	line-height: 1.2;
	font-weight: 600;
}

@media only screen and (max-width: 1199px) {
	.banner.style--two.management .banner-content h1 {
		font-size: 40px;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.style--two.management .banner-content h1 br {
		display: none;
	}
}

.banner.style--two.management .banner-content p {
	margin-bottom: 34px;
}

.banner.style--two.management .crm-bg {
	-webkit-animation: bg-scale 0.6s linear 0.3s backwards;
	animation: bg-scale 0.6s linear 0.3s backwards;
	width: 64%;
}

@media only screen and (max-width: 991px) {
	.banner.style--two.management .crm-bg {
		width: 100%;
	}
}

@media only screen and (max-width: 575px) {
	.banner.style--two.management .crm-bg {
		display: none;
	}
}

.banner.style--two.management .banner-img .b-thumb {
	-webkit-animation: fadeInRight2 0.6s linear 0.9s backwards;
	animation: fadeInRight2 0.6s linear 0.9s backwards;
	padding-left: 50px;
}

@media only screen and (max-width: 1199px) {
	.banner.style--two.management .banner-img .b-thumb {
		padding-left: 0;
	}
}

.banner.email {
	min-height: 900px;
	padding-top: 140px;
	padding-bottom: 30px;
}

.banner.email:after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: #857dff;
	z-index: -1;
	border-radius: 50% 0 0 50%;
	-webkit-transform-origin: right center;
	-ms-transform-origin: right center;
	transform-origin: right center;
	-webkit-animation: wide 1s linear 0.3s backwards;
	animation: wide 1s linear 0.3s backwards;
}

@media only screen and (max-width: 991px) {
	.banner.email:after {
		display: none;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1680px) {
	.banner.email {
		min-height: 800px;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.email {
		min-height: 700px;
		padding-top: 180px;
	}
}

@media only screen and (max-width: 991px) {
	.banner.email {
		min-height: auto;
		background-color: #fff;
		padding-bottom: 0;
	}
}

@media only screen and (max-width: 575px) {
	.banner.email {
		padding-top: 140px;
	}
}

.banner.email .banner-content {
	-webkit-animation: fadeInLeft2 0.7s linear 0.7s backwards;
	animation: fadeInLeft2 0.7s linear 0.7s backwards;
}

.banner.email .banner-content h1 {
	font-size: 60px;
	margin-bottom: 27px;
	line-height: 1.17;
	font-weight: 600;
	color: #292929;
}

@media only screen and (max-width: 1199px) {
	.banner.email .banner-content h1 {
		font-size: 44px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.email .banner-content h1 {
		font-size: 34px;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	.banner.email .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 991px) {
	.banner.email .banner-content {
		margin-bottom: 60px;
	}
}

.banner.email .email-bg {
	position: absolute;
	left: 0;
	top: 0;
	bottom: -1px;
	width: 100%;
	height: 100%;
}

@media only screen and (max-width: 991px) {
	.banner.email .email-bg {
		display: none;
	}
}

.banner.email .banner-img {
	position: relative;
	z-index: 1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.banner.email .banner-img {
		height: 500px;
	}
}

.banner.email .banner-img .email-thumb {
	position: relative;
	z-index: -1;
	right: -130px;
	bottom: -40px;
	-webkit-animation: fadeInUp10 0.7s linear 0.5s backwards;
	animation: fadeInUp10 0.7s linear 0.5s backwards;
}

@media only screen and (min-width: 1200px) and (max-width: 1680px) {
	.banner.email .banner-img .email-thumb {
		padding-left: 20px;
		right: -110px;
		bottom: 0;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.email .banner-img .email-thumb {
		padding-left: 70px;
		right: -40px;
		bottom: 0;
	}
}

@media only screen and (max-width: 991px) {
	.banner.email .banner-img .email-thumb {
		padding-left: 0;
		right: 0;
		position: relative;
	}
}

.banner.email.plugins {
	z-index: 2;
	padding-top: 200px;
	padding-bottom: 140px;
}

@media only screen and (max-width: 991px) {
	.banner.email.plugins {
		padding-top: 140px;
	}
}

.banner.email.plugins .plug-banner-shape {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 160px;
	z-index: 0;
}

.banner.email.plugins .banner-content {
	position: relative;
}

.banner.email.plugins .banner-img {
	position: relative;
	margin-bottom: -140px;
}

@media only screen and (max-width: 991px) {
	.banner.email.plugins .banner-img {
		margin-bottom: 0;
	}
}

.banner.payment-banner {
	padding-top: 230px;
	padding-bottom: 435px;
	position: relative;
}

.banner.payment-banner .payment-banner-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: -50%;
	z-index: -1;
	background: #9578fa;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#9578fa),
		to(#6449d6)
	);
	background: -o-linear-gradient(top, #9578fa 0, #6449d6 100%);
	background: linear-gradient(to bottom, #9578fa 0, #6449d6 100%);
	-webkit-animation: zoomDown 0.6s linear 0.3s backwards;
	animation: zoomDown 0.6s linear 0.3s backwards;
	border-radius: 0 0 50% 50%;
}

@media only screen and (max-width: 991px) {
	.banner.payment-banner .payment-banner-bg {
		border-radius: 0;
		-webkit-animation: none;
		animation: none;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.banner.payment-banner {
		padding-top: 180px;
		padding-bottom: 300px;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.payment-banner {
		padding-top: 140px;
		padding-bottom: 100px;
	}
}

@media only screen and (max-width: 991px) {
	.banner.payment-banner {
		padding-bottom: 180px;
	}
}

.banner.payment-banner .pay-banner-shape {
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 100%;
	height: 100%;
}

@media only screen and (max-width: 767px) {
	.banner.payment-banner .pay-banner-shape {
		display: none;
	}
}

.banner.payment-banner .banner-content {
	-webkit-animation: fadeInUp 0.8s linear 1.4s backwards;
	animation: fadeInUp 0.8s linear 1.4s backwards;
}

.banner.payment-banner .banner-content .content h1 {
	color: #fff;
	font-size: 60px;
	margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
	.banner.payment-banner .banner-content .content h1 {
		font-size: 50px;
	}
}

.banner.payment-banner .banner-content .content p {
	max-width: 100%;
	margin-bottom: 30px;
}

.banner.book {
	padding-top: 240px;
	border-bottom-right-radius: 200px;
}

@media only screen and (max-width: 991px) {
	.banner.book {
		padding-top: 180px;
	}
}

@media only screen and (max-width: 767px) {
	.banner.book {
		padding-top: 140px;
		border-bottom-right-radius: 100px;
	}
}

@media only screen and (max-width: 991px) {
	.banner.book .banner-content {
		margin-bottom: 60px;
	}
}

.banner.book .banner-content .doller {
	font-weight: 500;
	font-size: 36px;
	margin-bottom: 18px;
}

.banner.book .banner-content h1 {
	font-size: 70px;
	font-weight: 600;
	color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
	.banner.book .banner-content h1 {
		font-size: 60px;
	}

	.banner.book .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 575px) {
	.banner.book .banner-content h1 {
		font-size: 50px;
	}

	.banner.book .banner-content h1 br {
		display: none;
	}
}

.banner.book .banner-content .star-rating h4 {
	font-size: 22px;
	display: inline-block;
	font-weight: 500;
	margin-right: 26px;
}

.banner.book .banner-content .star-rating i {
	font-size: 16px;
}

.banner.analytic {
	margin-bottom: 140px;
	padding-top: 220px;
}

@media only screen and (max-width: 991px) {
	.banner.analytic {
		margin-bottom: 80px;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.analytic {
		padding-top: 180px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 767px) {
	.banner.analytic {
		padding-top: 150px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.analytic {
		padding-top: 120px;
		padding-bottom: 50px;
	}
}

@media only screen and (max-width: 991px) {
	.banner.analytic {
		background-color: #fff;
		background-color: -moz-linear-gradient(left, #fff 0, #dbf0ff 100%);
		background-color: -webkit-linear-gradient(left, #fff 0, #dbf0ff 100%);
		background-color: linear-gradient(to right, #fff 0, #dbf0ff 100%);
	}
}

.banner.analytic .analytic-shape {
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}

@media only screen and (max-width: 991px) {
	.banner.analytic .analytic-shape {
		display: none;
	}
}

@media only screen and (max-width: 991px) {
	.banner.analytic .banner-content {
		margin-bottom: 60px;
	}
}

.banner.analytic .banner-content h1 {
	font-size: 70px;
	font-size: 600;
	line-height: 1.17;
	margin-bottom: 24px;
}

@media only screen and (max-width: 575px) {
	.banner.analytic .banner-content h1 {
		font-size: 50px;
	}

	.banner.analytic .banner-content h1 br {
		display: none;
	}
}

.banner.analytic .banner-content p {
	font-size: 22px;
	margin-bottom: 44px;
	color: #292929;
}

.banner.analytic .banner-content p.info {
	font-size: 16px;
	font-weight: 400;
	margin-top: 15px;
}

.banner.hosting {
	padding-top: 325px;
	padding-bottom: 366px;
	background-size: cover;
	background-position: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.banner.hosting {
		padding-top: 280px;
		padding-bottom: 315px;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.hosting {
		padding-top: 180px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 767px) {
	.banner.hosting {
		padding-top: 150px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.hosting {
		padding-top: 120px;
		padding-bottom: 50px;
	}
}

.banner.hosting .banner-content {
	padding-right: 118px;
	margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
	.banner.hosting .banner-content {
		padding-right: 0;
	}
}

.banner.hosting .banner-content h1 {
	margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.banner.hosting .banner-content h1 {
		font-size: 53px;
	}

	.banner.hosting .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.hosting .banner-content h1 {
		font-size: 54px;
	}

	.banner.hosting .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.banner.hosting .banner-content h1 {
		font-size: 50px;
	}
}

.banner.hosting .banner-content p {
	margin-bottom: 40px;
}

.banner.pos {
	padding-top: 220px;
	padding-bottom: 200px;
}

@media only screen and (max-width: 991px) {
	.banner.pos {
		margin-bottom: 60px;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.pos {
		padding-top: 180px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 767px) {
	.banner.pos {
		padding-top: 150px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.pos {
		padding-top: 120px;
		padding-bottom: 50px;
	}
}

.banner.pos .pos-banner-bg {
	background-color: #f3fff7;
	position: absolute;
	width: 35%;
	height: 100%;
	right: 0;
	top: 0;
}

@media only screen and (max-width: 991px) {
	.banner.pos .pos-banner-bg {
		width: 100%;
	}
}

@media only screen and (max-width: 991px) {
	.banner.pos .banner-content {
		margin-bottom: 60px;
	}
}

.banner.pos .banner-content h1 {
	font-size: 70px;
	color: #292929;
	margin-bottom: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.banner.pos .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.pos .banner-content h1 {
		font-size: 60px;
	}
}

@media only screen and (max-width: 767px) {
	.banner.pos .banner-content h1 {
		font-size: 50px;
	}
}

@media only screen and (max-width: 379px) {
	.banner.pos .banner-content h1 {
		font-size: 44px;
	}
}

.banner.pos .banner-content p {
	margin-bottom: 44px;
}

.banner.app {
	padding-top: 180px;
	padding-bottom: 160px;
}

@media only screen and (max-width: 1199px) {
	.banner.app {
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 767px) {
	.banner.app {
		padding-top: 150px;
		padding-bottom: 80px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.app {
		padding-top: 120px;
		padding-bottom: 50px;
	}
}

.banner.app .pos-banner-bg {
	background-color: #f3fff7;
	position: absolute;
	width: 35%;
	height: 100%;
	right: 0;
	top: 0;
}

@media only screen and (max-width: 991px) {
	.banner.app .pos-banner-bg {
		width: 100%;
	}
}

@media only screen and (max-width: 991px) {
	.banner.app .banner-content {
		margin-bottom: 60px;
	}
}

.banner.app .banner-content h1 {
	font-size: 80px;
	color: #292929;
	line-height: 1.1;
	margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.banner.app .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 1199px) {
	.banner.app .banner-content h1 {
		font-size: 76px;
	}

	.banner.app .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.banner.app .banner-content h1 {
		font-size: 48px;
	}
}

.banner.app .banner-content p {
	margin-bottom: 34px;
}

.banner.app2 {
	background-color: #f5f8ff;
	padding-top: 130px;
	padding-bottom: 80px;
}

@media only screen and (max-width: 1199px) {
	.banner.app2 {
		padding-top: 180px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.app2 {
		padding-top: 120px;
		padding-bottom: 50px;
	}
}

.banner.app2 .banner-content {
	-webkit-animation: fadeInUp10 0.7s ease-in forwards;
	animation: fadeInUp10 0.7s ease-in forwards;
	padding-right: 120px;
}

.banner.app2 .banner-content .content {
	margin-bottom: 34px;
}

@media only screen and (min-width: 991px) and (max-width: 1500px) {
	.banner.app2 .banner-content {
		padding-right: 0;
	}
}

@media only screen and (max-width: 991px) {
	.banner.app2 .banner-content {
		margin-bottom: 60px;
		padding-right: 0;
	}
}

.banner.app2 .banner-content .star-rating {
	font-size: 16px;
}

.banner.app2 .banner-content .star-rating span {
	font-weight: 300;
}

.banner.app2 .banner-content h1 {
	font-size: 60px;
	color: #292929;
	line-height: 1.18;
	margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.banner.app2 .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 991px) {
	.banner.app2 .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.banner.app2 .banner-content h1 {
		font-size: 48px;
	}
}

.banner.app2 .banner-content p {
	margin-bottom: 25px;
}

.banner.app2 .banner-img {
	padding-right: 50px;
	-webkit-animation: rotate 0.7s ease-in forwards;
	animation: rotate 0.7s ease-in forwards;
}

@media only screen and (max-width: 991px) {
	.banner.app2 .banner-img {
		padding-right: 0;
	}
}

.banner.proto {
	padding-top: 150px;
	padding-bottom: 150px;
	position: relative;
	z-index: 1;
}

@media only screen and (max-width: 1199px) {
	.banner.proto {
		padding-top: 180px;
		padding-bottom: 120px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.proto {
		padding-top: 120px;
		padding-bottom: 50px;
	}
}

.banner.proto .proto-bg {
	left: 10px;
	top: 0;
	position: absolute;
	z-index: -1;
	height: 100%;
}

@media only screen and (max-width: 991px) {
	.banner.proto .proto-bg {
		left: 0;
	}
}

.banner.proto .banner-content {
	-webkit-animation: fadeInUp10 1.1s linear 0s 1 forwards;
	animation: fadeInUp10 1.1s linear 0s 1 forwards;
}

.banner.proto .banner-content h1 {
	font-size: 60px;
	line-height: 1.18;
	margin-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.banner.proto .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 991px) {
	.banner.proto .banner-content h1 br {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.banner.proto .banner-content h1 {
		font-size: 48px;
	}
}

@media only screen and (max-width: 479px) {
	.banner.proto .banner-content h1 {
		font-size: 40px;
	}
}

.banner.proto .banner-content p {
	margin-bottom: 25px;
}

.banner.proto .banner-img {
	padding-left: 35px;
}

@media only screen and (max-width: 991px) {
	.banner.proto .banner-img {
		padding-left: 0;
	}
}

.banner.proto .banner-img img {
	max-width: none;
}

@media only screen and (max-width: 991px) {
	.banner.proto .banner-img img {
		max-width: 100%;
	}
}

.banner-content {
	position: relative;
	z-index: 2;
}

.banner-content h1 {
	font-size: 50px;
	font-weight: 600;
	color: #292929;
	text-transform: capitalize;
	margin-bottom: 8px;
}

.banner-content .content {
	margin-bottom: 44px;
}

.banner-content .content P {
	max-width: 560px;
	font-weight: 300;
}

.banner-content .content P.affiliate {
	color: #81724d;
}

.banner-content .person {
	margin-bottom: 10px;
	display: inline-block;
}

.banner-content .person span {
	color: #5dd9c1;
	font-weight: 600;
}

.star-rating {
	margin-bottom: 6px;
}

.star-rating span {
	display: inline-block;
	margin-left: 5px;
}

.star-rating.mb12 {
	margin-bottom: 12px;
}

.pay-img-wrap {
	-webkit-animation: fadeInUp 0.8s linear 1.4s backwards;
	animation: fadeInUp 0.8s linear 1.4s backwards;
	position: relative;
	margin-top: -400px;
	z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.pay-img-wrap {
		margin-top: -230px;
	}
}

@media only screen and (max-width: 1199px) {
	.pay-img-wrap {
		margin-top: -100px;
	}
}

@media only screen and (max-width: 991px) {
	.pay-img-wrap {
		margin-top: -180px;
	}
}

.course-info {
	text-align: center;
	margin-bottom: 30px;
}

.course-info h3 {
	font-size: 30px;
	font-weight: 500;
	color: #292929;
	text-transform: capitalize;
	margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
	.course-info h3 {
		font-size: 28px;
	}
}

.course-info p {
	text-transform: capitalize;
	color: #5c6b56;
}

.app-circle,
.app-hexa,
.app-line,
.app-triangle {
	position: absolute;
	z-index: -1;
}

.app-line {
	left: 7%;
	top: 28%;
	-webkit-animation: levitate 15s linear alternate backwards;
	animation: levitate 15s linear alternate backwards;
}

.app-circle {
	left: 2%;
	top: 53%;
	-webkit-animation: zoomDown 3s linear 0s infinite both;
	animation: zoomDown 3s linear 0s infinite both;
}

.app-triangle {
	left: 32%;
	top: 16%;
	-webkit-animation: spin 180s linear 1s infinite alternate both;
	animation: spin 180s linear 1s infinite alternate both;
}

.app-hexa {
	left: 38%;
	top: 76%;
	-webkit-animation: spin 10s linear infinite both;
	animation: spin 10s linear infinite both;
}

.single-blog-item {
	position: relative;
	margin-bottom: 50px;
}

.single-blog-item .blog-image img {
	display: block;
	width: 100%;
}

.single-blog-item .blog-content {
	margin-top: 15px;
}

.single-blog-item .blog-content .blog-meta a {
	font-size: 14px;
}

.single-blog-item .blog-content h3 {
	line-height: 1.25;
	margin-bottom: 10px;
	margin-top: 10px;
	color: #292929;
}

.single-blog-item .blog-content p {
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 0;
}

.post-details {
	font-size: 16px;
}

.post-details figcaption {
	margin-top: 10px;
}

.post-details .entry-header .entry-thumbnail {
	margin-bottom: 50px;
}

.post-details .entry-header .entry-title {
	font-size: 36px;
	line-height: 1.28;
	margin-bottom: 5px;
	color: #292929;
}

.post-details .entry-header .entry-meta {
	margin-bottom: 42px;
}

.post-details .entry-header .entry-meta li:not(:last-child) {
	margin-right: 50px;
}

.post-details .entry-header .entry-meta li span {
	font-weight: 700;
	margin-right: 7px;
	font-size: 16px;
}

.post-details .entry-header .entry-meta li a {
	font-size: 14px;
}

.post-details .entry-categories ul li:first-child,
.post-details .entry-tags ul li:first-child {
	color: #292929;
	font-size: 16px;
	font-weight: 700;
	margin-right: 5px;
}

.post-details .entry-categories ul li a,
.post-details .entry-tags ul li a {
	font-size: 14px;
	line-height: 1.57;
}

.post-details .entry-categories ul li a:not(:last-child):after,
.post-details .entry-tags ul li a:not(:last-child):after {
	content: ',';
}

.comment-content h1,
.comment-content h2,
.comment-content h3,
.comment-content h4,
.comment-content h5,
.comment-content h6,
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6,
.page--content h1,
.page--content h2,
.page--content h3,
.page--content h4,
.page--content h5,
.page--content h6 {
	line-height: 1.5;
	margin-bottom: 15px;
	color: #292929;
}

.comment-content p,
.entry-content p,
.page--content p {
	margin-bottom: 30px;
	color: #292929;
}

.comment-content table,
.entry-content table,
.page--content table {
	margin-bottom: 50px;
}

.comment-content select,
.entry-content select,
.page--content select {
	margin-bottom: 50px;
}

.comment-content > :last-child,
.entry-content > :last-child,
.page--content > :last-child {
	margin-bottom: 0;
}

.comment-content a,
.entry-content a,
.page--content a {
	color: #292929;
	font-weight: 700;
	font-size: 14px;
}

.comment-content a:hover,
.entry-content a:hover,
.page--content a:hover {
	text-decoration: none;
}

.comment-content a i,
.entry-content a i,
.page--content a i {
	margin-right: 5px;
}

.post-author {
	padding: 40px 0;
}

.post-author .author-thumb {
	margin-right: 30px;
}

.post-author .author-info h4 {
	font-size: 18px;
	margin-bottom: 5px;
	color: #292929;
}

.post-author .author-info p {
	font-size: 16px;
}

.post-title {
	color: #292929;
}

.post-navigation .nav-link {
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
	max-width: 50%;
}

.post-navigation .nav-link .nav-title {
	display: block;
	font-size: 14px;
	font-weight: 700;
	opacity: 0.5;
	margin-bottom: 17px;
	color: #292929;
}

.post-navigation .nav-link .nav-title i {
	margin-right: 10px;
}

.post-navigation .nav-link .nav-title.text-right i {
	margin-left: 10px;
}

.post-navigation .nav-link .posted-on {
	margin: 10px 0 2px;
	display: block;
	font-size: 14px;
}

.post-navigation .nav-link:hover .nav-title {
	opacity: 1;
}

.related-post h4 {
	color: #292929;
	margin-bottom: 30px;
}

.comments-area .comments-title {
	margin-bottom: 25px;
	color: #292929;
}

.comments-area ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.comments-area .comment .single-comment {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid #e2e2e2;
}

@media only screen and (max-width: 479px) {
	.comments-area .comment .single-comment {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.comments-area .comment .single-comment .comment-author-image {
	min-width: 65px;
	margin-right: 30px;
	border-radius: 50%;
	overflow: hidden;
}

@media only screen and (max-width: 479px) {
	.comments-area .comment .single-comment .comment-author-image {
		margin-right: 0;
		margin-bottom: 30px;
	}
}

.comments-area
	.comment
	.single-comment:not(.trackback):not(.pingback)
	.comment-content {
	max-width: calc(100% - 95px);
	width: 100%;
}

@media only screen and (max-width: 479px) {
	.comments-area
		.comment
		.single-comment:not(.trackback):not(.pingback)
		.comment-content {
		max-width: 100%;
	}
}

.comments-area .comment .single-comment .comment-content .author_name {
	margin-right: 10px;
	margin-bottom: 0;
	font-size: 16px;
}

.comments-area .comment .single-comment .comment-content .commented-on {
	font-size: 12px;
}

.comments-area .comment .single-comment .comment-content p {
	margin: 7px 0 5px;
	font-size: 16px;
	color: #707070;
	font-weight: 400;
}

.comments-area .comment ul.children {
	margin-left: 95px;
}

@media only screen and (max-width: 479px) {
	.comments-area .comment ul.children {
		margin-left: 20px;
	}
}

@media only screen and (max-width: 991px) {
	.comment-respond {
		margin-bottom: 60px;
	}
}

.comment-respond .comment-reply-title {
	margin-bottom: 20px;
	color: #292929;
}

.comment-respond .comment-form .comment-notes {
	margin-bottom: 30px;
	font-size: 16px;
}

.comment-content .comment-respond .comment-form select,
.comment-respond .comment-form .comment-content select,
.comment-respond .comment-form .entry-content select,
.comment-respond .comment-form .page--content select,
.comment-respond .comment-form .theme-input-style,
.entry-content .comment-respond .comment-form select,
.page--content .comment-respond .comment-form select {
	margin-bottom: 35px;
}

.comment-respond .comment-form label {
	margin-bottom: 10px;
}

.comment-respond .comment-form .custom-checkbox {
	margin-bottom: 25px;
}

.comment-respond .comment-form .custom-checkbox label {
	font-size: 16px;
}

@media only screen and (max-width: 991px) {
	.responsive_table thead {
		display: none;
	}

	.responsive_table tbody tr {
		display: block;
	}

	.responsive_table tbody tr:not(:last-child) {
		margin-bottom: 50px;
	}

	.responsive_table tbody td {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}

.labelText {
	color: #292929;
	font-size: 18px;
	font-weight: 700;
}

.footer {
	padding-top: 98px;
	padding-bottom: 50px;
}

.footer .theme-input-group input:placeholder {
	color: #707070 !important;
}

.footer
	.theme-input-group.affiliate
	input[type='email']::-webkit-input-placeholder,
.footer
	.theme-input-group.course
	input[type='email']::-webkit-input-placeholder,
.footer
	.theme-input-group.error404
	input[type='email']::-webkit-input-placeholder,
.footer
	.theme-input-group.style--four
	input[type='email']::-webkit-input-placeholder,
.footer
	.theme-input-group.style--two.analytic
	input[type='email']::-webkit-input-placeholder {
	color: #fff !important;
}

.footer .theme-input-group.affiliate input[type='email']::-moz-placeholder,
.footer .theme-input-group.course input[type='email']::-moz-placeholder,
.footer .theme-input-group.error404 input[type='email']::-moz-placeholder,
.footer .theme-input-group.style--four input[type='email']::-moz-placeholder,
.footer
	.theme-input-group.style--two.analytic
	input[type='email']::-moz-placeholder {
	color: #fff !important;
}

.footer .theme-input-group.affiliate input[type='email']:-ms-input-placeholder,
.footer .theme-input-group.course input[type='email']:-ms-input-placeholder,
.footer .theme-input-group.error404 input[type='email']:-ms-input-placeholder,
.footer
	.theme-input-group.style--four
	input[type='email']:-ms-input-placeholder,
.footer
	.theme-input-group.style--two.analytic
	input[type='email']:-ms-input-placeholder {
	color: #fff !important;
}

.footer .theme-input-group.affiliate input[type='email']::-ms-input-placeholder,
.footer .theme-input-group.course input[type='email']::-ms-input-placeholder,
.footer .theme-input-group.error404 input[type='email']::-ms-input-placeholder,
.footer
	.theme-input-group.style--four
	input[type='email']::-ms-input-placeholder,
.footer
	.theme-input-group.style--two.analytic
	input[type='email']::-ms-input-placeholder {
	color: #fff !important;
}

.footer .theme-input-group.affiliate input[type='email']::placeholder,
.footer .theme-input-group.course input[type='email']::placeholder,
.footer .theme-input-group.error404 input[type='email']::placeholder,
.footer .theme-input-group.style--four input[type='email']::placeholder,
.footer
	.theme-input-group.style--two.analytic
	input[type='email']::placeholder {
	color: #fff !important;
}

.footer
	.theme-input-group.affiliate
	input[type='email']::-webkit-input-placeholder,
.footer
	.theme-input-group.course
	input[type='email']::-webkit-input-placeholder,
.footer
	.theme-input-group.error404
	input[type='email']::-webkit-input-placeholder,
.footer
	.theme-input-group.style--four
	input[type='email']::-webkit-input-placeholder,
.footer
	.theme-input-group.style--two.analytic
	input[type='email']::-webkit-input-placeholder {
	color: #fff !important;
}

.footer .widget {
	margin-bottom: 30px;
	padding: 0;
	background-color: transparent;
}

.footer .widget:last-child {
	margin-bottom: 50px;
}

.footer .widget .widget_title h3 {
	font-size: 26px;
	color: #292929;
	font-weight: 700;
	margin-bottom: 24px;
}

.footer .widget .widget-logo {
	margin-bottom: 30px;
}

.footer .widget.widget_text p {
	margin-bottom: 30px;
	font-weight: 300;
	max-width: 380px;
}

.footer .footer-top {
	margin-bottom: 90px;
}

.footer .footer-top .footer-top-left h2 {
	margin-bottom: 16px;
	font-size: 46px;
	font-weight: 600;
}

@media only screen and (max-width: 575px) {
	.footer .footer-top .footer-top-left h2 {
		font-size: 30px;
	}
}

.footer .footer-top .footer-top-left p {
	max-width: 550px;
	font-size: 22px;
}

.footer .footer-top .footer-top-right .custom-checkbox label {
	font-size: 16px;
}

.footer.style--two {
	background-color: #fcf3f1;
	padding-top: 138px;
	padding-bottom: 140px;
}

@media only screen and (max-width: 991px) {
	.footer.style--two {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.footer.style--two .footer-main .footer-boxed {
	background: #fff;
	padding-top: 100px;
	padding-bottom: 50px;
}

@media only screen and (max-width: 575px) {
	.footer.style--two .footer-main .footer-boxed {
		padding-top: 60px;
		padding-bottom: 10px;
	}
}

.footer.style--two .widget .widget_title h3 {
	color: #57548d;
	margin-bottom: 34px;
}

.footer.style--two.analytic {
	padding-bottom: 0;
}

.footer.style--two.analytic .widget .widget_title h3 {
	color: #292929;
	margin-bottom: 34px;
}

.footer.style--two.analytic .widget.widget_text p {
	font-weight: 400;
}

.footer.style--two.pos {
	background-color: #eef8f0;
}

.footer.pay-footer {
	padding-top: 210px;
	padding-bottom: 50px;
	background: #9578fa;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#9578fa),
		to(#6449d6)
	);
	background: -o-linear-gradient(top, #9578fa 0, #6449d6 100%);
	background: linear-gradient(to bottom, #9578fa 0, #6449d6 100%);
}

.footer.pay-footer .widget .widget_title h3 {
	color: #fff;
}

.footer.plugin-footer {
	padding-top: 180px;
	padding-bottom: 50px;
	position: relative;
}

.footer.plugin-footer .footer-shape {
	position: absolute;
	left: 0;
	top: -1px;
	width: 100%;
	height: 80px;
}

.footer.plugin-footer .widget .widget_title h3 {
	color: #fff;
}

.footer.course {
	background: #f6f8f5;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#f6f8f5),
		to(#fff)
	);
	background: -o-linear-gradient(top, #f6f8f5 0, #fff 100%);
	background: linear-gradient(to bottom, #f6f8f5 0, #fff 100%);
}

.footer.style--three {
	font-size: 18px;
	font-weight: 300;
	padding: 48px 0 34px;
	line-height: 1;
}

.footer.style--three .footer-logo {
	margin-bottom: 15px;
}

.footer.style--four {
	padding-top: 140px;
	padding-bottom: 130px;
}

@media only screen and (max-width: 991px) {
	.footer.style--four {
		padding-top: 80px;
		padding-bottom: 70px;
	}
}

.footer.style--four .footer-main .footer-main-inner {
	border-bottom: 1px solid #bfbfbf;
	padding-bottom: 10px;
}

.footer.style--four .footer-bottom {
	padding-top: 60px;
}

.footer.style--four .footer-bottom .footer-bottom-left h2 {
	font-weight: 600;
	margin-bottom: 15px;
}

.footer.style--four .footer-bottom p {
	font-size: 16px;
}

.footer.style--four .widget .widget_title h3 {
	color: #fff;
}

.footer.style--four .widget .footer_menu li a {
	color: #fff;
}

.footer.style--four .footer-icon1,
.footer.style--four .footer-icon2,
.footer.style--four .footer-icon3,
.footer.style--four .footer-icon4 {
	position: absolute;
	opacity: 0.6;
	left: 1%;
	top: 3%;
}

.footer.style--four .footer-icon2 {
	left: auto;
	right: 2.5%;
	top: 4%;
}

.footer.style--four .footer-icon3 {
	left: 40%;
	top: 62%;
}

.footer.style--four .footer-icon4 {
	left: auto;
	right: 2%;
	top: auto;
	bottom: 2%;
}

.footer.management {
	padding-top: 0;
	padding-bottom: 0;
}

.footer.management .footer-main {
	padding-top: 100px;
	padding-bottom: 50px;
}

.footer.management .widget .widget_title h3 {
	color: #fff;
}

.footer.management .widget .footer_menu li a {
	color: #fff;
}

.footer.management .footer-bottom {
	background-color: #6c69cf;
	font-size: 18px;
	padding: 23px 0 25px;
}

.footer.pos {
	padding-top: 118px;
	padding-bottom: 120px;
}

@media only screen and (max-width: 991px) {
	.footer.pos {
		padding-top: 78px;
		padding-bottom: 80px;
	}
}

.footer.pos .widget.widget_text p {
	font-weight: 400;
}

.footer.pos .widget .widget_title h3 {
	color: #292929;
}

.footer.pos .widget .footer_menu li a {
	font-weight: 400;
}

.footer.app {
	background-size: cover;
	background-position: top center;
	position: relative;
	padding-top: 300px;
	margin-top: 240px;
}

@media only screen and (max-width: 1199px) {
	.footer.app {
		padding-top: 250px;
	}
}

@media only screen and (max-width: 575px) {
	.footer.app {
		margin-top: 120px;
	}
}

@media only screen and (max-width: 479px) {
	.footer.app {
		padding-top: 170px;
	}
}

.footer.app .footer-img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.footer.app .footer-img img {
	position: absolute;
	bottom: 300px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.footer.app .footer-img img {
		left: 8%;
	}
}

.footer.app .footer-content .footer-logo {
	margin-bottom: 30px;
}

.footer.app .footer-content p {
	font-size: 16px;
	max-width: 560px;
	margin: 0 auto 30px;
}

.footer.app2 {
	padding-top: 0;
	padding-bottom: 0;
}

.footer.app2 .footer-main {
	padding-top: 100px;
	padding-bottom: 50px;
}

.footer.app2 .widget .widget_title h3 {
	color: #292929;
}

.footer.app2 .widget .footer_menu li a {
	color: #707070;
}

.footer.app2 .footer-bottom {
	background-color: #e8efff;
	font-size: 18px;
	padding: 23px 0 25px;
	color: #292929;
}

.footer.app2 .footer-bottom a {
	color: #292929;
}

.footer.proto {
	padding-top: 370px;
	padding-bottom: 115px;
	position: relative;
}

@media only screen and (max-width: 767px) {
	.footer.proto {
		padding-top: 80px;
		padding-bottom: 10px;
	}
}

.footer.proto > img {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: -1px;
}

@media only screen and (max-width: 767px) {
	.footer.proto > img {
		display: none;
	}
}

.footer.proto .widget .widget_title h3 {
	color: #fff;
}

.footer.proto .widget .footer_menu li a {
	color: #fff;
}

.footer .copyright-footer {
	padding: 56px 0;
	font-size: 16px;
}

.footer .copyright-footer .copyright-text {
	color: #fff;
}

.footer .copyright-footer .copyright-text a {
	color: #fff;
}

.footer .copyright-footer .copyright-text.style--two {
	color: #707070;
}

.footer .copyright-footer .copyright-text.style--two a {
	color: #707070;
}

@media only screen and (max-width: 991px) {
	.error-404 {
		padding: 80px 0;
	}
}

.content-404 h1 {
	font-size: 60px;
	line-height: 1.4;
	margin-bottom: 8px;
	font-weight: 600;
	color: #292929;
}

@media only screen and (max-width: 575px) {
	.content-404 h1 {
		font-size: 50px;
	}
}

.content-404 a {
	font-weight: 500;
}

.content-404 > p {
	margin-bottom: 34px;
}

.content-404 .search-form p,
.content-404 .widget.widget_newsletter.style--two p,
.content-404 .widget.widget_search p {
	font-weight: 600;
	margin-bottom: 20px;
}

.img-404-absolute {
	display: none;
}

@media only screen and (min-width: 1980px) and (max-width: 3600px) {
	.img-404-absolute {
		display: block;
		position: absolute;
		width: 50%;
		right: 0;
		top: 0;
		height: 100%;
	}
}

.img-404 img {
	max-width: none;
}

@media only screen and (min-width: 1980px) and (max-width: 3600px) {
	.img-404 img {
		display: none;
	}
}

@media only screen and (max-width: 991px) {
	.img-404 img {
		max-width: 100%;
		margin-bottom: 60px;
	}
}

.icon404-1 {
	position: absolute;
	-webkit-animation: banner3Shake 3s linear 0s infinite forwards;
	animation: banner3Shake 3s linear 0s infinite forwards;
	left: 5%;
	top: 20%;
	z-index: -1;
}

.content-coming-soon h1 {
	font-size: 60px;
	line-height: 1.4;
	margin-bottom: 8px;
	font-weight: 600;
	color: #292929;
}

@media only screen and (max-width: 575px) {
	.content-coming-soon h1 {
		font-size: 50px;
	}
}

.content-coming-soon > p {
	margin-bottom: 24px;
}

.swiper-container {
	width: 100%;
	height: 400px;
	margin-left: auto;
	margin-right: auto;
}

.swiper-container.style--two {
	height: 440px;
}

@media only screen and (max-width: 1199px) {
	.swiper-container.style--two {
		height: 600px;
	}
}

@media only screen and (max-width: 991px) {
	.swiper-container.style--two {
		height: 400px;
		margin-bottom: 80px;
		padding-top: 10px;
	}
}

@media only screen and (max-width: 575px) {
	.swiper-container.style--two {
		height: 500px;
	}
}

@media only screen and (max-width: 379px) {
	.swiper-container.style--two {
		height: 600px;
	}
}

.swiper-slide {
	font-size: 18px;
	background: #fff;
	padding-left: 60px;
}

@media only screen and (max-width: 575px) {
	.swiper-slide {
		font-size: 14px;
		padding-left: 50px;
	}
}

.swiper-slide h3 {
	color: #292929;
	font-weight: 500;
	margin-bottom: 12px;
	cursor: pointer;
}

@media only screen and (max-width: 575px) {
	.swiper-slide h3 {
		font-size: 20px;
		margin-bottom: 6px;
	}
}

@media only screen and (max-width: 479px) {
	.swiper-slide P {
		font-size: 16px;
		line-height: 1.5;
	}
}

.dot-line {
	width: 2px;
	background: #cecece;
	height: 127%;
	position: absolute;
	left: 10px;
}

.dot-line:after {
	position: absolute;
	content: '';
	width: 12px;
	height: 12px;
	background: #fff;
	border: 2px solid #cecece;
	left: -4.5px;
	top: 0;
}

.swiper-slide.slide-active .dot-line {
	background: #5f52dc;
	-webkit-animation: fullHeight 0.5s linear 0s backwards;
	animation: fullHeight 0.5s linear 0s backwards;
}

.swiper-slide.slide-active .dot-line:after {
	width: 20px;
	height: 20px;
	border: 3px solid #5f52dc;
	left: -9px;
}

.swiper-button-next,
.swiper-button-prev {
	background: 0 0;
	position: absolute;
	top: 0;
	left: 14.5px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	position: absolute;
	top: -30px;
	left: -1px;
	content: '\f106';
	font-family: fontAwesome;
	font-size: 40px;
}

.swiper-button-next.swiper-button-disabled:after,
.swiper-button-prev.swiper-button-disabled:after {
	color: #b8b8b8;
}

.swiper-button-next {
	top: auto;
	bottom: -50px;
}

.swiper-button-next:after {
	content: '\f107';
	top: auto;
	bottom: 0;
}

.swiper-button-next.style--two,
.swiper-button-prev.style--two {
	left: 4px;
	bottom: -60px;
}

.swiper-button-prev.style--two {
	top: 10px;
}

.dot-line.style--two {
	left: 15px;
}

.dot-line.style--two:after,
.dot-line.style--two:before {
	width: 16px;
	height: 16px;
	border: 3px solid #00418e;
	left: -6.5px;
	top: 0;
}

.dot-line.style--two:before {
	position: absolute;
	content: '';
	width: 0;
	height: 0;
	background-color: #ff5b83;
	left: 50%;
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	transform: translate(-50%);
	top: 8px;
	border: none;
	z-index: 1;
}

.swiper-slide.style--two p {
	font-size: 20px;
}

.swiper-slide.style--two.slide-active .dot-line {
	background: #cecece;
	-webkit-animation: fullHeight 0.5s linear 0s backwards;
	animation: fullHeight 0.5s linear 0s backwards;
}

.swiper-slide.style--two.slide-active .dot-line:after {
	width: 30px;
	height: 30px;
	border: 2px solid #00418e;
	left: -14px;
}

.swiper-slide.style--two.slide-active .dot-line:before {
	width: 18px;
	height: 18px;
	top: 6px;
	left: 50%;
	-webkit-transform: translate(-50%);
	-ms-transform: translate(-50%);
	transform: translate(-50%);
}

.swiper-container.how-work-slider {
	height: 600px;
}

@media only screen and (max-width: 1199px) {
	.swiper-container.how-work-slider {
		height: 700px;
	}
}

@media only screen and (max-width: 991px) {
	.swiper-container.how-work-slider {
		height: 600px;
		margin-bottom: 60px;
	}
}

.swiper-container.how-work-slider .swiper-slide {
	background: 0 0;
	padding-left: 0;
	padding: 20px 30px;
}

@media only screen and (max-width: 479px) {
	.swiper-container.how-work-slider .swiper-slide {
		padding: 10px;
	}
}

.swiper-container.how-work-slider .swiper-slide .img {
	margin-right: 30px;
}

@media only screen and (max-width: 479px) {
	.swiper-container.how-work-slider .swiper-slide .img {
		margin-right: 20px;
	}
}

.swiper-container.how-work-slider .swiper-slide .content h3 {
	font-size: 30px;
	font-weight: 600;
}

@media only screen and (max-width: 575px) {
	.swiper-container.how-work-slider .swiper-slide .content h3 {
		font-size: 22px;
	}
}

.swiper-container.how-work-slider .swiper-slide .content p {
	font-size: 20px;
}

@media only screen and (max-width: 575px) {
	.swiper-container.how-work-slider .swiper-slide .content p {
		font-size: 16px;
	}
}

.swiper-container.how-work-slider .swiper-slide.active {
	background-color: #fff;
}

[data-tab] {
	display: none;
}

[data-tab].active {
	display: block;
}

.service-bg {
	background-color: #f5f8ff;
}

.analytic-fbg {
	background: #f7fbfe;
}

.single-service {
	padding: 10px 40px 50px;
	margin-bottom: 30px;
	background-color: #fff;
	position: relative;
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.btn-link:hover {
	text-decoration: none !important;
}

@media only screen and (max-width: 575px) {
	.single-service {
		padding: 40px 20px;
	}
}

.single-service:hover {
	-webkit-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	transform: translateY(-5px);
}

.single-service .service-icon {
	width: 80px;
	height: 80px;
	background: #6bf1d6;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 30px;
}

.single-service .service-icon.two {
	background: #ae88e6;
}

.single-service .service-icon.three {
	background: #47cbf3;
}

.single-service .service-icon.four {
	background: #ffbd52;
}

.single-service .service-icon.five {
	background: #f091ba;
}

.single-service .service-icon.six {
	background: #77a8ff;
}

.single-service .service-content h3 {
	color: #292929;
	margin-bottom: 8px;
	font-weight: 500;
}

@media only screen and (max-width: 479px) {
	.single-service {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.single-service .service-icon {
		margin-right: 0;
		margin-bottom: 30px;
	}

	.single-service .service-content {
		text-align: center;
	}
}

.single-service.style--two {
	overflow: hidden;
}

.single-service.style--two > span {
	position: absolute;
	width: 0;
	height: 0;
	display: block;
	z-index: -1;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: 0.7s;
	-o-transition: 0.7s;
	transition: 0.7s;
}

.single-service.style--two:hover > span {
	width: 300%;
	height: 1320px;
}

.single-service.style--two .service-icon {
	margin: 0;
	margin-bottom: 38px;
	width: 120px;
	height: 120px;
	background-color: #ecebff;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.single-service.style--two .service-icon.bg-2 {
	background-color: #fff1d9;
}

.single-service.style--two .service-icon.bg-3 {
	background-color: #d9fff1;
}

.single-service.style--two .service-content h3 {
	color: #57548d;
	margin-bottom: 15px;
	font-weight: 600;
}

.single-service.style--two .service-content P {
	font-size: 18px;
	font-weight: 300;
}

.single-service.style--two:hover {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.single-service.style--two:hover .service-icon {
	background-color: #fff;
}

.single-service.style--two:hover .service-icon .svg path {
	fill: #ebd281;
}

.single-service.style--two:hover .service-content h3 {
	color: #ebd281;
}

.single-service.style--two:hover .service-content P {
	color: #fff;
}

.single-service.style--two:hover .service-content .btn-crm.btn-view-more {
	color: #ebd281;
}

.single-service.style--two:hover
	.service-content
	.btn-crm.btn-view-more
	.svg
	line,
.single-service.style--two:hover
	.service-content
	.btn-crm.btn-view-more
	.svg
	path {
	stroke: #ebd281;
}

.single-service.style--two.management .service-content h3 {
	color: #625fd1;
}

.single-service.style--two.management .service-content P {
	font-size: 18px;
	font-weight: 300;
}

.single-service.style--two.management .service-content .btn-crm.btn-view-more {
	color: #625fd1;
}

.single-service.style--two.management
	.service-content
	.btn-crm.btn-view-more
	.svg
	line,
.single-service.style--two.management
	.service-content
	.btn-crm.btn-view-more
	.svg
	path {
	stroke: #625fd1;
}

.single-service.style--two.management:hover {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.single-service.style--two.management:hover .service-icon {
	background-color: #fff;
}

.single-service.style--two.management:hover .service-content h3 {
	color: #fff;
}

.single-service.style--two.management:hover
	.service-content
	.btn-crm.btn-view-more {
	color: #ff4d8c;
}

.single-service.style--two.management:hover
	.service-content
	.btn-crm.btn-view-more
	.svg
	line,
.single-service.style--two.management:hover
	.service-content
	.btn-crm.btn-view-more
	.svg
	path {
	stroke: #ff4d8c;
}

.single-service.style--three {
	padding: 0 40px;
	margin-bottom: 52px;
}

@media only screen and (max-width: 479px) {
	.single-service.style--three {
		padding: 0;
	}
}

.single-service.style--three .service-icon {
	margin: 0 auto;
	margin-bottom: 38px;
	background: 0 0;
}

.single-service.style--three .service-content h3 {
	color: #292929;
	margin-bottom: 15px;
	font-weight: 600;
}

.single-service.style--three .service-content P {
	font-size: 18px;
	font-weight: 300;
}

.single-service.style--three.pay .service-icon {
	margin-bottom: 28px;
	width: 120px;
	height: 120px;
	background: #dff5ff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.single-service.style--three.pay .service-icon.two {
	background: #fff3eb;
}

.single-service.style--three.pay .service-icon.three {
	background: #d9fff6;
}

.single-service.style--three.pay .service-icon.four {
	background: #fff0ef;
}

.single-service.style--three.pay .service-icon.five {
	background: #dffff6;
}

.single-service.style--three.pay .service-icon.six {
	background: #e9f3ff;
}

.single-service.style--three.pay:hover .service-icon {
	background: #21bcff;
}

.single-service.style--three.pay:hover .service-icon .svg g path {
	fill: #fff;
}

.single-service.style--three.pay:hover .service-icon.two {
	background: #f99c5e;
}

.single-service.style--three.pay:hover .service-icon.three {
	background: #2dcea5;
}

.single-service.style--three.pay:hover .service-icon.four {
	background: #ff8781;
}

.single-service.style--three.pay:hover .service-icon.five {
	background: #04e2a3;
}

.single-service.style--three.pay:hover .service-icon.six {
	background: #0472fa;
}

.single-service.style--three.pay .service-content h3 {
	margin-bottom: 0;
	font-weight: 500;
	font-size: 26px;
}

.single-service.style--three.pay:hover {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.single-service.style--three.plug .service-icon {
	margin-bottom: 28px;
	width: 120px;
	height: 120px;
	background: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.single-service.style--three.plug .service-content h3 {
	margin-bottom: 0;
	font-weight: 500;
	font-size: 26px;
}

.single-service.style--four {
	padding: 40px;
	background: #929dff;
	text-align: center;
	margin-bottom: 0;
}

.single-service.style--four:hover {
	-webkit-box-shadow: 0 10px 24px rgba(146, 157, 255, 0.5);
	box-shadow: 0 10px 24px rgba(146, 157, 255, 0.5);
}

.single-service.style--four .service-icon {
	margin: 0;
	margin-bottom: 58px;
	height: auto;
	width: auto;
	background: 0 0;
}

.single-service.style--four .service-content h3 {
	color: #fff;
	margin-bottom: 15px;
	font-weight: 600;
}

.single-service.style--four:hover {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.single-service.style--four.one {
	background: #85cfff;
}

.single-service.style--four.one:hover {
	-webkit-box-shadow: 0 10px 24px rgba(133, 207, 255, 0.5);
	box-shadow: 0 10px 24px rgba(133, 207, 255, 0.5);
}

.single-service.style--four.two {
	background: #bf87ff;
}

.single-service.style--four.two:hover {
	-webkit-box-shadow: 0 10px 24px rgba(191, 135, 255, 0.5);
	box-shadow: 0 10px 24px rgba(191, 135, 255, 0.5);
}

.single-service.style--four.three {
	background: #ffcb87;
}

.single-service.style--four.three:hover {
	-webkit-box-shadow: 0 10px 24px rgba(255, 203, 135, 0.5);
	box-shadow: 0 10px 24px rgba(255, 203, 135, 0.5);
}

.single-service.style--four.four {
	background: #85b6ff;
}

.single-service.style--four.four:hover {
	-webkit-box-shadow: 0 10px 24px rgba(133, 182, 255, 0.5);
	box-shadow: 0 10px 24px rgba(133, 182, 255, 0.5);
}

.single-service.style--five {
	margin: 0;
	padding: 0;
	overflow: hidden;
	position: relative;
}

.single-service.style--five .service-icon {
	padding: 10px;
	background-color: #e6fffa;
	margin-right: 0;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	border-radius: 0;
}

.single-service.style--five .service-icon.one {
	background: #d7eff9;
}

.single-service.style--five .service-icon.two {
	background: #e9e6ff;
}

.single-service.style--five .service-icon.three {
	background: #fff6db;
}

.single-service.style--five .service-content {
	padding: 28px 20px 22px;
	padding-left: 100px;
}

.single-service.style--five .service-content h3 {
	margin-bottom: 5px;
	font-weight: 500;
}

.single-service.style--five .service-content p {
	font-weight: 300;
}

.single-service.style--five:hover {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.single-service.style--six .service-icon {
	background: #dff5ff;
}

.single-service.style--six .service-icon.two {
	background: #fff3eb;
}

.single-service.style--six .service-icon.three {
	background: #dffff6;
}

.single-service.style--six .service-icon.four {
	background: #e9f3ff;
}

.single-service.style--six .service-content p {
	font-weight: 300;
}

.single-service.style--six:hover .service-icon .svg {
	-webkit-transform: rotate(10deg);
	-ms-transform: rotate(10deg);
	transform: rotate(10deg);
}

.single-service.style--seven {
	margin-bottom: 50px;
	height: auto;
	box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
	border-radius: 12px;
}

.single-service.style--seven img {
	margin-bottom: 0px;
}

.single-service.style--seven h3 {
	font-size: 22px;
}

.single-service.style--seven.management {
	margin-bottom: 73px;
}

@media only screen and (max-width: 575px) {
	.single-service.style--seven.management {
		margin-bottom: 53px;
	}
}

.single-service.style--seven.management2 {
	padding: 0 10px;
}

.single-service.style--seven.management2 h3 {
	font-size: 26px;
	line-height: 1.3;
	margin-bottom: 0;
}

.single-service.analytic {
	padding: 0;
	margin-bottom: 53px;
	background: 0 0;
}

.single-service.analytic .service-icon {
	margin: 0 auto;
	margin-bottom: 18px;
	background: 0 0;
}

.single-service.analytic .service-content h3 {
	color: #292929;
	margin-bottom: 5px;
	font-weight: 600;
	font-size: 26px;
}

.single-service.analytic .service-content P {
	font-size: 22px;
	font-weight: 300;
	max-width: 340px;
	margin: 0 auto;
}

.single-service.host {
	text-align: center;
	padding: 50px;
	margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
	.single-service.host {
		padding: 30px;
	}
}

@media only screen and (max-width: 575px) {
	.single-service.host {
		padding: 30px 20px;
		margin-bottom: 10px;
	}
}

.single-service.host .service-icon {
	margin: 0 auto 28px;
	background: #fdce36;
}

.single-service.host .service-icon.two {
	background: #ae88e6;
}

.single-service.host .service-icon.three {
	background: #82d0e8;
}

.single-service.host .service-icon.four {
	background: #6bf1d6;
}

.single-service.host .service-icon.five {
	background: #f091ba;
}

.single-service.host .service-icon.six {
	background: #b6cdf6;
}

.single-service.host .service-content h3 {
	margin-bottom: 13px;
}

.single-service.host .service-content p {
	font-weight: 300;
}

.single-service.host.style-two .service-icon {
	background: 0 0;
}

.single-service.proto {
	padding: 0;
	background-color: transparent;
	margin-bottom: 60px;
}

.single-service.proto .service-icon {
	width: 140px;
	height: 140px;
	background-color: #fff;
}

@media only screen and (max-width: 479px) {
	.single-service.proto .service-icon {
		margin-bottom: 20px;
	}
}

.single-service.proto .service-content h3 {
	color: #5a50a5;
}

.single-service.proto:hover {
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}

.host-wc-right {
	position: relative;
}

.host-wc-right span.bg {
	position: absolute;
	width: 1100%;
	right: -1000%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f3f7ff;
	z-index: -1;
	border-radius: 20px 0 0 20px;
}

.host-wc-right .single-service {
	padding: 20px 0 20px 117px;
	margin-bottom: 14px;
	background-color: transparent;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.host-wc-right .single-service {
		padding-left: 90px;
	}
}

@media only screen and (max-width: 1199px) {
	.host-wc-right .single-service {
		padding: 20px 0 20px 50px;
	}
}

@media only screen and (max-width: 575px) {
	.host-wc-right .single-service {
		padding: 20px;
	}
}

.host-wc-right .single-service .service-icon {
	background-color: #af86e6;
}

.host-wc-right .single-service .service-icon.two {
	background-color: #9ed2e3;
}

.host-wc-right .single-service .service-icon.three {
	background-color: #fecf2f;
}

.host-wc-right .single-service .service-content p {
	font-size: 18px;
	font-weight: 300;
}

.host-wc-right .single-service:hover .service-icon .svg,
.host-wc-right .single-service:hover .service-icon img {
	-webkit-transform: rotate(10deg);
	-ms-transform: rotate(10deg);
	transform: rotate(10deg);
}

.service-slider.owl-carousel .owl-stage-outer {
	padding: 30px;
	margin: 0 -30px;
}

.ce-content h2 {
	font-weight: 600;
	margin-bottom: 14px;
	line-height: 1.28;
}

.ce-content p {
	margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
	.ce-content {
		margin-bottom: 60px;
	}
}

.customer {
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	opacity: 0.5;
}

@media only screen and (max-width: 479px) {
	.customer {
		font-size: 12px;
	}
}

.customer span {
	display: block;
	font-size: 22px;
	font-weight: 600;
	color: #292929;
	margin-bottom: 8px;
}

@media only screen and (max-width: 479px) {
	.customer span {
		font-size: 16px;
	}
}

.customer.one {
	opacity: 1;
}

.ui-range-slider {
	padding: 60px;
}

@media only screen and (max-width: 575px) {
	.ui-range-slider {
		padding: 30px;
	}
}

.ui-range-slider p {
	font-weight: 400;
}

.ui-range-slider strong {
	font-size: 60px;
	font-weight: 600;
	line-height: 1.3;
}

.ui-range-slider .sale {
	margin-bottom: 38px;
}

.ui-range-slider .sale strong {
	font-weight: 600;
}

.ui-range-slider .total-earning {
	margin-bottom: 90px;
}

.ui-range-slider .total-earning strong {
	font-weight: 600;
}

#slider {
	border: none;
	height: 2px;
	background: #cecece;
	position: relative;
}

#slider span {
	width: 12px;
	height: 12px;
	background: #fff;
	border: 2px solid #cecece;
	border-radius: 50%;
	left: 0;
	top: -6px;
	position: absolute;
	z-index: 999;
}

#slider span.one {
	border-color: #5f52dc;
}

#slider span.two {
	left: 33.33%;
}

#slider span.three {
	left: 66.66%;
}

#slider span.four {
	left: 100%;
}

#slider .focus {
	left: 0;
	top: -1px;
	width: 33.33%;
	height: 4px;
	background: #5f52dc;
	position: absolute;
}

#slider span.ui-slider-handle {
	border-color: #5f52dc;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	top: -10px;
}

.earn-path-feature {
	background: #fbfbfb;
	padding: 100px 120px 20px;
}

@media only screen and (max-width: 1199px) {
	.earn-path-feature {
		padding: 80px 50px 0;
	}
}

.earn-path-feature .single-service {
	background: 0 0;
	padding: 0;
	margin-bottom: 80px;
}

.earn-path-feature .single-service .service-icon {
	width: 100px;
	height: 100px;
	background: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 40px;
}

@media only screen and (max-width: 479px) {
	.earn-path-feature .single-service .service-icon {
		margin: 0;
		margin-bottom: 20px;
	}
}

.pos-cta {
	padding-top: 80px;
}

@media only screen and (max-width: 1199px) {
	.pos-cta {
		padding-top: 120px;
	}
}

@media only screen and (max-width: 991px) {
	.pos-cta {
		padding-top: 60px;
	}
}

.cta-line1,
.cta-line2 {
	position: absolute;
	left: 5%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.cta-line2 {
	left: auto;
	right: 5%;
}

.cta-icon1,
.cta-icon2,
.cta-icon3 {
	position: absolute;
	opacity: 0.6;
	left: 5%;
	top: 8%;
}

.cta-icon2 {
	left: 60%;
	top: auto;
	bottom: 5%;
}

.cta-icon3 {
	left: auto;
	right: 5%;
}

.analytic-cta-img {
	position: absolute;
	left: 0;
	bottom: 0;
}

@media only screen and (max-width: 767px) {
	.cta-proto {
		padding-top: 40px;
	}
}

.cta-content {
	position: relative;
	z-index: 2;
	padding-left: 15px;
	padding-right: 15px;
}

.cta-content h2 {
	line-height: 1.2;
	font-size: 46px;
	font-weight: 600;
	margin-bottom: 44px;
}

@media only screen and (max-width: 991px) {
	.cta-content h2 br {
		display: none;
	}
}

@media only screen and (max-width: 575px) {
	.cta-content h2 {
		font-size: 28px;
	}
}

.cta-content .cta-quote {
	padding-bottom: 100px;
	margin-top: 152px;
}

@media only screen and (max-width: 575px) {
	.cta-content .cta-quote {
		padding-bottom: 40px;
		margin-top: 100px;
	}
}

.cta-content .cta-quote h3 {
	font-size: 26px;
	font-weight: 500;
	color: #fff;
	position: relative;
}

.cta-content.email h2 {
	font-size: 40px;
	line-height: 1.35;
}

@media only screen and (max-width: 991px) {
	.cta-content.style--two {
		margin-bottom: 60px;
	}
}

.cta-content.style--two h2 {
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
	.cta-content.style--two h2 {
		font-size: 30px;
	}
}

.cta-content.style--two p {
	margin-bottom: 44px;
}

.cta-content.crs h2 {
	font-size: 40px;
	margin-bottom: 17px;
	margin-top: -6px;
}

@media only screen and (max-width: 991px) {
	.cta-content.crs h2 br {
		display: none;
	}
}

@media only screen and (max-width: 575px) {
	.cta-content.crs h2 {
		font-size: 30px;
	}
}

.cta-content.book h2 {
	font-size: 40px;
	margin-bottom: 8px;
}

@media only screen and (max-width: 479px) {
	.cta-content.book h2 {
		font-size: 36px;
	}
}

.cta-content.book h3 {
	font-size: 22px;
	font-weight: 500;
	margin-bottom: 15px;
}

.cta-content.book p {
	margin-bottom: 34px;
}

@media only screen and (max-width: 991px) {
	.cta-content.book .paperback {
		margin-bottom: 60px;
	}
}

.cta-content.book .paperback .order-box {
	padding: 40px 60px;
	background: #fff0b8;
}

.cta-content.book .paperback .order-box:not(:last-child) {
	margin-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.cta-content.book .paperback .order-box {
		padding: 40px 40px;
	}
}

@media only screen and (max-width: 1199px) {
	.cta-content.book .paperback .order-box {
		padding: 40px 60px;
	}

	.cta-content.book .paperback .order-box:not(:last-child) {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

@media only screen and (max-width: 991px) {
	.cta-content.book .paperback .order-box {
		padding: 40px;
	}

	.cta-content.book .paperback .order-box:not(:last-child) {
		margin-right: 20px;
		margin-bottom: 0;
	}
}

@media only screen and (max-width: 575px) {
	.cta-content.book .paperback .order-box:not(:last-child) {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

.cta-content.book .paperback .order-box h3 {
	color: #292929;
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 10px;
}

.cta-content.book .paperback .order-box .price {
	margin-bottom: 25px;
}

.cta-content.book .paperback .order-box .price strong {
	font-size: 30px;
	font-weight: 600;
	margin-right: 14px;
	line-height: 1;
}

.cta-content.book .paperback .order-box .price .stroke-text del {
	font-size: 22px;
	font-weight: 400;
	line-height: 1;
}

.cta-content.book .paperback .order-box .btn-book.s-btn {
	color: #fff;
}

.cta-content.book .paperback .order-box .btn-book.s-btn:hover {
	background: #d2e5ff;
}

.cta-content.book .paperback .order-box.box2 {
	background: #fff;
}

.cta-content.book .paperback .order-box.box2 .btn-book.s-btn {
	background: #d2e5ff;
}

.cta-content.book .paperback .order-box.box2 .btn-book.s-btn:hover {
	color: #fff;
}

.cta-content.management h2 {
	font-size: 40px;
	margin-bottom: 10px;
}

.cta-content.management p {
	max-width: 590px;
	margin: 0 auto;
	margin-bottom: 40px;
}

.cta-content.pos {
	padding: 0;
}

.cta-content.pos h2 {
	margin-bottom: 14px;
}

.cta-content.pos p {
	max-width: 560px;
}

.cta-content.app {
	padding: 0;
}

.cta-content.app h2 {
	margin-bottom: 24px;
	color: #292929;
	font-size: 50px;
	font-weight: 700;
}

@media only screen and (max-width: 575px) {
	.cta-content.app h2 {
		font-size: 40px;
	}
}

.cta-content.app p {
	font-weight: 400;
	margin-bottom: 55px;
}

.cta-content.proto {
	position: relative;
	z-index: 1;
	padding-top: 100px;
	padding-bottom: 100px;
	margin-bottom: -300px;
}

@media only screen and (max-width: 767px) {
	.cta-content.proto {
		margin-bottom: 0;
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.cta-content.proto:after {
	position: absolute;
	left: 0;
	top: 0;
	width: 111%;
	height: 100%;
	content: '';
	-webkit-transform: perspective(2850px) rotateY(30deg);
	transform: perspective(2850px) rotateY(30deg);
	z-index: -1;
}

@media only screen and (max-width: 991px) {
	.cta-content.proto:after {
		width: 115%;
		left: -28px;
	}
}

@media only screen and (max-width: 767px) {
	.cta-content.proto:after {
		-webkit-transform: perspective(2850px) rotateY(0);
		transform: perspective(2850px) rotateY(0);
		width: 200%;
		left: -50%;
	}
}

.cta-content.proto h2 {
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
	.cta-content.proto h2 {
		font-size: 36px;
	}
}

@media only screen and (max-width: 479px) {
	.cta-content.proto h2 {
		font-size: 32px;
	}
}

.cta-content.proto p {
	max-width: 640px;
	margin: 0 auto 44px;
}

@media only screen and (min-width: 0px) and (max-width: 1500px) {
	.cta-content.email h2 br {
		display: none;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.cta-img.book {
		padding-right: 15px;
	}
}

@media only screen and (max-width: 1199px) {
	.cta-img.book {
		padding-right: 15px;
	}
}

.cta-img.pos {
	padding-bottom: 10px;
}

@media only screen and (max-width: 991px) {
	.cta-img.pos {
		margin-bottom: 40px;
	}
}

.Payment-cta {
	margin-bottom: -110px;
	position: relative;
}

.Payment-cta .cta-wrap {
	padding: 85px 30px;
	background: #3d52a2;
}

@media only screen and (max-width: 479px) {
	.Payment-cta .cta-wrap {
		padding: 60px 30px;
	}
}

.Payment-cta .cta-wrap .cta-content h2 {
	margin-bottom: 0;
	font-size: 40px;
	text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
	.Payment-cta .cta-wrap .cta-content h2 {
		font-size: 36px;
	}
}

@media only screen and (max-width: 479px) {
	.Payment-cta .cta-wrap .cta-content h2 {
		font-size: 28px;
	}
}

@media only screen and (max-width: 1199px) {
	.Payment-cta .cta-wrap .cta-content h2 {
		margin-bottom: 10px;
	}
}

.plug-cta {
	background-color: #4e2fa9;
}

.bg-left {
	position: absolute;
	left: -200%;
	top: 0;
	width: calc(300% - 15px);
	height: 100%;
	border-radius: 0 0 150px 0;
	z-index: -1;
}

@media only screen and (max-width: 767px) {
	.bg-left {
		width: 600%;
	}
}

@media only screen and (max-width: 1199px) {
	.pos-cta-bg {
		background-color: #f2ffff;
	}
}

.row.cta-bg {
	position: relative;
	z-index: 1;
}

.row.cta-bg:after {
	position: absolute;
	background-color: #f2ffff;
	content: '';
	left: 15px;
	top: auto;
	bottom: 0;
	width: calc(100% - 30px);
	height: calc(100% - 40px);
	z-index: -1;
}

@media only screen and (max-width: 1199px) {
	.row.cta-bg:after {
		display: none;
	}
}

.feature-content h2 {
	margin-bottom: 10px;
}

@media only screen and (max-width: 991px) {
	.feature-content {
		margin-bottom: 60px;
	}
}

.feature-content.style--two.pay {
	padding: 140px 0 130px;
	padding-right: 100px;
}

@media only screen and (max-width: 1199px) {
	.feature-content.style--two.pay {
		padding-right: 60px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-content.style--two.pay {
		padding: 80px 0 70px;
		padding-right: 0;
		margin-bottom: 60px;
	}
}

.feature-content.style--two.pay2 {
	padding: 140px 0 90px;
	padding-left: 140px;
}

@media only screen and (max-width: 1199px) {
	.feature-content.style--two.pay2 {
		padding-left: 100px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-content.style--two.pay2 {
		padding-left: 0;
		padding: 80px 0 30px;
	}
}

.feature-content.plug {
	margin-bottom: 0;
}

.feature-content.plug .section-title {
	margin-bottom: 40px;
}

.feature-content.plug .section-title h2 {
	margin-bottom: 10px;
	font-weight: 600;
}

.feature-content.plug.style--two .section-title {
	margin-bottom: 10px;
}

.feature-content.plug .content p {
	margin-bottom: 14px;
}

.feature-content.plug .content p.mb-30 {
	margin-bottom: 30px;
}

.feature-content.management .section-title {
	margin-bottom: 30px;
}

.feature-content.management .section-title h2 {
	font-weight: 600;
	margin-bottom: 10px;
	line-height: 1.2;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.feature-content.management .section-title h2 br {
		display: none;
	}
}

.feature-content.management .section-title p {
	margin: 0;
}

.feature-content.pos {
	position: relative;
	padding: 60px 75px;
	background-color: #fffcf2;
	margin-left: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.feature-content.pos {
		margin-left: 0;
		padding: 50px;
	}
}

@media only screen and (max-width: 1199px) {
	.feature-content.pos {
		margin-left: 0;
		padding: 50px;
	}

	.feature-content.pos .list-check.pos li p {
		font-size: 18px;
	}
}

@media only screen and (max-width: 575px) {
	.feature-content.pos {
		padding: 50px 15px;
	}
}

.feature-content.app {
	padding: 0 50px;
}

@media only screen and (max-width: 991px) {
	.feature-content.app {
		padding: 0;
	}
}

.feature-content.app h2 {
	font-size: 50px;
	font-weight: 600;
	line-height: 1.3;
	color: #292929;
	margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
	.feature-content.app h2 {
		font-size: 40px;
	}
}

.feature-content.app p {
	margin-bottom: 30px;
}

.feature-content.app.style--two {
	padding: 0;
	padding-right: 120px;
	margin: 0;
}

@media only screen and (max-width: 1199px) {
	.feature-content.app.style--two {
		padding-right: 50px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-content.app.style--two {
		padding: 0;
		padding-bottom: 20px;
	}
}

.feature-content.app2 {
	padding-left: 120px;
	margin: 0;
}

@media only screen and (max-width: 1199px) {
	.feature-content.app2 {
		padding-left: 40px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-content.app2 {
		padding-left: 0;
	}
}

.feature-content.l-app h2 {
	font-size: 50px;
	color: #292929;
	line-height: 1.2;
	margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
	.feature-content.l-app h2 {
		margin-bottom: 50px;
	}
}

@media only screen and (max-width: 1199px) {
	.feature-content.l-app h2 br {
		display: none;
	}
}

@media only screen and (max-width: 575px) {
	.feature-content.l-app h2 {
		font-size: 40px;
	}
}

.feature-content.l-app p {
	margin-bottom: 30px;
	font-weight: 400;
}

.feature-content.proto h2 {
	margin-bottom: 20px;
	line-height: 1.25;
}

@media only screen and (max-width: 767px) {
	.feature-content.proto h2 {
		font-size: 36px;
	}
}

.feature-content.proto p {
	margin-bottom: 30px;
}

.feature-img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	z-index: 1;
}

.feature-img .f-bg {
	position: relative;
	left: auto;
	right: -250px;
	width: 118%;
	top: 0;
	z-index: -1;
}

.feature-img .f-bg.crm {
	position: absolute;
	top: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.feature-img .f-bg.crm {
		top: 0;
	}
}

@media only screen and (max-width: 1199px) {
	.feature-img .f-bg.crm {
		top: -100px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img .f-bg.crm {
		top: 0;
		left: auto;
		right: -15px;
		width: 100%;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.feature-img .f-bg {
		left: 140px;
		right: auto;
		width: 100%;
	}
}

@media only screen and (max-width: 1199px) {
	.feature-img .f-bg {
		left: 0;
	}
}

.feature-img .f-img {
	position: absolute;
	left: auto;
	right: -50px;
}

.feature-img .f-img.crm {
	position: relative;
}

@media only screen and (max-width: 1199px) {
	.feature-img .f-img {
		right: 0;
		padding: 0 15px;
	}
}

.feature-img .mobile-bg {
	position: absolute;
	z-index: -1;
	-webkit-animation: spin 60s linear 0.3s infinite forwards;
	animation: spin 60s linear 0.3s infinite forwards;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.feature-img .mobile-bg {
		bottom: 80px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
	.feature-img .host-f2,
	.feature-img .pay-f2 {
		padding-right: 30px;
	}
}

.feature-img.style--two .f-bg {
	left: -270px;
}

@media only screen and (max-width: 1199px) {
	.feature-img.style--two .f-bg {
		left: -100px;
	}
}

.feature-img.style--two .f-img {
	right: 0;
}

@media only screen and (max-width: 1199px) {
	.feature-img.style--two .f-img {
		right: 0;
	}
}

.feature-img.style--three .f-bg {
	left: 290px;
}

@media only screen and (max-width: 1199px) {
	.feature-img.style--three .f-bg {
		left: 100px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.style--three .f-bg {
		right: 0;
	}
}

.feature-img.style--three .f-img {
	right: -90px;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.feature-img.style--three .f-img {
		right: -60px;
	}
}

@media only screen and (max-width: 1199px) {
	.feature-img.style--three .f-img {
		right: 0;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.style--three .f-img {
		right: 0;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.plug {
		margin-bottom: 60px;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.feature-img.analytic .feature-map3 {
		width: 100%;
	}
}

.feature-img.app img {
	margin-left: -150px;
	max-width: none;
}

@media only screen and (min-width: 991px) and (max-width: 1500px) {
	.feature-img.app img {
		max-width: 140%;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.app img {
		max-width: 100%;
		margin-left: 0;
		margin-bottom: 30px;
	}
}

.feature-img.app2 img {
	margin-left: -15px;
	max-width: none;
}

@media only screen and (min-width: 991px) and (max-width: 1500px) {
	.feature-img.app2 img {
		max-width: 120%;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.app2 img {
		max-width: 100%;
		margin-left: 0;
		margin-bottom: 40px;
	}
}

.feature-img.l-app {
	margin-left: 60px;
}

@media only screen and (max-width: 991px) {
	.feature-img.l-app {
		margin-left: 0;
	}
}

.feature-img.l-app.two {
	margin: 0;
	margin-right: 60px;
}

@media only screen and (max-width: 991px) {
	.feature-img.l-app.two {
		margin: 0;
	}
}

.feature-img.proto {
	padding-left: 20px;
}

@media only screen and (max-width: 991px) {
	.feature-img.proto {
		padding-left: 0;
	}
}

.feature-img.proto.left {
	padding-left: 0;
}

.feature-img.proto .proto-img {
	max-width: none;
}

@media only screen and (max-width: 991px) {
	.feature-img.proto .proto-img {
		max-width: 100%;
	}
}

.feature-img.proto .proto-img.left {
	left: -270px;
	position: relative;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.feature-img.proto .proto-img.left {
		left: -360px;
	}
}

@media only screen and (max-width: 1199px) {
	.feature-img.proto .proto-img.left {
		left: -400px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-img.proto .proto-img.left {
		left: 0;
	}
}

@-moz-document url-prefix() {
	.feature-img.proto .proto-img {
		max-width: none;
		min-width: 808px;
	}
	@media only screen and (max-width: 991px) {
		.feature-img.proto .proto-img {
			max-width: 100%;
			min-width: auto;
		}
	}
}

.pay-bg1,
.pay-bg2 {
	position: absolute;
	left: -1000%;
	top: 0;
	background: #f9f9f9;
	width: 1100%;
	height: 100%;
	z-index: -1;
}

@media only screen and (max-width: 991px) {
	.pay-bg1,
	.pay-bg2 {
		height: calc(100% - 60px);
	}
}

.pay-bg2 {
	left: auto;
	right: -1000%;
}

@media only screen and (max-width: 991px) {
	.pay-bg2 {
		height: calc(100% - 60px);
	}
}

.feature {
	padding-top: 70px;
	padding-bottom: 190px;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.feature {
		padding-top: 0;
		padding-bottom: 140px;
	}
}

@media only screen and (max-width: 1199px) {
	.feature {
		padding-top: 100px;
		padding-bottom: 140px;
	}
}

@media only screen and (max-width: 991px) {
	.feature {
		margin-top: 0;
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.e-feature-img {
	width: 745px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.e-feature-img {
		width: 110%;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1680px) {
	.e-feature-img {
		width: 110%;
	}
}

@media only screen and (max-width: 1199px) {
	.e-feature-img {
		width: 100%;
	}
}

.e-feature-img.two {
	position: relative;
	left: -190px;
}

@media only screen and (min-width: 1200px) and (max-width: 1602px) {
	.e-feature-img.two {
		left: -10%;
	}
}

@media only screen and (max-width: 1199px) {
	.e-feature-img.two {
		left: 0;
	}
}

.feature-wrap {
	padding-top: 90px;
	padding-bottom: 8px;
}

@media only screen and (max-width: 575px) {
	.feature-wrap {
		padding-top: 60px;
	}
}

.feature-wrap .single-service {
	margin-bottom: 80px;
}

@media only screen and (max-width: 575px) {
	.feature-wrap .single-service {
		margin-bottom: 50px;
		padding: 0;
	}
}

.feature-wrap .single-service.proto {
	margin-bottom: 60px;
}

@media only screen and (max-width: 575px) {
	.feature-wrap .single-service.proto {
		margin-bottom: 40px;
	}
}

.feature-wrap.plug {
	padding-top: 80px;
	padding-bottom: 0;
	background-color: #4e2fa9;
}

.feature-wrap.plug .single-service {
	background: 0 0;
	margin-bottom: 80px;
}

.feature-wrap.plug .single-service .service-content h3 {
	color: #fff;
}

.feature-wrap.proto {
	margin: 0 30px;
	padding-top: 60px;
	padding-bottom: 40px;
	background-color: #d9e5ff;
	border-radius: 60px;
	position: relative;
	z-index: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.feature-wrap.proto {
		margin: 0 -50px;
	}
}

@media only screen and (max-width: 1199px) {
	.feature-wrap.proto {
		margin: 0 -50px;
	}
}

@media only screen and (max-width: 991px) {
	.feature-wrap.proto {
		margin: 0;
	}
}

@media only screen and (max-width: 575px) {
	.feature-wrap.proto {
		padding-top: 40px;
		padding-bottom: 20px;
	}
}

.feature-wrap.proto:after {
	content: '';
	position: absolute;
	background-color: #f1f5fe;
	width: calc(100% + 60px);
	height: 100%;
	left: -30px;
	bottom: 20px;
	border-radius: 60px;
	z-index: -1;
}

.tab-content.app2-tab div > img {
	-webkit-animation: banner3Shake 10s ease-in infinite;
	animation: banner3Shake 10s ease-in infinite;
}

.choose-card-wrap,
.get-card-wrap {
	position: relative;
}

.choose-card-wrap .feature-img,
.get-card-wrap .feature-img {
	margin-bottom: -140px;
}

@media only screen and (max-width: 991px) {
	.choose-card-wrap .feature-img,
	.get-card-wrap .feature-img {
		margin-bottom: 60px;
	}
}

.choose-card-wrap .pay-bg3,
.choose-card-wrap .pay-bg4,
.get-card-wrap .pay-bg3,
.get-card-wrap .pay-bg4 {
	position: absolute;
	width: 1030%;
	height: 100%;
	background: #f9f9f9;
	left: -1000%;
	top: 0;
	z-index: -1;
}

@media only screen and (max-width: 991px) {
	.choose-card-wrap .pay-bg3,
	.choose-card-wrap .pay-bg4,
	.get-card-wrap .pay-bg3,
	.get-card-wrap .pay-bg4 {
		display: none;
	}
}

.get-card-wrap {
	padding-bottom: 140px;
}

@media only screen and (max-width: 991px) {
	.get-card-wrap {
		padding-bottom: 0;
	}
}

.choose-card-wrap {
	position: relative;
}

.choose-card-wrap .feature-img {
	margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
	.choose-card-wrap .feature-img {
		margin-bottom: 60px;
	}
}

.choose-card-wrap .pay-bg4 {
	left: auto;
	right: -1000%;
}

.single-feature-wrap:nth-child(even) {
	margin-top: 50px;
}

@media only screen and (max-width: 767px) {
	.single-feature-wrap:nth-child(even) {
		margin-top: 0;
	}
}

@media only screen and (max-width: 767px) {
	.single-feature-wrap:not(:last-child) {
		margin-bottom: 30px;
	}
}

.single-feature {
	padding: 50px 40px 44px;
	background: #fffcf1;
	border-top-left-radius: 50px;
}

@media only screen and (max-width: 1199px) {
	.single-feature {
		padding: 40px 20px 34px;
	}
}

.single-feature .feature-icon {
	margin-bottom: 35px;
}

.single-feature .feature-content {
	margin-bottom: 0;
}

.single-feature .feature-content h3 {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 6px;
	color: #292929;
}

@media only screen and (max-width: 575px) {
	.single-feature .feature-content h3 {
		font-size: 24px;
	}
}

.single-feature .feature-content p {
	font-size: 20px;
}

@media only screen and (max-width: 575px) {
	.single-feature .feature-content p {
		font-size: 16px;
	}
}

.single-feature.two {
	background: #f8fbff;
	border-top-left-radius: 0;
	border-bottom-right-radius: 50px;
}

.single-feature.three {
	background: #f9fff1;
}

.single-feature.four {
	background: #fff3f1;
	border-top-left-radius: 0;
	border-top-right-radius: 50px;
}

.single-feature.app {
	text-align: center;
	background-color: transparent;
	padding: 0;
	margin-bottom: 40px;
}

.single-feature.app .feature-icon {
	margin-bottom: 45px;
}

@media only screen and (max-width: 479px) {
	.single-feature.app .feature-icon {
		margin-bottom: 30px;
	}
}

.single-feature.app .feature-content h3 {
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 20px;
}

.single-feature.app2 {
	text-align: center;
	background-color: transparent;
	padding: 0;
	margin-bottom: 40px;
}

.single-feature.app2 .feature-icon {
	margin-bottom: 30px;
}

.single-feature.app2 .feature-icon .circle {
	position: relative;
	width: 172px;
	height: 172px;
}

.single-feature.app2 .feature-icon .circle .svg,
.single-feature.app2 .feature-icon .circle img {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.single-feature.app2 .feature-icon .circle.left:after {
	position: absolute;
	content: '';
	z-index: -1;
	height: 100%;
	width: 50%;
	background: #ebf6ff;
	border-bottom-left-radius: 172px;
	border-top-left-radius: 172px;
	left: 0;
	top: 0;
}

.single-feature.app2 .feature-icon .circle.bottom:after {
	position: absolute;
	content: '';
	z-index: -1;
	height: 50%;
	width: 100%;
	background: #e2fff9;
	border-bottom-left-radius: 172px;
	border-bottom-right-radius: 172px;
	left: 0;
	top: auto;
	bottom: 0;
}

.single-feature.app2 .feature-icon .circle.right:after {
	position: absolute;
	content: '';
	z-index: -1;
	height: 100%;
	width: 50%;
	background: #fff6f3;
	border-bottom-right-radius: 172px;
	border-top-right-radius: 172px;
	left: auto;
	right: 0;
	top: 0;
}

.single-feature.app2 .feature-content h3 {
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 20px;
}

.single-feature.style--two {
	background: 0 0;
	border-radius: 0;
	text-align: center;
}

.feature-box {
	position: relative;
}

@media only screen and (max-width: 991px) {
	.feature-box {
		margin-bottom: 80px;
	}
}

.feature-box .ebook-reveal,
.feature-box:after {
	background: #f8fbff;
	position: absolute;
	width: 1116%;
	height: 100%;
	content: '';
	left: -1000%;
	top: 0;
	z-index: -1;
	border-top-right-radius: 150px;
}

.feature-box:before {
	position: absolute;
	content: '01.';
	left: 0;
	top: -50px;
	font-size: 150px;
	font-weight: 600;
	line-height: 1;
}

@media only screen and (max-width: 575px) {
	.feature-box:before {
		font-size: 100px;
		top: -30px;
	}
}

.feature-box .img {
	margin-bottom: 60px;
}

.feature-box h2 {
	font-weight: 600;
	margin-bottom: 18px;
}

@media only screen and (max-width: 575px) {
	.feature-box h2 {
		font-size: 30px;
	}
}

.feature-box.two {
	margin-top: 140px;
}

@media only screen and (max-width: 991px) {
	.feature-box.two {
		margin-top: 0;
	}
}

.feature-box.two:after {
	width: 1116%;
	height: 100%;
	left: auto;
	right: -1000%;
	border-top-right-radius: 0;
	border-top-left-radius: 150px;
}

.feature-box.two:before {
	content: '02.';
}

.feature-box.two.four:after {
	border-radius: 0 0 0 150px;
}

.feature-box.two.four:before {
	content: '04.';
}

.feature-box.three:after {
	border-radius: 0 0 150px 0;
}

.feature-box.three:before {
	content: '03.';
}

.single-feature.box {
	text-align: center;
	background: #dff5ff;
	padding: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	margin-bottom: 30px;
}

.single-feature.box h2 {
	font-weight: 500;
	font-size: 60px;
	color: #21bcff;
	margin-bottom: 5px;
}

.single-feature.box p {
	font-weight: 400;
	text-transform: capitalize;
	color: #292929;
}

.single-feature.box.box2 {
	background: #fff3eb;
}

.single-feature.box.box2 h2 {
	color: #f99c5e;
}

.single-feature.box.box3 {
	background: #dffff6;
}

.single-feature.box.box3 h2 {
	color: #2dcea5;
}

.single-feature.box.box4 {
	background: #e9f3ff;
}

.single-feature.box.box4 h2 {
	color: #0472fa;
}

.plugin-feature-bg,
.plugin-feature-bg2 {
	position: absolute;
	left: 0;
	top: 40px;
	width: 40%;
	height: calc(100% - 180px);
	background-color: #857dfb;
}

@media only screen and (max-width: 991px) {
	.plugin-feature-bg,
	.plugin-feature-bg2 {
		height: calc(55% - 100px);
		width: 70%;
	}
}

@media only screen and (max-width: 767px) {
	.plugin-feature-bg,
	.plugin-feature-bg2 {
		display: none;
	}
}

.plugin-feature-bg2 {
	left: auto;
	right: 0;
	bottom: 140px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.feature-img .f-bg {
		height: 560px;
	}

	.feature-img .f-img.crm2,
	.feature-img .f-img.crm3 {
		top: 60px;
	}
}

.email-fbg {
	background-color: #fafafa;
}

.feature-one-content {
	position: relative;
	z-index: 1;
}

.feature-one-content h2 {
	color: #292929;
	font-weight: 600;
	margin-bottom: 15px;
}

.feature-one-content p {
	margin-bottom: 40px;
}

.feature-one-content.two h2 {
	line-height: 1.23;
}

.feature-one-content.two p {
	margin-bottom: 32px;
}

.feature-two-content h3 {
	font-weight: 500;
	font-size: 22px;
	color: #292929;
	margin-bottom: 25px;
}

.integration-bg {
	background-color: #f5f8ff;
}

.work-slider .work-slide-text h2 {
	color: #292929;
	font-size: 32px;
	font-weight: 600;
	margin-bottom: 20px;
}

.work-slider .work-slide-text p {
	font-size: 22px;
}

@media only screen and (max-width: 991px) {
	.work-slider .work-slide-image {
		margin-bottom: 60px;
	}
}

.work-slider-dots {
	margin-top: -5px;
	margin-bottom: 55px;
	padding: 0 40px;
}

@media only screen and (max-width: 991px) {
	.work-slider-dots {
		display: none !important;
	}
}

.work-slider-dots .owl-stage {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.work-slider-dots .owl-item {
	text-align: center;
}

.work-slider-dots .active .dots-count {
	padding-bottom: 17px;
	position: relative;
	font-size: 26px;
	line-height: 1.2;
	font-weight: 500;
	color: #bcbcbc;
	cursor: pointer;
	display: inline-block;
}

@media only screen and (max-width: 1199px) {
	.work-slider-dots .active .dots-count {
		font-size: 22px;
	}
}

.work-slider-dots .active .dots-count .process-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: transparent;
}

.work-slider-dots .active .dots-count .process-bar-active {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 2px;
	background-color: #232323;
}

.work-slider-dots .active.current .dots-count {
	color: #232323;
}

.work-slider-dots .active.current .dots-count .process-bar {
	background-color: #dbdbdb;
}

.work-slider-dots .active.current .dots-count .process-bar-active {
	-webkit-transition: width 4.9s;
	-o-transition: width 4.9s;
	transition: width 4.9s;
	width: 100%;
}

.row.feature-bg {
	padding-left: 80px;
	padding-right: 80px;
	position: relative;
}

@media only screen and (max-width: 575px) {
	.row.feature-bg {
		padding-left: 0;
		padding-right: 0;
	}
}

.row.feature-bg:after {
	background-color: #f3fff7;
	position: absolute;
	content: '';
	width: 100%;
	height: calc(100% + 20px);
	left: 0;
	top: -120px;
	z-index: -1;
}

.feature-wrapper {
	padding-left: 80px;
	padding-right: 80px;
	position: relative;
	z-index: 1;
}

@media only screen and (max-width: 575px) {
	.feature-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

.feature-wrapper:after {
	background-color: #f1f5fe;
	position: absolute;
	content: '';
	width: 100%;
	height: 70%;
	left: 0;
	top: -100px;
	z-index: -1;
}

@media only screen and (max-width: 991px) {
	.feature-wrapper:after {
		height: 90%;
		top: -80px;
	}
}

@media only screen and (max-width: 575px) {
	.feature-wrapper:after {
		height: 100%;
		top: -60px;
		left: -15px;
		width: calc(100% + 30px);
	}
}

.pos-feature-slider .owl-stage-outer {
	padding: 20px;
	margin: -20px;
}

.feature-single {
	background-color: #fff;
	padding: 60px 20px;
	text-align: center;
}

.feature-single .feature-icon {
	margin-bottom: 28px;
}

.feature-single .feature-content h3 {
	font-weight: 500;
	color: #292929;
}

.feature-single.retailer {
	margin-bottom: 50px;
}

@media only screen and (max-width: 991px) {
	.feature-single.retailer {
		margin-bottom: 30px;
	}
}

.feature-single.retailer .feature-content {
	margin: 0;
}

.feature-single.retailer:hover .feature-icon {
	-webkit-animation: fadeInUp10 0.5s ease-in;
	animation: fadeInUp10 0.5s ease-in;
}

@media only screen and (max-width: 767px) {
	.trapezium-section {
		margin-bottom: 50px;
	}
}

.trapezium {
	position: relative;
	padding: 80px 20px;
	text-align: center;
	margin-bottom: 100px;
}

@media only screen and (max-width: 767px) {
	.trapezium {
		margin-bottom: 50px;
	}
}

.trapezium:before {
	content: '';
	position: absolute;
	background-color: #f1f5fe;
	width: 114%;
	top: 0;
	bottom: 0;
	left: -1%;
	right: 0;
	z-index: -1;
	-webkit-transform: perspective(50em) rotateX(30deg);
	transform: perspective(800px) rotateY(30deg);
}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
	.trapezium:before {
		left: -4%;
	}
}

@media only screen and (max-width: 767px) {
	.trapezium:before {
		left: 15px;
		width: 112%;
	}
}

@media only screen and (max-width: 575px) {
	.trapezium:before {
		left: 0;
	}
}

.trapezium.two:before {
	left: auto;
	right: -1%;
	-webkit-transform: perspective(50em) rotateX(-30deg);
	transform: perspective(800px) rotateY(-30deg);
}

@media only screen and (max-width: 1199px) {
	.trapezium.two:before {
		right: -3%;
	}
}

@media only screen and (max-width: 767px) {
	.trapezium.two:before {
		right: 15px;
	}
}

@media only screen and (max-width: 575px) {
	.trapezium.two:before {
		right: 0;
	}
}

.trapezium.two:hover:before {
	left: auto;
	right: 0;
}

.trapezium:hover:before {
	width: 100%;
	left: 0;
	-webkit-transform: perspective(50em) rotateX(0);
	transform: perspective(800px) rotateY(0);
}

.trapezium .feature-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100px;
	height: 100px;
	background-color: #fff;
	margin: 0 auto 30px;
}

.trapezium .feature-content h3 {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 10px;
}

.trapezium .feature-content p {
	font-size: 18px;
	max-width: 390px;
	margin-left: auto;
	margin-right: auto;
}

.tab-btn.proto {
	margin-bottom: 40px;
}

.tab-btn.proto li {
	display: inline-block;
	font-size: 26px;
	font-weight: 500;
	cursor: pointer;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #bcbcbc;
}

@media only screen and (max-width: 379px) {
	.tab-btn.proto li {
		font-size: 24px;
	}
}

.tab-btn.proto li:not(:last-child) {
	margin-right: 65px;
}

@media only screen and (max-width: 767px) {
	.tab-btn.proto li:not(:last-child) {
		margin-right: 30px;
	}
}

@media only screen and (max-width: 575px) {
	.tab-btn.proto li:not(:last-child) {
		margin-bottom: 10px;
	}
}

.tab-btn.proto li:after {
	position: absolute;
	content: '';
	width: 0%;
	height: 2px;
	left: 50%;
	bottom: 0;
	-webkit-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.tab-btn.proto li.active:after {
	width: 100%;
	left: 0;
}

.tab-content.proto img {
	margin-bottom: 30px;
}

.tab-content.proto img:not(:last-child) {
	margin-right: 55px;
}

@media only screen and (max-width: 1199px) {
	.tab-content.proto img:not(:last-child) {
		margin-right: 30px;
	}
}

@media only screen and (max-width: 479px) {
	.tab-content.proto img:nth-child(even) {
		margin-right: 0;
	}
}

.tab-content.proto div.active {
	-webkit-animation: fadeInUp10 0.4s linear 0s forwards;
	animation: fadeInUp10 0.4s linear 0s forwards;
}

.insight-title {
	padding: 140px 80px;
	height: 100%;
	border-radius: 30px 0 0 30px;
}

.insight-title .title-content {
	max-width: 440px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	.insight-title {
		padding: 100px 40px;
	}
}

@media only screen and (max-width: 1199px) {
	.insight-title {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		padding: 100px 30px;
	}
}

@media only screen and (max-width: 991px) {
	.insight-title {
		border-radius: 30px 30px 0 0;
	}
}

@media only screen and (max-width: 575px) {
	.insight-title {
		padding: 80px 30px;
	}
}

.title-content h2 {
	margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
	.title-content h2 {
		font-size: 34px;
	}
}

.title-content p {
	margin-bottom: 44px;
}

@media only screen and (max-width: 1199px) {
	.title-content {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		height: auto;
	}
}

.insight-data {
	background-color: #fcf3f1;
	border-radius: 0 30px 30px 0;
	padding: 75px 40px 68px;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	.insight-data {
		padding: 90px 40px 100px;
	}
}

@media only screen and (max-width: 1199px) {
	.insight-data {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		padding: 70px 30px 78px;
	}
}

@media only screen and (max-width: 991px) {
	.insight-data {
		border-radius: 0 0 30px 30px;
	}
}

@media only screen and (max-width: 575px) {
	.insight-data {
		padding: 50px 30px 58px;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

.single-data {
	text-align: center;
	padding: 15px;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	margin-bottom: 15px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.single-data {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 45%;
		flex: 0 0 45%;
	}
}

@media only screen and (max-width: 575px) {
	.single-data {
		padding: 40px 0;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
}

.single-data strong {
	font-size: 80px;
	font-weight: 600;
	line-height: 1.3;
}

.single-data h3 {
	font-weight: 400;
	color: #292929;
	line-height: 1.3;
}

@media only screen and (max-width: 479px) {
	.single-data h3 {
		font-size: 22px;
	}
}

@media only screen and (max-width: 767px) {
	.crm-testimonial.owl-carousel > .owl-nav {
		display: none;
	}
}

.crm-testimonial.owl-carousel > .owl-nav button {
	top: -175px;
	background: 0 0 !important;
}

.crm-testimonial.owl-carousel > .owl-nav button i {
	display: none;
}

.crm-testimonial.owl-carousel > .owl-nav button:hover .svg {
	-webkit-animation: arrowRight 0.8s linear 0.2s infinite;
	animation: arrowRight 0.8s linear 0.2s infinite;
}

.crm-testimonial.owl-carousel > .owl-nav button:hover .svg g path {
	stroke: #ebd281;
}

.crm-testimonial.owl-carousel > .owl-nav button:hover .svg.left-svg {
	-webkit-animation: arrowLeft 0.8s linear 0.2s infinite;
	animation: arrowLeft 0.8s linear 0.2s infinite;
}

.testimonial-single {
	background-color: #fff;
	padding: 60px 40px 55px;
}

@media only screen and (max-width: 575px) {
	.testimonial-single {
		padding: 40px 20px 35px;
	}
}

.testimonial-single .ts-top {
	margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
	.testimonial-single .ts-top {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.testimonial-single .tst-content .ts-img {
	margin-right: 20px;
}

.testimonial-single .tst-content .ts-img img {
	width: 70px;
	height: 70px;
}

.testimonial-single .tst-content .content h4 {
	font-weight: 500;
	color: #57548d;
}

.testimonial-single .tst-content .content span {
	font-size: 14px;
	font-weight: 300;
	display: block;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.testimonial-single .tst-content {
		width: 100%;
	}
}

@media only screen and (max-width: 575px) {
	.testimonial-single .review {
		margin-top: -15px;
		margin-left: 5px;
	}
}

.testimonial-single .review strong {
	font-weight: 600;
	font-size: 20px;
}

.testimonial-single .ts-bottom p {
	position: relative;
	padding-left: 28px;
}

.testimonial-single .ts-bottom p:before {
	content: '"';
	position: absolute;
	left: -3px;
	top: 0;
	font-size: 60px;
	line-height: 1;
}

.testimonial-single.style--two {
	padding: 50px 50px 46px;
}

@media only screen and (max-width: 1199px) {
	.testimonial-single.style--two {
		padding: 50px 20px 46px;
	}
}

@media only screen and (max-width: 575px) {
	.testimonial-single.style--two {
		padding: 30px 30px 26px;
	}
}

.testimonial-single.style--two .tst-content .ts-img {
	margin-right: 20px;
}

.testimonial-single.style--two .tst-content .ts-img img {
	width: 80px;
	height: 80px;
}

.testimonial-single.style--two .tst-content .content .star-rating {
	margin-bottom: 6px;
}

.testimonial-single.style--two .tst-content .content .star-rating i {
	font-size: 16px;
}

.testimonial-single.style--two .tst-content .content h5 {
	font-weight: 600;
	font-size: 18px;
	color: #5f52dc;
}

.testimonial-single.style--two .tst-content .content span {
	font-size: 14px;
	font-weight: 300;
	display: block;
}

.testimonial-single.style--two .ts-bottom p {
	padding-left: 0;
	font-size: 20px;
	font-weight: 300;
}

.testimonial-single.style--two .ts-bottom p:before {
	display: none;
}

@media only screen and (max-width: 479px) {
	.testimonial-single.style--two .ts-bottom p {
		font-size: 16px;
	}
}

.testimonial-single.style--two.book {
	background: #fff3f1;
	border-radius: 0 50px 0 0;
	padding: 60px;
}

@media only screen and (max-width: 1199px) {
	.testimonial-single.style--two.book {
		padding: 30px;
	}
}

.testimonial-single.style--two.book .ts-bottom p {
	font-size: 18px;
}

.testimonial-single.style--two.plug {
	background: #fff;
}

.testimonial-single.style--three {
	padding: 60px 40px 54px;
	margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
	.testimonial-single.style--three {
		padding: 30px 30px 26px;
	}
}

.testimonial-single.style--three .ts-top {
	margin-bottom: 30px;
}

.testimonial-single.style--three .tst-content .ts-img {
	margin-right: 30px;
	max-width: 44%;
}

.testimonial-single.style--three .tst-content .ts-img img {
	width: auto;
	height: auto;
	border-radius: 0;
}

.testimonial-single.style--three .tst-content .content h3 {
	font-weight: 600;
	color: #292929;
}

.testimonial-single.style--three .tst-content .content span {
	font-size: 18px;
	font-weight: 400;
	display: block;
	margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
	.testimonial-single.style--three .tst-content {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.testimonial-single.style--three .tst-content .ts-img {
		margin: 0;
		margin-bottom: 30px;
		max-width: 60%;
	}
}

@media only screen and (max-width: 991px) {
	.testimonial-single.style--three .tst-content {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.testimonial-single.style--three .tst-content .ts-img {
		margin-bottom: 0;
		margin-right: 30px;
		max-width: 44%;
	}
}

@media only screen and (max-width: 767px) {
	.testimonial-single.style--three .tst-content {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.testimonial-single.style--three .tst-content .ts-img {
		margin: 0;
		margin-bottom: 30px;
		max-width: 60%;
	}
}

.testimonial-single.style--three .ts-bottom p {
	padding-left: 0;
	font-size: 20px;
	font-weight: 300;
}

.testimonial-single.style--three .ts-bottom p:before {
	display: none;
}

@media only screen and (max-width: 479px) {
	.testimonial-single.style--three .ts-bottom p {
		font-size: 16px;
	}
}

.book-review-slider .owl-item:nth-child(even) .testimonial-single {
	margin-top: 50px;
	background: #f9fff1;
	border-radius: 0 0 0 50px;
}

@media only screen and (max-width: 991px) {
	.book-review-slider .owl-item:nth-child(even) .testimonial-single {
		margin-top: 0;
	}
}

.rating {
	font-size: 20px;
	color: #5c6b56;
}

.rating strong {
	font-size: 60px;
	font-weight: 600;
	line-height: 1.5;
	display: block;
}

@media only screen and (max-width: 575px) {
	.rating.student-rating {
		margin-top: 20px;
	}
}

.customer-review-slider.owl-carousel .owl-stage-outer {
	padding: 15px;
	margin: -15px;
}

.email-testimonial-slider .testimonial-item h3 {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: 25px;
}

.email-testimonial-slider .testimonial-item h3 span {
	font-size: 20px;
	font-weight: 300;
	color: #292929;
}

.email-testimonial-slider .testimonial-item P {
	color: #292929;
	font-size: 22px;
	line-height: 1.6;
	font-weight: 500;
}

@media only screen and (max-width: 479px) {
	.email-testimonial-slider .testimonial-item P {
		font-size: 16px;
	}
}

.email-testimonial-slider.host .testimonial-item h3 {
	font-size: 24px;
	margin-bottom: 6px;
}

.email-testimonial-slider.host .testimonial-item P {
	font-size: 20px;
	font-weight: 300;
	line-height: 1.8;
	margin-bottom: 24px;
}

@media only screen and (max-width: 479px) {
	.email-testimonial-slider.host .testimonial-item P {
		font-size: 16px;
	}
}

.email-testimonial-slider.app2 .testimonial-item h3 {
	font-size: 20px;
	color: #292929;
	font-weight: 600;
	margin-bottom: 6px;
}

.email-testimonial-slider.app2 .testimonial-item h3 span {
	font-size: 16px;
	display: block;
	margin-top: 7px;
}

.email-testimonial-slider.app2 .testimonial-item P {
	font-size: 22px;
	font-weight: 300;
	font-style: italic;
	line-height: 1.9;
	margin-bottom: 46px;
}

@media only screen and (max-width: 479px) {
	.email-testimonial-slider.app2 .testimonial-item P {
		font-size: 18px;
	}
}

.email-clients-slider .clients-item {
	padding: 0 20px;
}

@media only screen and (max-width: 991px) {
	.email-clients-slider .clients-item {
		padding: 0;
	}
}

.email-clients-slider.host .clients-item {
	padding: 0;
}

@media only screen and (max-width: 1199px) {
	.logo-carousel.email {
		padding-top: 50px;
	}
}

@media only screen and (max-width: 767px) {
	.logo-carousel.email {
		padding-top: 0;
	}
}

.app-testimonial-slider {
	background-position: center bottom;
	background-size: auto;
}

@media only screen and (max-width: 575px) {
	.app-testimonial-slider {
		background-size: 180% 30%;
	}
}

.app-testimonial-slider .single-slide {
	text-align: center;
	padding-bottom: 100px;
}

@media only screen and (max-width: 575px) {
	.app-testimonial-slider .single-slide {
		padding-bottom: 40px;
	}
}

.app-testimonial-slider .single-slide p {
	max-width: 810px;
	font-size: 24px;
	font-weight: 300;
	color: #292929;
	margin: 0 auto 50px;
	font-style: italic;
}

@media only screen and (max-width: 575px) {
	.app-testimonial-slider .single-slide p {
		font-size: 18px;
	}
}

.app-testimonial-slider .single-slide img {
	margin-bottom: 30px;
	width: auto;
	display: inline-block;
}

@media only screen and (max-width: 575px) {
	.app-testimonial-slider .single-slide img {
		width: 200px;
	}
}

.app-testimonial-slider .single-slide h4 {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	text-transform: capitalize;
}

.app-testimonial-slider .single-slide h4 span {
	font-size: 18px;
	font-weight: 400;
	display: block;
	margin-top: 5px;
}

.testimonial-slider.student-review .owl-stage-outer {
	padding: 20px;
	margin: -20px;
}

.accordion > .card {
	padding: 40px 0;
	border: 0;
	background: #fff;
	overflow: hidden;
	margin-bottom: 30px;
}

.accordion > .card.active .accordion-title {
	border-bottom-color: #eee;
	padding-bottom: 30px;
}

.accordion > .card.active .accordion-title > h3:after {
	font-family: FontAwesome;
	content: '';
}

.accordion > .card .accordion-title {
	padding: 18px 0 10px;
	margin-left: 300px;
	margin-right: 100px;
	border-bottom: 1px solid transparent;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.accordion > .card .accordion-title {
		margin-left: 250px;
	}
}

@media only screen and (max-width: 1199px) {
	.accordion > .card .accordion-title {
		margin-left: 200px;
	}
}

@media only screen and (max-width: 991px) {
	.accordion > .card .accordion-title {
		margin-left: 30px;
		margin-right: 30px;
		margin-top: 100px;
	}
}

@media only screen and (max-width: 575px) {
	.accordion > .card .accordion-title {
		margin-left: 15px;
		margin-right: 15px;
	}
}

.accordion > .card .accordion-title > h3 {
	font-size: 30px;
	color: #292929;
	margin-bottom: 20px;
	font-weight: 600;
	position: relative;
}

@media only screen and (max-width: 767px) {
	.accordion > .card .accordion-title > h3 {
		font-size: 24px;
	}
}

.accordion > .card .accordion-title > h3:after {
	font-family: FontAwesome;
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 30px;
	font-weight: 400;
	cursor: pointer;
}

.accordion > .card .accordion-title p {
	margin-bottom: 6px;
	max-width: 900px;
}

@media only screen and (max-width: 767px) {
	.accordion > .card .accordion-title p {
		font-size: 16px;
	}
}

.accordion > .card .accordion-title .accordion-title-meta span {
	display: inline-block;
	font-size: 18px;
	color: #292929;
	font-weight: 500;
}

@media only screen and (max-width: 767px) {
	.accordion > .card .accordion-title .accordion-title-meta span {
		font-size: 14px;
	}
}

.accordion
	> .card
	.accordion-title
	.accordion-title-meta
	span:not(:last-child) {
	margin-right: 40px;
}

@media only screen and (max-width: 767px) {
	.accordion
		> .card
		.accordion-title
		.accordion-title-meta
		span:not(:last-child) {
		margin-right: 20px;
	}
}

.accordion > .card .accordion-title .accordion-absolute {
	text-align: center;
	position: absolute;
	left: 100px;
	top: 79px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.accordion > .card .accordion-title .accordion-absolute {
		left: 75px;
	}
}

@media only screen and (max-width: 1199px) {
	.accordion > .card .accordion-title .accordion-absolute {
		top: 100px;
		left: 50px;
	}
}

@media only screen and (max-width: 991px) {
	.accordion > .card .accordion-title .accordion-absolute {
		top: 50px;
		left: 30px;
	}
}

@media only screen and (max-width: 575px) {
	.accordion > .card .accordion-title .accordion-absolute {
		left: 15px;
	}
}

.accordion > .card .accordion-title .accordion-absolute h3 {
	font-weight: 600;
	color: #292929;
}

.accordion > .card .accordion-title .accordion-absolute .svg {
	margin-bottom: 17px;
}

.accordion > .card .accordion-content {
	max-width: 900px;
	margin-left: 300px;
	margin-top: 31px;
	margin-right: 100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.accordion > .card .accordion-content {
		margin-left: 250px;
	}
}

@media only screen and (max-width: 1199px) {
	.accordion > .card .accordion-content {
		margin-left: 200px;
	}
}

@media only screen and (max-width: 991px) {
	.accordion > .card .accordion-content {
		margin-left: 15px;
		margin-right: 15px;
	}
}

.lecture-container {
	margin-bottom: 15px;
}

.lecture-container .left-content {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
}

.lecture-container .left-content .play-circle {
	margin-right: 20px;
	display: inline-block;
}

.lecture-container .left-content .title {
	font-size: 18px;
}

@media only screen and (max-width: 767px) {
	.lecture-container .left-content .title {
		font-size: 14px;
	}
}

.lecture-container .left-content .title a {
	color: #292929;
}

.lecture-container .details {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.lecture-container .details .preview {
	width: 30%;
	display: block;
	text-align: right;
}

@media only screen and (max-width: 767px) {
	.lecture-container .details .preview {
		width: 40%;
	}
}

.lecture-container .details .preview .preview-text {
	width: 100%;
}

.lecture-container .details .preview-text {
	font-size: 18px;
	font-weight: 600;
	width: 30%;
	display: block;
	text-align: right;
}

@media only screen and (max-width: 767px) {
	.lecture-container .details .preview-text {
		font-size: 14px;
		width: 40%;
	}
}

.lecture-container .details .preview-text .svg {
	margin-right: 23px;
}

@media only screen and (max-width: 767px) {
	.lecture-container .details .preview-text .svg {
		margin-right: 15px;
	}
}

.lecture-container .details .content-summary {
	font-size: 18px;
}

@media only screen and (max-width: 767px) {
	.lecture-container .details .content-summary {
		font-size: 14px;
	}
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
	border-radius: 20px;
}

.accordion > .card:first-of-type {
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
}

.accordion > .card:last-of-type {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.chaptar .chaptar-box {
	padding: 40px 30px;
	background: #f8fbff;
}

.chaptar .chaptar-box:not(:last-child) {
	margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
	.chaptar .chaptar-box {
		margin-bottom: 20px;
	}
}

.chaptar .chaptar-box h3 {
	color: #292929;
	font-size: 26px;
	font-weight: 600;
	margin-bottom: 10px;
	cursor: pointer;
}

.chaptar .chaptar-box h3 span {
	margin-right: 5px;
}

@media only screen and (max-width: 575px) {
	.chaptar .chaptar-box {
		padding: 30px 20px;
	}

	.chaptar .chaptar-box h3 {
		font-size: 20px;
	}

	.chaptar .chaptar-box p {
		font-size: 16px;
	}
}

.chaptar .chaptar-box.active h3,
.chaptar .chaptar-box.active p,
.chaptar .chaptar-box:hover h3,
.chaptar .chaptar-box:hover p {
	color: #fff;
}

@media only screen and (max-width: 991px) {
	.chaptar-img {
		margin-bottom: 40px;
	}
}

.price-box {
	padding: 50px 60px 60px;
	background: #fff;
	margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
	.price-box {
		padding: 30px 30px 40px;
	}
}

.price-box .price-head {
	margin-bottom: 12px;
}

.price-box .price-head span {
	font-size: 18px;
}

.price-box .price-head span strong {
	font-size: 60px;
	font-weight: 600;
	line-height: 1.48;
	padding-right: 10px;
}

.price-box .price-head span .d-price {
	position: relative;
	bottom: -7px;
}

.price-box .price-head span del {
	font-size: 24px;
	font-weight: 600;
	line-height: 1;
	color: #d5d5d5;
}

.price-box .price-head p {
	font-weight: 300;
	max-width: 320px;
}

.price-box.two .price-head span strong {
	color: #fff;
}

.price-box.two .price-head span .d-price del {
	color: #cbfff5;
}

.price-box.two ul.list-check li {
	color: #fff;
}

.price-box.two ul.list-check li:after {
	color: #fff;
}

.price-box .btn-crs.white,
.price-box .theme-input-group.style--four button.btn-crs,
.theme-input-group.style--four .price-box button.btn-crs {
	background: #fff;
}

.price-box .btn-crs.white:hover,
.price-box .theme-input-group.style--four button.btn-crs:hover,
.theme-input-group.style--four .price-box button.btn-crs:hover {
	color: #fff;
}

.pricing-toogle {
	line-height: 1;
}

@media only screen and (max-width: 991px) {
	.pricing-toogle.host {
		margin-top: 30px;
	}
}

.pricing-toogle.host .toggler--is-active {
	color: #180d5b;
}

.toggle,
.toggler {
	display: inline-block;
	vertical-align: middle;
}

.toggler {
	color: #292929;
	-webkit-transition: 0.2s;
	-o-transition: 0.2s;
	transition: 0.2s;
	font-weight: 400;
	font-size: 16px;
	cursor: pointer;
	line-height: 1;
}

.toggler--is-active {
	color: #ff5b83;
}

.b {
	display: block;
}

.toggle {
	position: relative;
	width: 60px;
	height: 26px;
	border-radius: 100px;
	overflow: hidden;
	margin: 0 16px;
}

.check {
	position: absolute;
	display: block;
	cursor: pointer;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 6;
}

.check:checked ~ .switch {
	right: 2px;
	left: 57.5%;
	-webkit-transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	-o-transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	-webkit-transition-property: left, right;
	-o-transition-property: left, right;
	transition-property: left, right;
	-webkit-transition-delay: 0.08s, 0s;
	-o-transition-delay: 0.08s, 0s;
	transition-delay: 0.08s, 0s;
}

.switch {
	position: absolute;
	left: 2px;
	top: 2px;
	bottom: 2px;
	right: 57.5%;
	background-color: #fff;
	border-radius: 26px;
	z-index: 1;
	-webkit-transition-property: left, right;
	-o-transition-property: left, right;
	transition-property: left, right;
	-webkit-transition-delay: 0s, 0.08s;
	-o-transition-delay: 0s, 0.08s;
	transition-delay: 0s, 0.08s;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.hide {
	display: none;
}

.single-price {
	padding: 60px 100px;
	border: 1px solid #00418e;
	text-align: center;
	position: relative;
	z-index: 1;
}

.single-price .price-head {
	padding-bottom: 35px;
	border-bottom: 1px solid #bcbcbc;
}

.single-price .price-head h3 {
	font-size: 30px;
	font-weight: 600;
	color: #292929;
	margin-bottom: 8px;
}

.single-price .price-head p {
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 30px;
}

.single-price .price-head .price strong {
	font-size: 46px;
	line-height: 1.2;
}

.single-price .price-head > span {
	font-size: 16px;
	font-weight: 400;
}

.single-price .price-body .price-list {
	padding: 42px 0;
}

.single-price .price-body .price-list li {
	font-size: 22px;
	font-weight: 300;
}

.single-price .price-body .price-list li:not(:last-child) {
	margin-bottom: 5px;
}

.single-price.active:after {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: -1;
	-webkit-transform: scale(1.15, 1.06);
	-ms-transform: scale(1.15, 1.06);
	transform: scale(1.15, 1.06);
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.single-price.active:after {
		-webkit-transform: scale(1.18, 1.04);
		-ms-transform: scale(1.18, 1.04);
		transform: scale(1.18, 1.04);
	}
}

@media only screen and (max-width: 1199px) {
	.single-price.active:after {
		-webkit-transform: scale(1.21, 1.03);
		-ms-transform: scale(1.21, 1.03);
		transform: scale(1.21, 1.03);
	}
}

@media only screen and (max-width: 991px) {
	.single-price.active:after {
		-webkit-transform: scale(1, 1);
		-ms-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}

.single-price.active .price-head h3 {
	color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1880px) {
	.single-price {
		padding: 60px 50px;
	}
}

@media only screen and (max-width: 1199px) {
	.single-price {
		padding: 60px 50px;
	}
}

@media only screen and (max-width: 991px) {
	.single-price {
		margin-bottom: 30px;
	}
}

.single-price.app {
	background-color: #a7d3f6;
	border: none;
	background-position: center bottom;
	padding-bottom: 18px;
}

@media only screen and (max-width: 1199px) {
	.single-price.app {
		padding: 60px 30px 20px;
	}
}

.single-price.app .price-head {
	padding-bottom: 60px;
	border-bottom: 1px solid #efefef;
}

.single-price.app .price-head h3 {
	margin-bottom: 3px;
}

.single-price.app .price-head p {
	font-size: 16px;
	margin-bottom: 25px;
	color: #292929;
}

.single-price.app .price-body .price-list {
	padding-top: 50px;
	padding-bottom: 80px;
}

.single-price.app .price-body .price-list li {
	font-size: 20px;
	font-weight: 400;
	color: #292929;
}

.single-price.app .price-body .price-list li:not(:last-child) {
	margin-bottom: 12px;
}

.single-price.app .price-footer span {
	font-weight: 500;
	font-size: 18px;
	color: #fff;
}

.single-price.app .price-footer span strong {
	font-size: 40px;
	font-weight: 700;
	line-height: 1;
}

.single-price.app.two {
	background-color: #ffb99f;
}

.single-price.app.three {
	background-color: #86e9d4;
	margin-bottom: 0;
}

.single-price.app .price-footer .white-btn {
	color: #fff;
	text-transform: capitalize;
}

.single-price.app .price-footer .white-btn:hover {
	letter-spacing: 1px;
}

.single-price.app2 .price-head {
	padding-bottom: 30px;
}

.single-price.app2 .price-head h3 {
	margin-bottom: 3px;
}

.single-price.app2 .price-head span {
	color: #292929;
	font-size: 18px;
	font-weight: 500;
	margin-top: 12px;
}

.single-price.app2 .price-head span .link-btn {
	color: #5cb6fe;
}

.single-price.app2 .price-head span .link-btn:hover {
	color: #ff6773;
}

.single-price.app2 .price-body .price-list li {
	color: #707070;
}

.pkg .pkg-list {
	background-color: #fff;
	cursor: pointer;
	padding: 60px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	z-index: 2;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.pkg .pkg-list {
		padding: 60px 40px;
	}
}

@media only screen and (max-width: 575px) {
	.pkg .pkg-list {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 40px 20px;
	}
}

.pkg .pkg-list > span {
	position: absolute;
	width: 0;
	height: 0;
	display: block;
	z-index: -1;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: 0.7s;
	-o-transition: 0.7s;
	transition: 0.7s;
}

.pkg .pkg-list:hover > span {
	width: 300%;
	height: 2380px;
}

.pkg .pkg-list .left {
	margin-right: 20px;
}

@media only screen and (max-width: 575px) {
	.pkg .pkg-list .left {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

.pkg .pkg-list .left h3 {
	font-size: 30px;
	color: #292929;
	margin-bottom: 8px;
	font-weight: 600;
}

.pkg .pkg-list .left p {
	font-size: 18px;
	max-width: 320px;
}

@media only screen and (max-width: 575px) {
	.pkg .pkg-list .right {
		margin-right: auto;
	}
}

.pkg .pkg-list .right div {
	font-weight: 300;
	margin-bottom: 14px;
}

.pkg .pkg-list .right div strong {
	font-size: 46px;
	font-weight: 600;
	line-height: 1;
}

.pkg.active .pkg-list .left h3,
.pkg.active .pkg-list .left p,
.pkg:hover .pkg-list .left h3,
.pkg:hover .pkg-list .left p {
	color: #fff;
}

.pkg.active .pkg-list .right div,
.pkg:hover .pkg-list .right div {
	color: #fff;
}

.pkg-info {
	padding: 50px 60px 57px;
	background-color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.pkg-info {
		padding: 50px 33px 57px;
	}
}

@media only screen and (max-width: 1199px) {
	.pkg-info {
		padding: 40px 30px;
	}
}

@media only screen and (max-width: 991px) {
	.pkg-info {
		padding: 50px 60px;
	}
}

@media only screen and (max-width: 575px) {
	.pkg-info {
		padding: 40px 20px;
	}
}

.pkg-info h3.pkg-name {
	display: inline-block;
	background-color: #fff4de;
	color: #f7a400;
	margin-bottom: 40px;
	font-size: 30px;
	font-weight: 600;
	border-radius: 7px;
	padding: 15px 20px;
}

.pkg-info .price-feature li:not(:last-child) {
	margin-bottom: 36px;
}

.pkg-info .price-feature h3 {
	color: #292929;
	font-weight: 500;
	margin-bottom: 6px;
}

.pkg-info .price-feature p {
	font-size: 18px;
	line-height: 1.5;
}

.owl-carousel.logo-carousel .single-slide {
	text-align: center;
}

.owl-carousel.logo-carousel .owl-item img {
	display: inline-block;
	width: auto;
	opacity: 0.4;
}

.owl-carousel.logo-carousel .owl-item img:hover {
	opacity: 1;
	-webkit-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transform: scale(0.9);
}

.author-content {
	padding-left: 50px;
}

@media only screen and (max-width: 767px) {
	.author-content {
		padding-left: 0;
	}
}

.author-content .author-badge {
	font-size: 16px;
	padding-left: 35px;
	margin-bottom: 12px;
}

.author-content .author-badge:before {
	position: absolute;

	left: 0;
	top: 5px;
}

.author-content h2 {
	font-weight: 600;
}

.author-content span {
	font-size: 22px;
	margin-bottom: 10px;
	display: block;
}

.author-img {
	padding-left: 15px;
}

@media only screen and (max-width: 991px) {
	.author-img {
		padding-left: 50px;
		margin-bottom: 50px;
	}
}

@media only screen and (max-width: 767px) {
	.author-img {
		padding-left: 0;
	}
}

.author-newsletter h3 {
	font-weight: 600;
}

.accordion.management .accordion-card {
	border-bottom: 1px solid #eee;
	padding: 38px 0 34px 40px;
}

.accordion.management .accordion-card:first-child {
	border-top: 1px solid #eee;
}

.accordion.management .accordion-card.active .accordion-title h3:after {
	content: '\f106';
}

.accordion.management .accordion-title {
	position: relative;
}

.accordion.management .accordion-title h3 {
	color: #292929;
	cursor: pointer;
	font-weight: 500;
	font-size: 22px;
	position: relative;
	line-height: 1.2;
}

.accordion.management .accordion-title h3:after {
	content: '\f107';
	position: absolute;
	right: 20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #292929;
	font-family: fontAwesome;
}

.accordion.management .accordion-title .accordion-whatsign {
	position: absolute;
	left: -40px;
	top: -1px;
}

.accordion.management .accordion-content {
	margin-top: 15px;
}

.quick-links {
	margin-bottom: 40px;
}

.quick-links h3 {
	font-size: 30px;
	font-weight: 500;
	color: #292929;
	margin-bottom: 20px;
}

.quick-links ul li {
	margin-bottom: 10px;
}

.quick-links ul li a {
	color: #292929;
	font-size: 18px;
}

.quick-links ul li a i {
	margin-right: 5px;
}

.quick-links ul li a:hover {
	margin-left: 5px;
}

@media only screen and (max-width: 991px) {
	.filter-control {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.filter-control .filter-title {
		margin-bottom: 20px;
	}
}

@media only screen and (max-width: 479px) {
	.filter {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.filter-title h3 {
	font-weight: 600;
	font-size: 30px;
	color: #292929;
}

.custom-select {
	position: relative;
	background: #fff;
	height: 44px;
	padding: 0;
	background: #fff;
	border: none;
	width: auto;
}

.custom-select:not(:last-child) {
	margin-right: 40px;
}

@media only screen and (max-width: 479px) {
	.custom-select:not(:last-child) {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

.custom-select select {
	display: none;
}

.custom-select .select-items div,
.custom-select .select-selected {
	padding: 8px 20px;
	cursor: pointer;
	color: #707070;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-transform: capitalize;
}

.custom-select .select-selected {
	padding: 8px 70px 8px 20px;
	color: #292929;
	background-color: #fff;
	border: 1px solid #eee;
	font-size: 18px;
}

.custom-select .select-selected:after {
	position: absolute;
	content: '\f107';
	top: 8px;
	right: 30px;
	width: 0;
	height: 0;
	color: #292929;
	font-family: fontAwesome;
}

.custom-select .select-selected.select-arrow-active:after {
	content: '\f106';
}

.custom-select .select-items {
	position: absolute;
	background-color: #fff;
	top: calc(100% + 1px);
	left: 0;
	right: 0;
	z-index: 99;
	font-size: 16px;
	padding-top: 20px;
	padding-bottom: 15px;
}

.custom-select .select-hide {
	display: none;
}

.single-job {
	border: 1px solid #eee;
	padding: 40px 40px 60px;
	margin-bottom: 60px;
}

.single-job.style--two {
	padding: 70px 60px;
	margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
	.single-job.style--two {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

@media only screen and (max-width: 575px) {
	.single-job.style--two {
		padding: 50px 40px;
	}
}

@media only screen and (max-width: 575px) {
	.single-job.style--two {
		padding: 40px 20px;
	}
}

.single-job.style--two .job-logo {
	margin-bottom: 0;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 29%;
	flex: 0 0 29%;
}

@media only screen and (max-width: 1199px) {
	.single-job.style--two .job-logo {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 35%;
		flex: 0 0 35%;
	}
}

@media only screen and (max-width: 991px) {
	.single-job.style--two .job-logo {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		margin-bottom: 20px;
	}
}

.single-job.style--two .job-content .meta-box {
	margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
	.single-job.style--two .job-content {
		margin-bottom: 20px;
	}
}

.single-job.style--two .job-button {
	margin-left: auto;
}

@media only screen and (max-width: 991px) {
	.single-job.style--two .job-button {
		margin-left: 0;
	}
}

.single-job:hover {
	border-color: transparent;
}

.single-job .job-logo {
	margin-bottom: 30px;
}

.single-job .job-content .job-category {
	display: block;
	font-size: 16px;
	margin-bottom: 10px;
}

.single-job .job-content h3 {
	font-size: 26px;
	font-weight: 500;
	color: #292929;
	margin-bottom: 15px;
	line-height: 1.1;
}

.single-job .job-content h3 a {
	color: #292929;
}

.single-job .job-content .meta-box {
	margin-bottom: 20px;
}

.single-job .job-content .meta-box p {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0;
}

.single-job .job-content .meta-box p:not(:last-child) {
	margin-right: 40px;
}

.single-job .job-content .meta-box p img {
	margin-right: 10px;
}

@media only screen and (max-width: 991px) {
	.job-details {
		margin-bottom: 70px;
	}
}

.job-details-content h3 {
	font-size: 30px;
	font-weight: 600;
	color: #292929;
	margin-bottom: 22px;
}

.job-details-content p {
	margin-bottom: 15px;
}

.job-details-img {
	margin-bottom: 70px;
}

.post-meta {
	margin-bottom: 45px;
}

.post-meta li {
	display: inline-block;
	font-weight: 300;
}

.post-meta li i,
.post-meta li img {
	margin-right: 10px;
}

.post-meta li:not(:last-child) {
	margin-right: 30px;
	margin-bottom: 5px;
}

.form-group,
.widget {
	margin-bottom: 0;
}

.form-group .custom-select,
.widget .custom-select {
	margin-bottom: 20px;
}

.form-group .col-form-label,
.widget .col-form-label {
	padding: 0;
	margin-bottom: 15px;
	line-height: 1;
	text-transform: capitalize;
}

.comment-content .form-group select,
.comment-content .widget select,
.entry-content .form-group select,
.entry-content .widget select,
.form-group .comment-content select,
.form-group .entry-content select,
.form-group .page--content select,
.form-group .theme-input-style,
.page--content .form-group select,
.page--content .widget select,
.widget .comment-content select,
.widget .entry-content select,
.widget .page--content select,
.widget .theme-input-style {
	font-size: 18px;
	font-weight: 300;
}

.form-group .custom-select .select-selected,
.widget .custom-select .select-selected {
	font-size: 18px;
	font-weight: 300;
}

.form-group .custom-select .select-selected.select-arrow-active,
.widget .custom-select .select-selected.select-arrow-active {
	border-color: #eee !important;
}

.form-group textarea.theme-input-style,
.widget textarea.theme-input-style {
	height: 340px;
}

@media only screen and (max-width: 575px) {
	.form-group textarea.theme-input-style,
	.widget textarea.theme-input-style {
		height: 200px;
	}
}

@media only screen and (max-width: 479px) {
	.form-group textarea.theme-input-style,
	.widget textarea.theme-input-style {
		height: 150px;
	}
}

.modal-dialog {
	max-width: 930px;
	margin: 50px auto;
}

@media only screen and (max-width: 991px) {
	.modal-dialog {
		max-width: 700px;
	}
}

@media only screen and (max-width: 767px) {
	.modal-dialog {
		max-width: 520px;
	}
}

@media only screen and (max-width: 575px) {
	.modal-dialog {
		max-width: 100%;
		margin: 50px 15px;
	}
}

.modal-content {
	border: none;
	padding: 64px 104px;
	border-radius: 0;
}

@media only screen and (max-width: 991px) {
	.modal-content {
		padding: 50px 60px;
	}
}

@media only screen and (max-width: 575px) {
	.modal-content {
		padding: 40px;
	}
}

@media only screen and (max-width: 479px) {
	.modal-content {
		padding: 24px 0;
	}
}

.modal-header {
	border: none;
	margin-bottom: 50px;
}

.modal-header button.close {
	position: absolute;
	top: -25px;
	left: auto;
	right: -25px;
	width: 50px;
	height: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0;
	margin: 0;
	opacity: 1;
}

@media only screen and (max-width: 575px) {
	.modal-header button.close {
		width: 30px;
		height: 30px;
		right: -10px;
		top: -10px;
	}

	.modal-header button.close .svg {
		width: 10px;
		height: 10px;
	}
}

.modal-header .modal-title {
	margin: 0 auto;
}

.modal-header .modal-title .modal-title-icon {
	width: 72px;
	height: 72px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 auto 25px;
}

.modal-header .modal-title h3 {
	font-size: 26px;
	font-weight: 600;
	color: #292929;
}

.file-drop-area {
	background-color: #fbfbfb;
	color: #d7d7ef;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 100%;
	padding: 40px 20px 35px;
	border: 1px dashed #707070;
	border-radius: 3px;
	margin-bottom: 40px;
}

.file-drop-area .svg,
.file-drop-area img {
	margin-bottom: 15px;
}

.fake-btn {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	font-size: 12px;
	text-transform: capitalize;
	font-size: 22px;
	font-weight: 600;
	line-height: 1;
}

.file-msg {
	font-size: small;
	font-weight: 300;
	line-height: 1.4;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
}

.file-input {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	cursor: pointer;
	opacity: 0;
}

.file-input:focus {
	outline: 0;
}

.signup-form-left {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 40%;
	flex: 0 0 40%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.signup-form-left {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 38%;
		flex: 0 0 38%;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.signup-form-left {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 52%;
		flex: 0 0 52%;
	}
}

@media only screen and (max-width: 1199px) {
	.signup-form-left {
		display: none;
	}
}

.signup-form-right {
	padding: 60px;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 60%;
	flex: 0 0 60%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.signup-form-right {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 53%;
		flex: 0 0 53%;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.signup-form-right {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 48%;
		flex: 0 0 48%;
	}
}

@media only screen and (max-width: 1199px) {
	.signup-form-right {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
}

@media only screen and (max-width: 575px) {
	.signup-form-right {
		padding: 30px 15px;
	}
}

.signin-form-title,
.signup-form-title {
	margin-bottom: 60px;
}

.signin-form-title h2,
.signup-form-title h2 {
	font-size: 36px;
	color: #292929;
	font-weight: 600;
	margin-bottom: 15px;
}

@media only screen and (max-width: 575px) {
	.signin-form-title h2,
	.signup-form-title h2 {
		font-size: 30px;
	}
}

.signin-form-title p,
.signup-form-title p {
	max-width: 540px;
	margin: 0 auto;
}

.signup-form label {
	margin-bottom: 15px;
	padding: 0;
}

.signup-form .login-text {
	font-weight: 400;
	color: #292929;
}

.signin-form-left {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.signin-form-left {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 46%;
		flex: 0 0 46%;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.signin-form-left {
		display: none;
	}
}

@media only screen and (max-width: 1199px) {
	.signin-form-left {
		display: none;
	}
}

.signin-form-right {
	padding: 60px;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.signin-form-right {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 45%;
		flex: 0 0 45%;
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	.signin-form-right {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 80%;
		flex: 0 0 80%;
	}
}

@media only screen and (max-width: 1199px) {
	.signin-form-right {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
}

@media only screen and (max-width: 575px) {
	.signin-form-right {
		padding: 30px 15px;
	}
}

@media only screen and (max-width: 767px) {
	.signin-help {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

@media only screen and (max-width: 767px) {
	.signin-help .forgot-password {
		margin-bottom: 6px;
	}
}

@media only screen and (max-width: 991px) {
	.contact-form-left {
		margin-bottom: 80px;
	}
}

.contact-form-left .content {
	margin-bottom: 70px;
}

.contact-form-left h2 {
	color: #292929;
	font-size: 36px;
	font-weight: 600;
	margin-bottom: 20px;
}

.contact-form-left p {
	max-width: 540px;
}

.contact-form {
	padding: 80px 60px;
	background-color: #fff;
}

@media only screen and (max-width: 575px) {
	.contact-form {
		padding: 50px 15px;
	}
}

.contact-form.style--two {
	margin-top: -200px;
}

@media only screen and (max-width: 991px) {
	.contact-form.style--two {
		margin-top: -150px;
	}
}

@media only screen and (max-width: 575px) {
	.contact-form.style--two {
		margin-top: -100px;
	}
}

.contact-form .contact-form-top {
	margin-bottom: 65px;
}

.contact-form .contact-form-top h2 {
	margin-bottom: 15px;
	font-size: 36px;
	color: #292929;
	font-weight: 600;
}

.contact-form .contact-form-top p {
	max-width: 540px;
	margin: 0 auto;
}

#map {
	width: 100%;
	height: 700px;
}

@media only screen and (max-width: 991px) {
	#map {
		height: 550px;
	}
}

@media only screen and (max-width: 575px) {
	#map {
		height: 400px;
	}
}

#map.map2 {
	height: 800px;
}

@media only screen and (max-width: 991px) {
	#map.map2 {
		height: 600px;
	}
}

@media only screen and (max-width: 575px) {
	#map.map2 {
		height: 400px;
	}
}

.gm-style-iw {
	border-radius: 0 !important;
	-webkit-box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06) !important;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06) !important;
	padding: 17px 5px 5px 17px !important;
	text-align: center;
	left: 100px !important;
	width: 210px !important;
}

.gm-style-iw button {
	display: none !important;
}

.gm-style .gm-style-iw-t::after {
	display: none !important;
}

#marker-info {
	line-height: 1.5;
}

.member {
	text-align: center;
	background-color: #fff;
	padding: 40px 20px;
	margin-bottom: 40px;
}

.member .member-img {
	margin-bottom: 28px;
}

.member .member-content h3 {
	font-weight: 500;
}

.member .member-content p {
	font-size: 18px;
	margin: 0;
}

.social-links a {
	display: inline-block;
	padding: 5px;
	font-size: 14px;
}

.pb-140 {
	padding-top: 4rem;
}

@media only screen and (max-width: 991px) {
	.pb-140 {
		padding-bottom: 2rem;
	}
}
