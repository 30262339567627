.navigation-menu-icon {
	content: url('../img/HamIcon.svg') !important;
}

.dtr-pt-8 {
	padding-top: 10rem;
}

.compscroll {
	height: 660px;
	overflow: hidden;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
}

.compscroll::after {
	content: '';
	background-image: linear-gradient(
		180deg,
		rgba(253, 254, 255, 0) 8%,
		#fff 50%
	);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 40px;
}

.compscroll::before {
	content: '';
	background-image: linear-gradient(
		180deg,
		rgba(253, 254, 255, 0) 8%,
		#fff 50%
	);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	transform: scaleY(-1);
	z-index: 99;
}

@media only screen and (max-width: 1059px) {
	.compscroll {
		height: 480px;
	}
}

@media only screen and (max-width: 767px) {
	.compscroll {
		height: 360px;
	}
}

.scrollcom {
	webkit-animation: 30s topperlist linear infinite;
	animation: 30s topperlist linear infinite;
}

.comscrollimg {
	width: 100%;
	padding-top: 70%;
	margin: 0 auto;
	background: url(../img/hexscroll.jpeg) no-repeat center;
	background-size: contain;
}
@media only screen and (max-width: 500px) {
	.comscrollimg {
		background: url(../img/porthexscroll.jpeg) no-repeat center;
		background-size: contain;
		padding-top: 250%;
		width: 95%;
	}
}

body {
	-webkit-font-smoothing: antialiased !important;
}

#scrollid {
	display: none;
}

@-webkit-keyframes topperlist {
	from {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	to {
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}

@keyframes topperlist {
	from {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	to {
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}

.main-container .title {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 2rem;
	background: linear-gradient(180deg, #e7936e 0%, #f6c077 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.main-container .description {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	color: #d2d2d2;
	font-size: 1.25rem;
	margin-top: 1rem;
}

.heroBtn {
	margin-top: 1.5rem;
	display: flex;
	gap: 1rem;
}

.pushable {
	background: linear-gradient(95.57deg, #b15d37 3.08%, #db9c48 100%);
	border: none;
	border-radius: 5px;
	padding: 0;
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	padding: 2px;
	text-decoration: none !important;
}
.front {
	display: block;
	padding: 12px 42px;
	border-radius: 5px;
	font-size: 1.25rem;
	background: black;
	color: #e2a16c;
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	text-align: center;
	will-change: transform;
	transition: transform 250ms;
	text-decoration: none !important;
}

.pushable:hover .front {
	transform: translate(4px, 4px);
}
.front:hover {
	text-decoration: none !important;
}

@media (min-width: 768px) {
	.main-container .web {
		display: block;
		width: 100%;
	}
	.main-container .mobile {
		display: none;
	}
}
@media (max-width: 768px) {
	.main-container .web {
		display: none;
	}
	.main-container .mobile {
		display: block;
		width: 800px;
	}
	.main-container .row {
		flex-direction: column-reverse;
	}
	.main-container .row .title {
		font-size: 1.5rem;
		margin-top: 50px;
		text-align: left;
	}
	.main-container .row .description {
		font-size: 16px;
		text-align: left;
	}
	.heroBtn {
		flex-direction: column;
		justify-content: center;
		gap: 1.5rem;
	}
}
.contact-area .contact_from .form-control:focus {
	border-color: #b26549 !important;
}
.mobile-nav a {
	color: white !important;
}
.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
	color: #b26549 !important;
}

.pb-40 {
	padding-bottom: 4rem !important;
}

.row1 {
	display: flex;
	align-items: center;
}
.row2 {
	display: flex;
	align-items: center;
}

.aboutFont {
	font-size: 1.4rem;
}

@media (max-width: 640px) {
	.aboutFont {
		font-size: 1.2rem;
	}
}

.right-aligned-title {
	text-align: right !important;
}
.left-aligned-title {
	text-align: left !important;
}
.right-aligned-desc {
	text-align: right !important;
}
.left-aligned-desc {
	text-align: left !important;
}

.pyNone {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

@media (max-width: 540px) {
	.my {
		margin-left: 0.75rem;
		margin-right: auto;
	}
	.myHero {
		padding-left: 2.5rem !important;
		padding-right: 2.5rem !important;
	}
}

.flex-potfolio {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
}
.pad1 {
	padding: 2rem;
}
.pad2 {
	padding-top: 3rem;
	padding-bottom: 1.75rem;
	padding-left: 2.25rem;
	padding-right: 2.25rem;
}
.pad3 {
	padding: 2rem;
}
.pad4 {
	padding: 2.5rem;
}
.pad5 {
	padding-top: 2rem;
	padding-bottom: 1rem;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}
.pad6 {
	padding: 2.25rem;
}
.pad7 {
	padding-top: 4rem;
	padding-bottom: 1rem;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.flex_box {
	display: flex;
	flex-direction: column;
	flex-grow: 1 2;
}

.margin {
	margin-top: 3rem;
	margin-bottom: 3.5rem;
}

/* .flex1 {
	flex-grow: 1;
} */
.flex2 {
	flex-grow: 3;
}

.marginBottom {
	margin-bottom: 5rem;
}

.imgheight {
	height: 15rem !important;
}

.marginTop-2 {
	margin-top: 2rem;
}
.marginWeb {
	margin-bottom: 4rem !important;
}

@media (max-width: 1000px) {
	.imgheight {
		height: 10rem !important;
	}
	.imgMargin {
		margin-bottom: 3rem;
	}

	.marginWeb {
		margin-bottom: 5rem !important;
	}
}

@media (max-width: 1000px) {
	.right-aligned-title {
		text-align: center !important;
	}
	.left-aligned-title {
		text-align: center !important;
	}
	.right-aligned-desc {
		text-align: left !important;
	}
	.left-aligned-desc {
		text-align: left !important;
	}
	.row1 {
		flex-direction: column-reverse;
	}
	.row2 {
		flex-direction: column;
	}
}
