/* .slick-list {
    overflow: ${props => (props.overflow ? "visible" : "hidden")};
  } */
/* Slider */
.bg-black {
	background-color: black;
}

.our_works {
	text-transform: uppercase;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 2rem;
	background: linear-gradient(180deg, #e7936e 0%, #f6c077 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	padding-bottom: 2rem;
}

.testimoni--wrapper {
	color: white;
}

.slick-slider {
	margin-bottom: 8px;

	position: relative;

	display: block;
	box-sizing: border-box;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;

	display: block;
	/* overflow: hidden; */

	margin: 0;
	padding: 0;
}
.slick-list:focus {
	outline: none;
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;

	display: block;
	margin-left: auto;
	margin-right: auto;
}
.slick-track:before,
.slick-track:after {
	display: table;

	content: '';
}
.slick-track:after {
	clear: both;
}
.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;

	height: 100%;
	min-height: 1px;
}
[dir='rtl'] .slick-slide {
	float: right;
}
.slick-slide img {
	display: block;
}
.slick-slide.slick-loading img {
	display: none;
}
.slick-slide.dragging img {
	pointer-events: none;
}
.slick-initialized .slick-slide {
	display: block;
}
.slick-loading .slick-slide {
	visibility: hidden;
}
.slick-vertical .slick-slide {
	display: block;

	height: auto;

	border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
	display: none;
}
/* Arrows */
.slick-previous,
.slick-nextArr {
	font-size: 0;
	line-height: 0;

	position: absolute;
	top: 50%;

	display: block;

	width: 20px;
	height: 20px;
	padding: 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);

	cursor: pointer;

	color: transparent;
	border: none;
	outline: none;
	background: transparent;
}
.slick-previous:hover,
.slick-previous:focus,
.slick-nextArr:hover,
.slick-nextArr:focus {
	color: transparent;
	outline: none;
	background: transparent;
}
.slick-previous:hover:before,
.slick-previous:focus:before,
.slick-nextArr:hover:before,
.slick-nextArr:focus:before {
	opacity: 1;
}
.slick-previous.slick-disabled:before,
.slick-nextArr.slick-disabled:before {
	opacity: 0.25;
}

/* .slick-previous:before,
.slick-nextArr:before {
	font-family: 'slick';
	font-size: 20px;
	line-height: 1;

	opacity: 0.75;
	color: white;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */

.slick-previous {
	left: -45px;
	/* right: 25px; */
}
[dir='rtl'] .slick-previous {
	right: -25px;
	left: auto;
}
.slick-previous:before {
	content: url('../assets/img/prevArrow.svg') !important;
}
[dir='rtl'] .slick-previous:before {
	content: '→' !important;
}

.slick-nextArr {
	right: -25px;
	/* width: 1.5rem; */
}
[dir='rtl'] .slick-nextArr {
	right: auto;
	left: -25px;
}
.slick-nextArr:before {
	/* width: 1.5rem; */
	content: url('../assets/img/nextArrow.svg') !important;
	/* content: '→' !important; */
}
[dir='rtl'] .slick-nextArr:before {
	content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	position: absolute;
	bottom: -10px;

	display: block;

	width: 100%;
	padding: 0;
	margin: 0;

	list-style: none;

	text-align: center;
}
.slick-dots li {
	position: relative;
	display: inline-block;
	/* width: 10px;
	height: 10px;
	margin: 0 5px; */
	padding: 0;
	cursor: pointer;
	transition: width 0.3s ease-in-out;
}
.slick-dots li button {
	font-size: 0;
	line-height: 0;

	display: block;

	width: 10px;
	height: 10px;
	padding: 5px;

	cursor: pointer;

	color: transparent;
	border: 0;
	outline: none;
	background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
	outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
	opacity: 1;
}
.slick-dots li button:before {
	font-family: 'slick';
	font-size: 6px;
	line-height: 20px;

	position: absolute;
	top: 0;
	left: 0;

	width: 10px;
	height: 10px;

	content: '•';
	text-align: center;

	opacity: 0.25;
	color: black;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
	opacity: 0.75;
	color: black;
}

/* Custom Slick Dots */

@keyframes loading {
	from {
		width: 0%;
	}

	to {
		width: 100%;
	}
}

.ft-slick__dots--custom {
	width: 8px;
	height: 8px;
	background-color: #e5e7e9;
	border-radius: 4px;
	position: relative;
}

.slick-dots li {
	width: 8px;
	height: 8px;
	/* margin: 0 10px; */
	transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active {
	/* width: 5vw; */
	transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active .ft-slick__dots--custom {
	width: 8px;
	height: 8px;
	/* top: 0px; */
	overflow: hidden;
}

.slick-dots .slick-active .ft-slick__dots--custom .loading {
	height: 8px;
	animation: loading 5s ease-in;
	background: linear-gradient(180deg, #e7936e 0%, #f6c077 100%);
	display: inline-block;
	position: absolute;
	/* top: 0;
	left: 0;
	border-radius: 4px; */
}

.carouselBtn {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	color: white;
	background: linear-gradient(95.57deg, #b15d37 3.08%, #db9c48 100%);
	border-radius: 4px;
	padding: 0.5rem 1rem;
	margin-right: 1rem;
}

.carouselFont {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	font-size: calc(0.55em + 0.55vw);
	line-height: 1.75rem;
}
.carouselHeading_1 {
	font-size: 1.5rem;
}
.carouselHeading_2 {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 2rem;
	background: linear-gradient(180deg, #e7936e 0%, #f6c077 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	flex-wrap: wrap;
}
.disabled_button {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	color: grey;
	background: linear-gradient(95.57deg, #dddddd 3.08%, #f3f3f3 100%);
	border-radius: 4px;
	padding: 0.5rem 1rem;
	margin-right: 1rem;
}

.flex-d {
	display: flex;
	justify-content: center;
}

.flex-btn {
	display: flex;
}

@media (max-width: 992px) {
	.flex-d {
		margin-bottom: 2rem;
	}
}
@media (max-width: 640px) {
	.flex-d {
		margin-bottom: 2rem;
	}
	.carouselHeading_2 {
		font-size: 1rem;
	}
}

@media (max-width: 440px) {
	.flex-btn {
		flex-direction: column;
		gap: 1.5rem;
	}
}
.testimoni--wrapper1 {
	color: white;
}
