body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'Avenir Next';
	src: local('Avenir Next'),
		url('./assets/fonts/Avenir Next.ttc') format('truetype');
	font-weight: normal;
}
